<template>
  <main id="invoiceDetailsPage" aria-label="content">
    <section class="o-section c-section--invoice js-content">
      <div class="o-section__wrapper">
        <div v-if="Object.keys(invoiceData).length" class="c-invoice">
          <div class="c-invoice__wrap">
            <div class="c-invoice__col">
              <div class="c-invoice__items">
                <div class="c-invoice__head">Invoice no.</div>
                <div class="c-invoice__no">
                  {{ invoiceData.fields["Invoice No."] }}
                </div>
              </div>
              <div class="c-invoice__items">
                <div class="c-invoice__title">Attachments</div>
                <ul class="o-list o-list__wrap c-invoice__attachments">
                  <li
                    v-for="(element, index) in invoiceData.fields['Invoice']"
                    :key="'C' + index"
                    class="c-invoice__attachment__items"
                  >
                    <a :href="element.url" target="_blank">
                      <img class="c-invoice__attach__image" alt="Thumnail Image" :src="element.thumbnails.large.url" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="c-invoice__col">
              <div class="c-invoice__items">
                <div class="c-invoice__head">Invoice no.</div>
                <div class="c-invoice__no">
                  {{ invoiceData.fields["Invoice No."] }}
                </div>
              </div>
              <div v-if="invoiceData.fields['Invoice Amount']" class="item-detail-wrap">
                <div class="item-detail-wrap__title">Invoice Amount:</div>
                <div class="c-modal__item__text">
                  {{ invoiceData.fields["Invoice Amount"] | currency }}
                </div>
              </div>
              <div v-if="invoiceData.fields['Sale No.']" class="item-detail-wrap">
                <div class="item-detail-wrap__title">Sale No.:</div>
                <div class="c-modal__item__text">
                  {{ invoiceData.fields["Sale No."] }}
                </div>
              </div>
              <div v-if="invoiceData.fields['Sale Date']" class="item-detail-wrap">
                <div class="item-detail-wrap__title">sale date:</div>
                <div class="c-modal__item__text">
                  {{ invoiceData.fields["Sale Date"] | date("date") }}
                </div>
              </div>
              <div
                v-for="(element, index) in invoiceData.fields.Sale"
                :key="'B' + index"
                class="item-detail-wrap item-detail-wrap--vertical"
              >
                <div class="item-detail-wrap__title">sale:</div>
                <nl2br tag="div" :text="element | titlecase | sanitize" class-name="c-modal__item__text" />
              </div>
              <div
                v-for="(element, index) in invoiceData.fields.Merchant"
                :key="'A' + index"
                class="item-detail-wrap item-detail-wrap--vertical"
              >
                <div class="item-detail-wrap__title">Merchant:</div>
                <nl2br tag="span" :text="element | titlecase" class-name="c-modal__item__text" />
              </div>
            </div>
          </div>
          <div v-if="currentItems.length" class="other-invoice-items">
            <div class="c-invoice__title other-items">Other items from this invoice</div>
            <GridViewInvoice :current-items="currentItems" />
          </div>
          <div v-else class="spinner-wrap">
            <Spinner />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import GridViewInvoice from "../components/ui/layouts/CardGridInvoice";
import Spinner from "@/components/ui/Spinner";
import airtableClient from "../services/airtableClient.service";
import airtableQuery from "../services/airtableQuery.service";

export default {
  name: "InvoiceDetails",
  components: {
    GridViewInvoice,
    Spinner,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromRoute = from;
    });
  },
  data() {
    return {
      isLoadingInvoiceData: false,
      isLoadingOtheritems: false,
      invoiceData: {},
      otherItemIds: [],
      currentItems: [],
      currentRecordId: "",
    };
  },
  created() {
    this.fetchData();
    this.currentRecordId = this.$store.state.currentRecordId || localStorage.getItem("currentRecordId");
  },
  methods: {
    fetchData() {
      this.isLoadingInvoiceData = true;
      airtableClient
        .get(`Invoice%20No./${this.$route.params.invoiceId}`)
        .then((invoiceData) => {
          this.invoiceData = invoiceData.data;
          this.isLoadingInvoiceData = false;
          this.otherItemIds = [...this.invoiceData.fields.Collection];
          this.getOtherItems();
        })
        .catch((error) => {
          this.isLoadingInvoiceData = false;
          this.$toasted.error(error);
        });
    },
    async getOtherItems() {
      if (this.otherItemIds.length) {
        this.isLoadingOtheritems = true;
        let filterByFormulaIdQuery = "OR(";
        this.otherItemIds.forEach((id) => {
          filterByFormulaIdQuery += `{Item ID}="${id}",`;
        });
        filterByFormulaIdQuery = filterByFormulaIdQuery.slice(0, -1);
        filterByFormulaIdQuery += ")";
        this.currentItems = await airtableQuery.getTableAsync(
          "Art Inventory",
          200,
          "administrator",
          filterByFormulaIdQuery,
          ["TitleClean", "Image", "LocationName", "Location", "Item ID"]
        );
        this.isLoadingOtheritems = false;
      }
    },
  },
};
</script>
