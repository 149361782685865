<template>
  <section class="secondary-header">
    <div class="filter-wrap">
      <div class="c-dashboard__items c-dashboard__filter">
        <button aria-label="Filters Button" class="c-btn c-btn--filter js-mobile" @click="toggleHtmlClass('is-fixed')">
          <svg fill="none" height="16" viewBox="0 0 17 16" width="17" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4 4.5v-4m0 15v-2m0 0a3 3 0 100-6 3 3 0 000
                6zm9-2v4m0-15v2m0 0a3 3 0 100 6 3 3 0 000-6z"
              stroke="#8F6456"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
            />
          </svg>
          Filters
        </button>
        <button v-if="fetchingData" class="c-btn btn-cancel" @click="cancelFilter">Cancel</button>
      </div>
      <button class="c-btn sidebar-toggle" @click="handleSidebar">
        <span v-if="isSidebar" class="arrow">&#8592;</span>
        <span v-if="!isSidebar" class="arrow">&#8594;</span>
        <span>Sidebar</span>
      </button>
    </div>
    <div v-if="totalResults || fetchingData" class="total-results">
      <span class="mr-1">Total results:</span>
      <span v-if="totalResults && !fetchingData">{{ totalResults }}</span>
      <span v-if="!totalResults && fetchingData" class="progress">
        <Progress fill-color="#000000" width="20" />
      </span>
    </div>
    <div class="layout-btn-wrap">
      <label>Switch Layout:</label>
      <a
        :class="{ 'is-active': currentLayout === 'masonry-small' }"
        aria-label="Grid Layout"
        class="c-btn c-btn--view"
        title="Masonry Small Layout"
        @click="setCurrentLayout('masonry-small')"
      >
        <svg fill="none" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.263 1H1v12.222h5.263V1zM6.263 15.444H1V21h5.263v-5.556zM13.632 1H8.368v5.556h5.264V1zM13.632 8.778H8.368V21h5.264V8.778zM21 1h-5.263v12.222H21V1zM21 15.444h-5.263V21H21v-5.556z"
            stroke="#73706E"
            stroke-linecap="square"
            stroke-miterlimit="10"
          />
        </svg>
      </a>
      <a
        :class="{ 'is-active': currentLayout === 'masonry-large' }"
        aria-label="Grid Layout"
        class="c-btn c-btn--view"
        title="Masonry Large Layout"
        @click="setCurrentLayout('masonry-large')"
      >
        <svg fill="none" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9 1H1v10.667h8V1zM9 15.667H1V21h8v-5.333zM21 1h-8v6.667h8V1zM21 11.667h-8V21h8v-9.333z"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>
      <a
        :class="{ 'is-active': currentLayout === 'list' }"
        aria-label="List Layout"
        class="c-btn c-btn--view"
        title="List Layout"
        @click="setCurrentLayout('list')"
      >
        <svg fill="none" viewBox="0 0 24 24" width="22" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.5 6.5a2 2 0 100-4 2 2 0 000 4zM4.5 14.5a2 2 0 100-4 2 2 0 000 4zM4.5 22.5a2 2 0 100-4 2 2 0 000 4zM22.5 2.5h-12v4h12v-4zM22.5 10.5h-12v4h12v-4zM22.5 18.5h-12v4h12v-4z"
            stroke="#fff"
            stroke-linecap="square"
            stroke-miterlimit="10"
          />
        </svg>
      </a>
      <a
        :class="{ 'is-active': currentLayout === 'grid' }"
        aria-label="Grid Layout"
        class="c-btn c-btn--view"
        title="Grid Layout"
        @click="setCurrentLayout('grid')"
      >
        <svg fill="none" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.5 2.5h-7v7h7v-7zM21.5 2.5h-7v7h7v-7zM8.5 15.5h-7v7h7v-7zM21.5 15.5h-7v7h7v-7z"
            stroke="#fff"
            stroke-linecap="square"
            stroke-miterlimit="10"
          />
        </svg>
      </a>
    </div>
  </section>
</template>
<script>
import dom from "../../services/dom.service";
import { mapState } from "vuex";
import Progress from "@/components/ui/Progress";

export default {
  name: "SecondaryHeader",
  components: {
    Progress,
  },
  props: {
    currentLayout: {
      type: String,
      required: true,
      default: "masonry-small",
    },
  },
  data() {
    return {
      isOpenSort: false,
      isSidebar: true,
    };
  },
  computed: {
    ...mapState(["fetchingData", "totalResults", "authData"]),
  },
  created() {
    this.localStorage = JSON.parse(localStorage.getItem("CycadVuex"));
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
    const contentArea = document.querySelector(".layout-wrap");
    contentArea.addEventListener("click", this.handleClick);
  },
  methods: {
    cancelFilter() {
      this.isClosedCategories = true;
      this.isClosedKeyword = true;
      this.isClosedLocations = true;
      this.isClosedMerchants = true;
      this.isClosedPriceRange = true;
      this.isClosedQualities = true;
      this.isClosedSorts = true;
      this.$root.$emit("reset-filters");
    },
    toggleHtmlClass(className) {
      dom.toggleHtmlClass(className);
    },
    setCurrentLayout(layout) {
      this.$store.commit("setCurrentLayout", layout);
      this.$emit("update-masonry");
    },
    handleKeyDown(event) {
      if (event.keyCode === 27) {
        this.isOpenSort = false;
      }
    },
    handleClick() {
      if (this.isOpenSort) {
        this.isOpenSort = false;
      }
    },
    handleSidebar() {
      this.$emit("update-masonry");
      const main = document.querySelector("main");
      const submitFilters = document.querySelector(".btn-submit-filters");
      this.isSidebar = !this.isSidebar;
      if (this.isSidebar) {
        main.classList.remove("sidebar-hidden");
        submitFilters.classList.remove("sidebar-hidden");
      } else {
        main.classList.add("sidebar-hidden");
        submitFilters.classList.add("sidebar-hidden");
      }
    },
  },
};
</script>
