<template>
  <div id="cart" class="o-section__wrapper transfer">
    <confirm-transfer-modal
      v-if="isOpenConfirmTransferModal"
      :key="randomKey"
      :error-confirmation-email-message="errorMessage"
      :is-sending="isSending"
      :show-error-confirmation-email-message="showErrorMessage"
      :grouped-order="groupedOrder"
      @close-location-modal="handleCloseModal"
      @send-transfer-request="sendTransferRequest"
    />
    <main id="cartPage" aria-label="content">
      <header class="page-header">
        <h2 class="c-section__title">Transfer Cart</h2>
      </header>
      <div class="scanner-wrap">
        <div v-if="isLoading">
          <Spinner />
        </div>
        <QRScanner v-else @on-scan="onScan" />
        <p>Scan an item QR code to begin or amend your transfer cart.</p>
        <p>Note: you may need to allow access on your device.</p>
      </div>
      <section class="cart-wrap">
        <header v-if="curTransferCart.length" class="cart-header">
          <span class="item">Item</span>
          <span class="destination">Destination</span>
          <span class="delete">
            <svg fill="none" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 7H5v6h2V7zM11 7H9v6h2V7z" fill="#B0AEA9" />
              <path
                d="M12 1c0-.6-.4-1-1-1H5c-.6 0-1 .4-1 1v2H0v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V5h1V3h-4V1zM6 2h4v1H6V2zm7 3v9H3V5h10z"
                fill="#B0AEA9"
              />
            </svg>
          </span>
        </header>
        <!-- LIST-->
        <div v-if="curTransferCart.length" class="cart-list">
          <cart-transfer-item
            v-for="(item, index) in transferCartItems"
            :key="item.id"
            :dropdown-disabled="isLocationDropdownDisabled"
            :index="index"
            :item="item"
            @on-remove-item="handleRemoveItem"
          />
        </div>
        <!-- LIST FOOTER -->
        <footer v-if="curTransferCart.length" class="cart-footer">
          <div class="cart-footer-row">
            <button
              class="c-btn mr-auto"
              :class="{ disabled: isMakeSameDisabled }"
              :disabled="isMakeSameDisabled"
              @click="handleMakeSame"
            >
              Make all destinations same as first
            </button>
            <button
              :class="{ disabled: isResetLocationsDisabled }"
              class="c-btn btn-reset-locations"
              @click="handleResetLocationDropdowns()"
            >
              Reset Locations
            </button>
            <button
              :disabled="isContinueDisabled"
              aria-label="Send"
              class="c-btn btn-success btn-continue"
              @click="confirmItemsToLocation()"
            >
              continue
            </button>
            <!--            remove all-->
            <button
              aria-label="Delete"
              class="c-link c-link--bin"
              title="Empty Transfer Cart"
              @click="handleRemoveItem('all')"
            >
              <svg fill="none" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 7H5v6h2V7zM11 7H9v6h2V7z" fill="#B0AEA9" />
                <path
                  d="M12 1c0-.6-.4-1-1-1H5c-.6 0-1 .4-1 1v2H0v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V5h1V3h-4V1zM6 2h4v1H6V2zm7 3v9H3V5h10z"
                  fill="#B0AEA9"
                />
              </svg>
            </button>
          </div>
        </footer>
        <div v-if="!transferCartItemIds.length" class="message-box message-box--cart">
          <h2 class="message-box__title">Your transfer cart is empty</h2>
        </div>
      </section>
      <!-- previous transfers previous transfers previous transfers -->
      <!-- previous transfers previous transfers previous transfers -->
      <!-- previous transfers previous transfers previous transfers -->
      <section v-if="previousTransfers && previousTransfers.length" class="previous-list">
        <header class="section-header previous">Previous Transfers for {{ curUserFirstName }}</header>
        <div v-for="(transfer, index) in previousTransfers" :key="index" class="cart-item-wrap">
          <div v-if="getStatus(transfer)" class="previous-details">
            <div class="detail">
              <label>Transfer ID:</label>
              {{ transfer.fields.TransferId }}
            </div>
            <div class="detail">
              <label>Date:</label>
              <span>{{ transfer.fields.Created | date("date") }}</span>
            </div>
            <div class="detail status">
              <label>Status:</label>
              <span :class="getStatus(transfer)">{{ transfer.fields.Status || "n/a" }}</span>
            </div>
            <div class="detail">
              <label>Items:</label>
              <ul v-if="transfer.fields.Collection">
                <li v-for="(n, ind) in transfer.fields.Collection.length" :key="ind">
                  <router-link
                    :to="`/item/${transfer.fields.Collection[n - 1]}`"
                    @click.native="setCurrentRecordId(transfer.fields.Collection[n - 1])"
                  >
                    <truncate
                      :length="70"
                      :text="transfer.fields.CollectionTitle[n - 1] | sanitize | titlecase"
                      action-class="is-hidden"
                      clamp="See More"
                      class=""
                      less="See Less"
                      type="html"
                    />
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="detail">
              <label>Transferred to:</label>
              <span class="location">
                {{ transfer.fields.LocationName || "N/A" }}
              </span>
            </div>
          </div>
        </div>
      </section>
      <div v-if="networkBusyStatus && !curTransferCart.length" class="pt-5 mt-5">
        <Spinner />
      </div>
    </main>
  </div>
</template>
<script>
import ConfirmTransferModal from "../modals/ConfirmTransferModal";
import Spinner from "../ui/Spinner";
import Truncate from "vue-truncate-collapsed";
import _ from "lodash";
import airtableQuery from "@/services/airtableQuery.service";
import axios from "axios";
import { mapState } from "vuex";
import dom from "@/services/dom.service";
import QRScanner from "@/components/ui/QRScanner";
import { useSound } from "@vueuse/sound";
import failSFX from "@/sounds/fail_2.mp3";
import successSFX from "@/sounds/success.mp3";
import newRoomSFX from "@/sounds/newroom.mp3";
import completeSFX from "@/sounds/complete.mp3";
import CartTransferItem from "@/components/pages/CartTransferItem.vue";

export default {
  name: "CartTransfer",
  components: {
    ConfirmTransferModal,
    QRScanner,
    Spinner,
    CartTransferItem,
    Truncate,
  },
  data() {
    return {
      curTransferCart: [],
      curTransferCartItemIds: [],
      curUserFirstName: "",
      errorMessage: "",
      isContinueDisabled: true,
      isLoading: false,
      isLocationDropdownDisabled: false,
      isMakeSame: false,
      isMakeSameDisabled: true,
      isOpenConfirmTransferModal: false,
      isResetLocationsDisabled: true,
      isSending: false,
      message: "",
      previousTransfers: [],
      randomKey: 42,
      showErrorMessage: false,
      showMessgeBox: false,
      successMessage: "",
      transferOrderId: "",
    };
  },

  watch: {
    transferOrder: {
      handler(newVal) {
        if (newVal) {
          if (newVal.length) {
            this.handleToggleIsMakeSameDisabled();
            this.handleToggleResetContinue();
          }
        }
      },
      deep: true,
    },
    transferCartItems: {
      handler(newVal) {
        if (newVal) {
          this.curTransferCart = _.clone(newVal);
        }
      },
      deep: true,
    },
    transferCartItemIds: {
      handler(newVal) {
        if (newVal) {
          this.curTransferCartItemIds = _.clone(newVal);
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState([
      "locations",
      "transferCartItems",
      "transferCartItemIds",
      "transferOrder",
      "networkBusyStatus",
      "curUserData",
    ]),
    groupedOrder() {
      let groupedOrder = _.groupBy(this.transferOrder, "newLocationName");
      return groupedOrder;
    },
    currentDate() {
      let transferDate = new Date().toLocaleDateString();
      let transferTime = new Date().toLocaleTimeString();
      return `${transferDate} at ${transferTime}`;
    },
  },
  async created() {
    this.$store.commit("setTransferOrder", []);
    await this.$store.dispatch("getLocations");
    if (this.transferCartItemIds.length) {
      await this.$store.dispatch("getTransferCart");
      if (this.transferOrder.length) {
        this.handleToggleResetContinue();
        this.handleToggleIsMakeSameDisabled();
      } else {
        let initTranserOrder = [];
        this.transferCartItems.forEach((obj) => {
          initTranserOrder.push({
            item: obj,
            newLocationId: "",
            newLocationName: "",
            notes: "",
            needBy: "",
          });
        });
        this.$store.commit("setTransferOrder", initTranserOrder);
      }
    } else {
      this.$store.commit("setTransferCartItems", []);
      this.$store.commit("setTransferOrder", []);
    }
    this.curTransferCart = _.clone(this.transferCartItems);
    this.curTransferCartItemIds = _.clone(this.transferCartItemIds);
    this.curUserFirstName = this.curUserData.fields.FirstName;

    if (!this.curTransferCart.length) {
      this.showMessgeBox = true;
    }
    this.getPreviousTransfers();
  },

  setup() {
    const success = useSound(successSFX);
    const fail = useSound(failSFX);
    const newroom = useSound(newRoomSFX);
    const complete = useSound(completeSFX);

    const playSound = (sound) => {
      if (sound === "success") {
        success.play();
      } else if (sound === "newroom") {
        newroom.play();
      } else if (sound === "complete") {
        complete.play();
      } else {
        fail.play();
      }
    };

    return {
      playSound,
    };
  },

  methods: {
    onScan(decodedString) {
      if (decodedString.includes("item/")) {
        this.curItemQRId = /[^/]*$/.exec(decodedString)[0];
        if (this.curTransferCartItemIds.includes(this.curItemQRId)) {
          this.$toasted.error(`This item is already in your cart!`, {
            duration: 3000,
          });
          this.playSound("fail");
        } else {
          this.playSound("success");
          this.addToTransferCart();
        }
      } else {
        this.playSound("fail");
        this.$toasted.error("That wasn't an item QR code.", {
          duration: 3000,
        });
      }
    },
    async addToTransferCart() {
      let newTransferOrder = [];
      this.curTransferCartItemIds.push(this.curItemQRId);
      this.$store.commit("addTransferCartItemId", this.curItemQRId);
      const item = await airtableQuery.getRecordAsync("Art Inventory", this.curItemQRId);
      newTransferOrder = [
        ...new Set([
          ...this.transferOrder,
          {
            item: this.item,
            newLocationId: "",
            newLocationName: "",
            notes: "",
            needBy: "",
          },
        ]),
      ];
      this.$store.commit("setTransferOrder", newTransferOrder);
      this.$store.commit("addTransferCartItem", item);
      airtableQuery.updateRecordsAsync("Users", [
        {
          id: this.curUserData.id,
          fields: { TransferCart: this.curTransferCartItemIds },
        },
      ]);
    },
    handleToggleIsMakeSameDisabled() {
      if (this.transferOrder.length) {
        let firstId = this.transferCartItems[0].id;
        this.transferOrder.forEach((obj) => {
          if (obj.item.id === firstId) {
            if (obj.newLocationId !== "") {
              this.isMakeSameDisabled = false;
            } else {
              this.isMakeSameDisabled = true;
            }
          }
        });
      }
    },
    handleMakeSame() {
      let firstId = this.transferCartItems[0].id;
      let globalLocationId = "";
      let globalLocationName = "";
      this.transferOrder.forEach((obj) => {
        if (obj.item.id === firstId) {
          globalLocationId = obj.newLocationId;
          globalLocationName = obj.newLocationName;
        }
      });
      this.transferOrder.forEach((item, idx) => {
        this.transferOrder[idx] = {
          ...item,
          newLocationId: globalLocationId,
          newLocationName: globalLocationName,
        };
      });
      this.$store.commit("setTransferOrder", this.transferOrder);
    },
    async getPreviousTransfers() {
      let filterByForumla = `AND({User}="${this.curUserData.fields.FullName}")`;
      await airtableQuery.getTableAsync("Transfers", 5000, "administrator", filterByForumla, []).then((data) => {
        this.previousTransfers = data.sort((a, b) => {
          return a.fields.Created > b.fields.Created ? -1 : 1;
        });
      });
    },

    handleToggleResetContinue() {
      let empty = 0;
      this.transferOrder.forEach((obj) => {
        if (obj.newLocationId === "") {
          empty += 1;
        }
      });
      if (empty === 0) {
        this.isContinueDisabled = false;
      } else {
        this.isContinueDisabled = true;
      }

      if (empty === this.transferOrder.length) {
        this.isResetLocationsDisabled = true;
        this.isMakeSameDisabled = true;
      } else {
        this.isResetLocationsDisabled = false;
      }
    },

    getStatus(transfer) {
      return transfer.fields.Status.replace(" ", "-");
    },
    // handle reset location dropdowns ----------------------
    handleResetLocationDropdowns() {
      this.isLocationDropdownDisabled = false;
      this.isMakeSame = false;
      let newTransferOrder = [];
      this.transferOrder.forEach((obj) => {
        newTransferOrder.push({
          ...obj,
          newLocationId: "",
          newLocationName: "",
        });
      });
      this.$store.commit("setTransferOrder", newTransferOrder);
    },
    // handle remove item ---------------------------
    handleRemoveItem(item) {
      if (item === "all") {
        const con = confirm(
          "Are you sure you want to delete all the items in your transfer cart? This action is permanent."
        );
        if (con === true) {
          this.$store.commit("setTransferCartItems", []);
          this.$store.commit("setTransferCartItemIds", []);
          this.$store.commit("setTransferOrder", []);
          this.curTransferCart = [];
          this.curTransferCartItemIds = [];
        } else {
          return;
        }
      } else {
        let newTransferOrder = [...this.transferOrder].filter((obj) => obj.id !== item.id);
        this.curTransferCartItemIds = [...this.curTransferCartItemIds].filter((id) => id !== item.id);
        this.$store.commit("removeTransferCartItemId", item.id);
        this.$store.commit("removeTransferCartItem", item.id);
        this.$store.commit("setTransferOrder", newTransferOrder);
      }
      airtableQuery.updateRecordsAsync("Users", [
        {
          id: this.curUserData.id,
          fields: { TransferCart: this.curTransferCartItemIds },
        },
      ]);
    },
    confirmItemsToLocation() {
      dom.toggleHtmlClass("is-fixed");
      this.isOpenConfirmTransferModal = true;
    },
    /**

     SEND TRANSFER REQUEST

     */
    async sendTransferRequest(transferRequestData, ccEmail) {
      const emailData = _.cloneDeep(transferRequestData); // unmutated original
      for (let i in transferRequestData) {
        if (transferRequestData.hasOwnProperty(i)) {
          delete transferRequestData[i].fields.LocationName;
          if (transferRequestData[i].fields.Notes && transferRequestData[i].fields.Notes.length > 0) {
            let transferNote = "";
            transferRequestData[i].fields.Notes.forEach((item) => {
              transferNote += `Item Title: ${item.itemTitle}, Item Note: ${item.itemNote} ■ `;
            });
            transferRequestData[i].fields.Notes = transferNote.slice(0, -2);
          }
        }
      }

      await airtableQuery.createRecordAsync("Transfers", transferRequestData).then((data, err) => {
        this.isOpenConfirmTransferModal = false;
        if (err) {
          alert(`There was a problem creating transfer order. Error: ${err}`);
          this.$toasted.danger(`There was a problem creating transfer order. Error: ${err}`, {
            duration: 5000,
          });
        }
        this.$toasted.success("Your transfer order was submitted", {
          duration: 5000,
        });
        this.transferOrderId = data[0].id;
        this.$store.commit("setTransferCartItemIds", []);
        this.$store.commit("setTransferCartItems", []);
        this.$store.commit("setTransferOrder", []);
        airtableQuery.updateRecordsAsync("Users", [{ id: this.curUserData.id, fields: { TransferCart: [] } }]);
        this.sendTransferConfirmationEmail(emailData, ccEmail);
      });
    },

    /**

     SEND CONFIMATION EMAIL

     */
    sendTransferConfirmationEmail(emailData, ccEmail) {
      this.message += "opendiv";
      this.message += `h3open Transfer request from: ${this.curUserData.fields.FullName}: h3close`;
      this.message += `Submitted on: ${this.currentDate}^^`;
      this.message += `__________________________________________^^`;

      emailData.forEach((transferLocation) => {
        this.message += `h4open Transfer ${
          transferLocation.fields.Collection.length > 1 ? "these items" : "this item"
        } to ${transferLocation.fields.LocationName} by: ${transferLocation.fields.NeedByDate} h4close ^^^^`;
        transferLocation.fields.Notes.forEach((item, i) => {
          this.message += `${i + 1}) `;
          let shortName = `${item.itemTitle.substring(0, 42)}\u2026`;
          let itemLink = `<a href="https://cycad.art/item/${item.itemId}">${shortName}</a>`;
          this.message += `${itemLink}^^`;
          this.message += `Note: ${item.itemNote}^^^^`;
        });
        this.message += `__________________________________________^^`;
      });
      // footer
      this.message += "closediv";
      let post = {
        message: this.message,
        ccEmail: ccEmail,
      };
      if (post) {
        axios
          .post("/transfer_confirmation.php", post)
          .then(() => {
            this.isSending = false;
            this.successMessage = "Transfer request sent to location manager.";
            window.scrollTo(0, 0);
            this.$toasted.show(this.successMessage, {
              duration: 6000,
            });
          })
          .catch((error) => {
            this.isSending = false;
            window.scrollTo(0, 0);
            this.$toasted.error(`There was a problem sending confirmation email. (${error})`, {
              duration: 6000,
            });
            console.error(error);
          });
      }
    },
    handleCloseModal() {
      dom.toggleHtmlClass("is-fixed");
      this.isOpenConfirmTransferModal = false;
    },
    setCurrentRecordId(id) {
      this.$store.commit("setCurrentRecordId", id);
    },
  },
};
</script>
