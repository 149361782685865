<template>
  <div class="c-filter__range">
    <div class="range-slider price" :class="{ disabled: itemHasNoValue || excludeItemsWithNoValue }">
      <div class="range-display">
        <Cleave
          key="minCleave"
          ref="minInput"
          v-model="minPriceInput"
          :options="options"
          class="text-input price-range"
          maxlength="11"
          type="text"
          @blur="setFilterPriceRange(minPriceInput, maxPriceInput)"
        />
        <Cleave
          key="maxCleave"
          ref="askingInput"
          v-model="maxPriceInput"
          :options="options"
          class="text-input price-range"
          maxlength="11"
          type="text"
          @blur="setFilterPriceRange(minPriceInput, maxPriceInput)"
        />
        <span :class="{ visible: maxPriceInput >= 5000000 }" class="plus-sign">+</span>
      </div>
      <input
        v-model="filters.minPrice"
        min="0"
        max="5000000"
        step="10000"
        type="range"
        class="min-range"
        @input="handleMinSlide()"
      />
      <input
        v-model="filters.maxPrice"
        min="0"
        max="5000000"
        step="10000"
        type="range"
        class="max-range"
        @input="handleMaxSlide()"
      />
      <svg width="100%" height="28">
        <line x1="4" y1="0" x2="400" y2="0" stroke="#444" stroke-width="18" stroke-dasharray="1 30" />
      </svg>
    </div>
    <div class="price-checkboxes">
      <div class="checkbox-wrap">
        <div class="form-group">
          <input
            id="itemHasNoValue"
            v-model="itemHasNoValue"
            class="squarecheck"
            type="checkbox"
            @change="handleItemHasNoValue"
          />
          <label for="itemHasNoValue">Has no value</label>
        </div>
      </div>
      <div class="checkbox-wrap">
        <div class="form-group">
          <input
            id="excludeNoValueItems"
            v-model="excludeItemsWithNoValue"
            class="squarecheck"
            type="checkbox"
            @change="handleExcludeItemsWithNoValue"
          />
          <label for="excludeNoValueItems">Exclude items with no value</label>
        </div>
      </div>
      <div v-if="curUserPerms.canSetAskingPrice" class="checkbox-wrap">
        <div class="form-group">
          <input
            id="itemHasAskingPrice"
            v-model="hasAskingPrice"
            class="squarecheck"
            type="checkbox"
            @change="handleToggleHasAskingPrice"
          />
          <label for="itemHasAskingPrice">Has asking price</label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Cleave from "vue-cleave-component";
import _ from "lodash";
import { baseFilters } from "@/constants";

export default {
  name: "PriceRange",
  components: {
    Cleave,
  },
  data() {
    return {
      hasAskingPriceChecked: false,
      minPriceInput: 0,
      maxPriceInput: 0,
      itemHasNoValue: false,
      excludeItemsWithNoValue: false,
      options: {
        numeral: true,
        numeralPositiveOnly: true,
        prefix: "$",
        rawValueTrimPrefix: true,
      },
    };
  },
  computed: {
    ...mapState(["filters", "curUserPerms"]),
    hasPriceRange() {
      return this.filters.minPrice === baseFilters.minPrice && this.filters.maxPrice === baseFilters.maxPrice;
    },
    hasAskingPrice: {
      get() {
        return this.$store.state.hasAskingPrice;
      },
      set(value) {
        this.$store.commit("setHasAskingPrice", value);
      },
    },
  },
  watch: {
    filters: {
      handler(newVal) {
        this.minPriceInput = newVal.minPrice;
        this.maxPriceInput = newVal.maxPrice;
        this.itemHasNoValue = newVal.hasNoValue === "true" || newVal.hasNoValue === true;
      },
      deep: true,
    },
  },
  created() {
    this.hasAskingPriceChecked = this.hasAskingPrice;
    this.itemHasNoValue = this.filters.hasNoValue === "true" || this.filters.hasNoValue === true;
    this.minPriceInput = this.filters.minPrice;
    this.maxPriceInput = this.filters.maxPrice;
  },
  updated() {},
  methods: {
    handleItemHasNoValue() {
      if (this.itemHasNoValue) {
        this.excludeItemsWithNoValue = false;
      }
      this.$store.commit("setFilterHasNoValue", this.itemHasNoValue);
      this.$store.commit("setExcludeItemsWithNoValue", this.excludeItemsWithNoValue);
    },
    handleExcludeItemsWithNoValue() {
      if (this.excludeItemsWithNoValue) {
        this.itemHasNoValue = false;
      }
      this.$store.commit("setExcludeItemsWithNoValue", this.excludeItemsWithNoValue);
      this.$store.commit("setFilterHasNoValue", this.itemHasNoValue);
    },
    handleToggleHasAskingPrice() {
      this.hasAskingPriceChecked = !this.hasAskingPriceChecked;
      this.$store.commit("setHasAskingPrice", this.hasAskingPriceChecked);
    },
    // handle min slide
    handleMinSlide: _.debounce(function () {
      this.setFilterPriceRange(this.minPriceInput, this.maxPriceInput);
    }, 100),
    // handle max slide
    handleMaxSlide: _.debounce(function () {
      this.setFilterPriceRange(this.minPriceInput, this.maxPriceInput);
    }, 100),

    setFilterPriceRange(minPrice, maxPrice) {
      let min = parseInt(minPrice, 10);
      let max = parseInt(maxPrice, 10);
      if (max < min) {
        max = min + 100;
        this.$toasted.error("MAX value cannot be less than MIN value. Auto adjusted.", {
          duration: 5000,
        });
      }
      this.$store.commit("setFilterPriceRange", [min, max]);
      if (this.hasPriceRange) {
        this.$store.commit("setFilterHasPriceRange", false);
      } else {
        this.$store.commit("setFilterHasPriceRange", true);
      }
    },
  },
};
</script>
