<template>
  <div class="c-filter__range">
    <div class="range-slider width">
      <div class="range-display">
        <input
          v-model="minWidthInput"
          :max="max"
          class="text-input min"
          maxlength="4"
          min="0"
          oninput="this.value=this.value.replace(/[^0-9]/g,'')"
          step="10"
          type="text"
          @blur="setFilterWidthRange(minWidthInput, maxWidthInput)"
        />
        <input
          v-model="maxWidthInput"
          :max="max"
          class="text-input max"
          maxlength="4"
          min="0"
          oninput="this.value=this.value.replace(/[^0-9]/g,'')"
          step="10"
          type="text"
          @blur="setFilterWidthRange(minWidthInput, maxWidthInput)"
        />
      </div>
      <input
        v-model.number="minWidthInput"
        :max="max"
        class="min-range"
        min="0"
        step="10"
        type="range"
        @change="setFilterWidthRange(minWidthInput, maxWidthInput)"
        @input="handleSlide($event)"
      />
      <input
        v-model.number="maxWidthInput"
        :max="max"
        class="max-range"
        min="0"
        step="10"
        type="range"
        @change="setFilterWidthRange(minWidthInput, maxWidthInput)"
        @input="handleSlide($event)"
      />
      <svg width="100%" height="28">
        <line x1="4" y1="0" x2="400" y2="0" stroke="#444" stroke-width="18" stroke-dasharray="1 30" />
      </svg>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "WidthRange",
  props: {
    units: {
      type: String,
      required: true,
      default: "IN",
    },
  },
  data() {
    return {
      minWidthInput: 0,
      maxWidthInput: 0,
    };
  },
  computed: {
    ...mapState(["filters"]),
    max() {
      let u = this.units;
      return u === "IN" ? 500 : 1250;
    },
    offset() {
      let u = this.units;
      return u === "IN" ? 10 : 25;
    },
  },
  watch: {
    filters: {
      handler(newVal) {
        this.minWidthInput = newVal.minWidth;
        this.maxWidthInput = newVal.maxWidth;
      },
      deep: true,
    },
  },
  created() {
    this.minWidthInput = this.filters.minWidth;
    this.maxWidthInput = this.filters.maxWidth;
  },
  methods: {
    handleSlide(event) {
      let handle = event.target.classList[0];
      if (handle === "max-range") {
        if (this.maxWidthInput < this.minWidthInput + this.offset) {
          this.maxWidthInput = this.minWidthInput + this.offset;
        }
        if (this.maxWidthInput >= this.max) {
          this.maxWidthInput = this.max;
        }
      }
      if (handle === "min-range") {
        if (this.minWidthInput >= this.maxWidthInput - this.offset) {
          this.minWidthInput = this.maxWidthInput - this.offset;
        }
      }
      if (this.minWidthInput < 1) {
        this.minWidthInput = 0;
      }
    },
    setFilterWidthRange(minWidth, maxWidth) {
      let min = parseInt(minWidth, 10);
      let max = parseInt(maxWidth, 10);
      if (max < min) {
        max = min + this.offset;
        this.maxWidthInput = min + this.offset;
      }
      this.$store.commit("setFilterWidthRange", [min, max]);
    },
  },
};
</script>
