<template>
  <main id="favoritesPage" aria-label="content">
    <section class="o-section__wrapper">
      <header class="page-header">
        <h2 class="c-section__title">My Favorites</h2>
      </header>
      <favorites />
    </section>
  </main>
</template>
<script>
import Favorites from "@/components/pages/Favorites.vue";

export default {
  name: "FavoritesPage",
  components: {
    Favorites,
  },
};
</script>
