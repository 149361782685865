import { baseFilters, initialState } from "@/constants";

import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import airtableQuery from "../services/airtableQuery.service";
import axiosClient from "../services/axiosClient.service";
import createPersistedState from "vuex-persistedstate";
import router from "../router/index";

Vue.use(Vuex);

// Helper function
async function fetchDataAndCommit({ commit }, { tableName, commitMutation, sortField, fields }) {
  try {
    const payload = await airtableQuery.getTableAsync(tableName, 500, "administrator", "", fields);
    const sortedData = payload.sort((a, b) => (a.fields[sortField] > b.fields[sortField] ? 1 : -1));
    commit(commitMutation, sortedData);
    console.log(`${tableName} data fetched and committed.`);
  } catch (error) {
    console.error(`Error fetching ${tableName}:`, error);
  }
}

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "CycadVuex",
      paths: [
        "authData",
        "compareList",
        "curRoomName",
        "curScanRoom",
        "curScanRoomItems",
        "curScanRoomScannedItemIds",
        "curScanRoomUnscannedItemIds",
        "curUserData",
        "curUserPerms",
        "currentLayout",
        "currentQueryURL",
        "currentRecordId",
        "currentRecordIndex",
        "filteredItemIds",
        "filters",
        "hasAskingPrice",
        "qualities",
        "previousRoomRecordIds",
        "shoppingCart",
        "shoppingCartItemIds",
        "sortByArr",
        "transferCartItems",
        "transferCartItemIds",
        "transferOrder",
        "userFavoriteItemIds",
        "userRoles",
      ],
    }),
  ],
  state: initialState,
  mutations: {
    // reset state
    resetState(state) {
      localStorage.clear();
      Object.assign(state, initialState);
    },
    // login
    loginStart(state) {
      state.loggingIn = true;
      state.loginError = "";
    },
    loginStop(state, errorMessage) {
      state.loggingIn = false;
      state.loginError = errorMessage;
    },
    setLoggingIn(state, payload) {
      state.loggingIn = payload;
    },
    setAuthData(state, payload) {
      state.authData = payload;
    },
    setcurUserData(state, payload) {
      console.log(
        `%c SET curUserData`,
        "font-size: 13px; background-color: purple; color: white; padding: 3px 12px 3px 7px;",
        payload
      );
      state.curUserData = { ...payload };
    },
    setShoppingCartItemIds(state, payload) {
      state.shoppingCartItemIds = [...payload];
    },
    setTransferCartItemIds(state, payload) {
      state.transferCartItemIds = [...payload];
    },
    setUserFavoriteItemIds(state, payload) {
      state.userFavoriteItemIds = [...payload];
    },
    // set roles
    setCurUserPerms(state, role) {
      console.log(`%c SET PERMS`, "font-size: 13px; background-color: blue; color: white; padding: 3px 12px 3px 7px;");
      state.curUserPerms.canBuy = role.fields.CanBuy;
      state.curUserPerms.canEditNotes = role.fields.CanAddEditNotes;
      state.curUserPerms.canSetAskingPrice = role.fields.CanSetAskingPrice;
      state.curUserPerms.canSetQuality = role.fields.CanSetQuality;
      state.curUserPerms.canTransfer = role.fields.CanTransfer;
      state.curUserPerms.canViewCollectionCount = role.fields.CanViewCollectionCount;
      state.curUserPerms.permittedArtInventoryFields = role.fields.PermittedArtInventoryFields || [];
      state.curUserPerms.permittedCategories = role.fields.PermittedCategories || [];
      state.curUserPerms.permittedCollections = role.fields.PermittedCollections || [];
      state.curUserPerms.permittedFilters = role.fields.PermittedFilters || [];
      state.curUserPerms.permittedLocationFields = role.fields.PermittedLocationFields || [];
      state.curUserPerms.permittedLocations = role.fields.PermittedLocations || [];
      state.curUserPerms.permittedMerchants = role.fields.PermittedMerchants || [];
      state.curUserPerms.permittedPages = role.fields.PermittedPages || [];
      state.curUserPerms.permittedSorting = role.fields.PermittedSorting || [];
    },
    clearLoginError(state) {
      state.loginError = "";
    },
    // role related
    setPermittedArtInventoryFields(state, fields) {
      state.permittedArtInventoryFields = fields;
    },
    setPermittedLocationFields(state, fields) {
      state.permittedLocationFields = fields;
    },
    setPermittedCategories(state, payload) {
      state.permittedCategories = payload;
    },
    setPermittedCollections(state, payload) {
      state.permittedCollections = payload;
    },
    setPermittedLocations(state, payload) {
      state.permittedLocations = payload;
    },
    setPermittedMerchants(state, payload) {
      state.permittedMerchants = payload;
    },
    setPermittedFilters(state, payload) {
      state.permittedFilters = payload;
    },
    setPermittedPages(state, payload) {
      state.permittedPages = payload;
    },
    setPermittedSorting(state, payload) {
      state.permittedSorting = payload;
    },
    setCurItemDetailRecord(state, payload) {
      state.curItemDetailRecord = payload;
    },
    // transfer cart ids
    addTransferCartItemId: (state, payload) => {
      state.transferCartItemIds = [...new Set([...state.transferCartItemIds, payload])];
    },
    removeTransferCartItemId: (state, payload) => {
      state.transferCartItemIds = [...state.transferCartItemIds.filter((id) => id !== payload)];
    },
    // transfer cart
    addTransferCartItem: (state, payload) => {
      state.transferCartItems = [...new Set([...state.transferCartItems, payload])];
    },
    removeTransferCartItem: (state, deleteId) => {
      state.transferCartItems = [...state.transferCartItems.filter((item) => item.id !== deleteId)];
    },
    setTransferCartItems(state, payload) {
      state.transferCartItems = payload;
    },
    // transfer order
    setTransferOrder(state, payload) {
      state.transferOrder = [...payload];
    },
    // transfer order
    setCompareList(state, payload) {
      state.compareList = [...payload];
    },
    // shopping cart ids
    addShoppingCartItemId: (state, payload) => {
      state.shoppingCartItemIds = [...new Set([...state.shoppingCartItemIds, payload])];
    },
    removeShoppingCartItemId: (state, deleteId) => {
      state.shoppingCartItemIds = [...state.shoppingCartItemIds.filter((id) => id !== deleteId)];
    },
    // shopping cart
    addShoppingCartItem: (state, payload) => {
      state.shoppingCart = [...new Set([...state.shoppingCart, payload])];
    },
    removeShoppingCartItem: (state, deleteId) => {
      state.shoppingCart = [...state.shoppingCart.filter((item) => item.id !== deleteId)];
    },
    setShoppingCart(state, payload) {
      state.shoppingCart = payload;
    },
    setShoppingCartTotal(state, payload) {
      state.shoppingCartTotal = payload;
    },
    // favoriteIds
    addUserFavoriteItemId: (state, payload) => {
      state.userFavoriteItemIds.push(payload);
    },
    removeUserFavoriteItemId: (state, payload) => {
      state.userFavoriteItemIds = state.userFavoriteItemIds.filter((id) => id !== payload);
    },
    // favorites array
    addUserFavoriteItem: (state, payload) => {
      state.userFavorites = [...new Set([...state.userFavorites, payload])];
    },
    removeUserFavoriteItem: (state, deleteId) => {
      state.userFavorites = [...state.userFavorites.filter((item) => item.id !== deleteId)];
    },
    setUserFavorites: (state, payload) => {
      state.userFavorites = payload;
    },
    // tables
    setCategories(state, categories) {
      state.categories = categories;
    },
    setLocations(state, locations) {
      state.locations = locations;
    },
    setMerchants(state, merchants) {
      state.merchants = merchants;
    },
    setCollections(state, collections) {
      state.collections = collections;
    },
    setQualities(state, qualities) {
      state.qualities = qualities;
    },
    setSubLocations(state, sublocations) {
      state.sublocations = sublocations;
    },
    setPreviousRoomScans(state, payload) {
      state.previousRoomScans = payload;
    },
    setPreviousScanRecordIds(state, payload) {
      state.previousRoomRecordIds = payload;
    },
    updatePreviousRoomScans(state, payload) {
      state.previousRoomScans = state.previousRoomScans.map((room) => [payload].find((o) => o.id === room.id) || room);
    },
    // single item page
    setCurrentRecordId(state, payload) {
      state.currentRecordId = payload;
    },
    setCurrentQueryURL(state, payload) {
      state.currentQueryURL = payload;
    },
    setFavorites(state, favorite) {
      state.filters.favorites = favorite;
      state.filters.locations = [];
      state.filters.categories = [];
      state.filters.quality = [];
      state.filters.keyword = "";
    },
    // airtable
    setFetchingData(state, value) {
      state.fetchingData = value;
    },
    setFilterByFormula(state, payload) {
      state.setFilterByFormula = payload;
    },
    setHasAskingPrice(state, payload) {
      state.hasAskingPrice = payload;
    },
    // filter category
    setFilterCategory(state, category) {
      state.filters.categories = [...new Set([...state.filters.categories, category])];
    },
    delFilterCategory(state, category) {
      state.filters.categories = [...state.filters.categories.filter((item) => item !== category)];
    },
    // filter location
    setFilterLocation(state, loc) {
      state.filters.locations = [...new Set([...state.filters.locations, loc])];
      state.filterByFormula += `SEARCH("${loc.toLowerCase().trim()}", LOWER({LocationName}))`;
    },
    delFilterLocation(state, loc) {
      state.filters.locations = [...state.filters.locations.filter((item) => item !== loc)];
      state.filterByFormula = state.filterByFormula.replace(`SEARCH({ LocationName }, "${loc}"),`, "");
    },
    // filter sublocation
    setFilterSubLocation(state, subLoc) {
      state.filters.sublocations = [...new Set([...state.filters.sublocations, subLoc])];
      state.filterByFormula += `SEARCH({SubLocationName}, "${subLoc}"),`;
    },
    delFilterSubLocation(state, subLoc) {
      state.filters.sublocations = [...state.filters.sublocations.filter((item) => item !== subLoc)];
      state.filterByFormula = state.filterByFormula.replace(`SEARCH({SubLocationName}, "${subLoc}"),`, "");
    },
    // filter collection
    setFilterCollection(state, col) {
      state.filters.collections = [...new Set([...state.filters.collections, col])];
    },
    delFilterCollection(state, col) {
      state.filters.collections = [...state.filters.collections.filter((item) => item !== col)];
    },
    // filter date start
    setFilterDateStart(state, date) {
      state.filters.dateStart = date;
    },
    delFilterDateStart(state) {
      state.filters.dateStart = "";
    },
    // filter date end
    setFilterDateEnd(state, date) {
      state.filters.dateEnd = date;
    },
    delFilterDateEnd(state) {
      state.filters.dateEnd = "";
    },
    // filter keyword
    setFilterKeyword(state, keyword) {
      state.filters.keyword = keyword;
    },
    delFilterKeyword(state) {
      state.filters.keyword = "";
    },
    setFilterMerchant(state, merch) {
      state.filters.merchants = [...new Set([...state.filters.merchants, merch])];
    },
    delFilterMerchant(state, merch) {
      state.filters.merchants = state.filters.merchants.filter((item) => item !== merch);
    },
    setFilterForSale(state, payload) {
      state.filters.forSale = payload;
    },
    // collateralized
    setFilterCollateralized(state, payload) {
      state.filters.collateralized = payload;
    },
    setFilterFreeAsset(state, payload) {
      state.filters.freeAsset = payload;
    },
    // filter length range
    setFilterLengthRange(state, lengthRange) {
      state.filters.minLength = lengthRange[0];
      state.filters.maxLength = lengthRange[1];
    },
    delFilterLengthRange(state) {
      state.filters.minLength = baseFilters.minLength;
      state.filters.maxLength = baseFilters.maxLength;
    },
    // filter width range
    setFilterWidthRange(state, widthRange) {
      state.filters.minWidth = widthRange[0];
      state.filters.maxWidth = widthRange[1];
    },
    delFilterWidthRange(state) {
      state.filters.minWidth = baseFilters.minWidth;
      state.filters.maxWidth = baseFilters.maxWidth;
    },
    // filter price range
    setFilterPriceRange(state, price) {
      state.filters.minPrice = price[0];
      state.filters.maxPrice = price[1];
    },
    delFilterPriceRange(state) {
      state.filters.minPrice = baseFilters.minPrice;
      state.filters.maxPrice = baseFilters.maxPrice;
    },
    // filter has price range
    setFilterHasPriceRange(state, payload) {
      state.filters.hasPriceRange = payload;
    },
    // filter no value
    setFilterHasNoValue(state, payload) {
      state.filters.hasNoValue = payload;
      if (payload === true || payload === "true") {
        state.filters.minPrice = 0;
        state.filters.maxPrice = 5000000;
      }
    },
    // filter no value
    setExcludeItemsWithNoValue(state, payload) {
      state.filters.excludeItemsWithNoValue = payload;
      if (payload === true || payload === "true") {
        state.filters.minPrice = 0;
        state.filters.maxPrice = 5000000;
      }
    },
    // set filter units
    setFilterUnits(state, units) {
      state.filters.units = units;
    },
    // set filters from menu
    setFilterKeywordFromNavbar(state, keyword) {
      state.filters = _.clone(baseFilters);
      state.filters.keyword = keyword;
    },
    setFilterCategoryFromMenu(state, category) {
      state.filters = _.clone(baseFilters);
      state.filters.categories = [category];
    },
    setFilterCollectionFromMenu(state, collection) {
      state.filters = _.clone(baseFilters);
      state.filters.collections = [collection];
    },
    setFilterLocationFromMenu(state, location) {
      state.filters = _.clone(baseFilters);
      state.filters.locations = [location];
    },
    setFilterMerchantFromMenu(state, merchant) {
      state.filters = _.clone(baseFilters);
      state.filters.merchants = [merchant];
    },

    // filter quality
    setFilterQuality(state, quality) {
      state.filters.quality = [...new Set([...state.filters.quality, quality])];
    },
    delFilterQuality(state, quality) {
      state.filters.quality = state.filters.quality.filter((item) => item !== quality);
    },
    // all sublocations
    setAllSublocations(state, sublocations) {
      let all = state.filters.sublocations;
      state.filters.sublocations = all.concat(sublocations);
    },
    delAllSublocations(state, subLocations) {
      state.filters.sublocations = [
        ...state.filters.sublocations.filter(function (el) {
          return subLocations.indexOf(el) < 0;
        }),
      ];
    },
    // filtered item ids
    setFilteredItemIds(state, itemIds) {
      state.filteredItemIds = itemIds;
    },
    // filters
    setFilters(state, filters) {
      filters.categories = filters.categories
        ? filters.categories.includes(",")
          ? filters.categories.split(",")
          : filters.categories.length
            ? Array.isArray(filters.categories)
              ? filters.categories
              : new Array(filters.categories)
            : []
        : [];
      filters.collections = filters.collections
        ? filters.collections.includes(",")
          ? filters.collections.split(",")
          : filters.collections.length
            ? Array.isArray(filters.collections)
              ? filters.collections
              : new Array(filters.collections)
            : []
        : [];
      filters.locations = filters.locations
        ? filters.locations.includes(",")
          ? filters.locations.split(",")
          : filters.locations.length
            ? Array.isArray(filters.locations)
              ? filters.locations
              : new Array(filters.locations)
            : []
        : [];
      filters.sublocations = filters.sublocations
        ? filters.sublocations.includes(",")
          ? filters.sublocations.split(",")
          : filters.sublocations.length
            ? Array.isArray(filters.sublocations)
              ? filters.sublocations
              : new Array(filters.sublocations)
            : []
        : [];
      filters.merchants = filters.merchants
        ? filters.merchants.includes(",")
          ? filters.merchants.split(",")
          : filters.merchants.length
            ? Array.isArray(filters.merchants)
              ? filters.merchants
              : new Array(filters.merchants)
            : []
        : [];
      filters.quality = filters.quality
        ? filters.quality.includes(",")
          ? filters.quality.split(",")
            ? Array.isArray(filters.quality)
            : filters.quality.length
              ? filters.quality
              : new Array(filters.quality)
          : []
        : [];
      filters.minPrice = filters.minPrice ? filters.minPrice : 0;
      filters.maxPrice = filters.maxPrice ? filters.maxPrice : 5000000;
      filters.minLength = filters.minLength ? filters.minLength : 0;
      filters.maxLength = filters.maxLength ? filters.maxLength : 500;
      filters.minWidth = filters.minWidth ? filters.minWidth : 0;
      filters.maxWidth = filters.maxWidth ? filters.maxWidth : 500;
      filters.itemIds = filters.itemIds ? filters.itemIds : "";
      state.filters = filters;
    },
    setInitialPage(state, page) {
      state.initialPage = page;
    },
    setItemIds(state, itemIds) {
      state.filters.itemIds = itemIds;
    },
    setItems(state, items) {
      state.items = _.clone(items);
    },
    setCurrentLayout(state, layout) {
      state.currentLayout = layout;
    },
    setLike(state, layout) {
      state.currentLayout = layout;
    },

    setNetworkBusyStatus(state, networkStatus) {
      state.networkBusyStatus = networkStatus;
    },
    setOffset(state, offset) {
      state.offset = offset;
    },
    setScrollOffset(state, offset) {
      state.scrollOffset = offset;
    },
    setSortDirection(state, direction) {
      state.sortDirection = direction;
    },
    setSortByArr(state, payload) {
      state.sortByArr = [...payload];
    },
    setTotalResults(state, payload) {
      state.totalResults = payload;
    },
    clearTransferCart(state) {
      state.transferCartItemIds = [];
    },
    loadingFavorites(state, payload) {
      state.loadingFavorites = payload;
    },
    clearFilters(state) {
      state.filters = {
        categories: [],
        collateralized: false,
        collections: [],
        dateEnd: "",
        dateStart: "",
        excludeItemsWithNoValue: false,
        favorites: "",
        forSale: false,
        freeAsset: false,
        hasNoValue: false,
        hasPriceRange: false,
        itemIds: "",
        keyword: "",
        locations: [],
        maxLength: 500,
        maxPrice: 5000000,
        maxWidth: 500,
        merchants: [],
        minLength: 0,
        minPrice: 0,
        minWidth: 0,
        quality: [],
        sublocations: [],
        units: "IN",
      };
    },
    setAirTableResTime(state, airTableResTime) {
      state.airTableResTime = airTableResTime;
    },
    setCurScanRoom(state, payload) {
      // {obj}
      state.curScanRoom = { ...payload };
    },
    setCurRoomName(state, payload) {
      state.curRoomName = payload;
    },
    setCurScanRoomItems(state, payload) {
      state.curScanRoomItems = payload;
    },
    setCurScanRoomScannedItemIds(state, payload) {
      state.curScanRoomScannedItemIds = payload;
    },
    setCurScanRoomUnscannedItemIds(state, payload) {
      state.curScanRoomUnscannedItemIds = payload;
    },
    delOffset(state) {
      state.offset = "";
    },
    delLoggedInUserData(state) {
      state.authData = {};
      localStorage.removeItem("authData");
    },
  },
  getters: {
    getTransferCartItemIds: (state) => {
      return state.transferCartItemIds;
    },
    getTransferOrder: (state) => {
      return state.transferOrder;
    },
    getCategories: (state) => {
      return state.categories;
    },
    getPermittedCategories: (state) => {
      return state.permittedCategories;
    },
    getPermittedFilters: (state) => {
      return state.permittedFilters;
    },
    getCategoriesName: (state) => {
      return state.categories.category_name;
    },
    getCurrentLayout: (state) => {
      return state.currentLayout;
    },
    getFilters: (state) => {
      return state.filters;
    },
    getItems: (state) => {
      return state.items;
    },
    getUnits: (state) => {
      return state.filters.units;
    },
    getLengthRange: (state) => {
      return [state.filters.minLength, state.filters.maxLength];
    },
    getWidthRange: (state) => {
      return [state.filters.minWidth, state.filters.maxWidth];
    },
    getLocations: (state) => {
      return state.locations.length;
    },
    getMerchants: (state) => {
      return state.merchants.length;
    },
    getPriceRange: (state) => {
      return [state.filters.minPrice, state.filters.maxPrice];
    },
    getHasNoValue: (state) => {
      return state.filters.hasNoValue;
    },
    getQualities: (state) => {
      return state.qualities.length;
    },
    getSortDirection: (state) => {
      return state.sortDirection;
    },
    getSorts: (state) => {
      return state.sortByArr;
    },
    getSubLocations: (state) => {
      return state.sublocations.length;
    },
    getLoggedInUserData: (state) => {
      return state.authData;
    },
    getUserEmail: (state) => {
      return state.authData.user_email;
    },
  },
  /**
   
   ACTIONS
   
   */
  actions: {
    // LOG IN ***********************************************************************
    async doLogin({ commit, dispatch }, payload) {
      commit("loginStart");
      try {
        const response = await axiosClient.post("/jwt-auth/v1/token", {
          username: payload.username,
          password: payload.password,
        });

        commit("setLoggingIn", false);
        commit("setFetchingData", false);
        commit("setAuthData", response.data);
        commit("loginStop", null);
        await dispatch("initializeApp");
      } catch (error) {
        console.error(error.response.data.message);
        commit("setFetchingData", false);
        commit("setNetworkBusyStatus", false);
        commit("loginStop", error.response.data.message);
        commit("delLoggedInUserData");
      }
    },

    // LOG OUT ***********************************************************************
    logout({ commit }) {
      commit("resetState");
      router.push({ name: "AppLogin" });
      localStorage.removeItem("CycadVuex");
    },

    // INITIALIZE APP ****************************************************************
    async initializeApp({ commit, dispatch }) {
      await dispatch("getUserRoles");
      await dispatch("getUsers");
      console.log(
        "%c INITIALIZE APP",
        "font-size: 16px; background-color: #0c0; color: black; padding: 3px 12px 3px 7px;"
      );
      commit("setTotalResults", 0);
      commit("setCurrentLayout", "masonry-small");
      dispatch("resetFilters");
      if (router.history.current.name !== "ArtInventoryPage") {
        router.push({ name: "ArtInventoryPage" });
      }
    },

    resetStore({ commit, dispatch }) {
      commit("clearLoginError");
      commit("delLoggedInUserData");
      commit("clearTransferCart");
      commit("setItems", []);
      commit("setShoppingCart", []);
      commit("setTransferCart", []);
      commit("setFavorites", []);
      commit("setItems", []);
      commit("setFilters", {});
      dispatch("resetFilters");
    },

    // GET USER ROLES ****************************************************************
    async getUserRoles({ commit, state }) {
      console.log(
        "%c GET USER ROLES",
        "font-size: 13px; background-color: blue; color: white; padding: 3px 12px 3px 7px;"
      );

      try {
        const payload = await airtableQuery.getTableAsync("User Roles", 500, "administrator");

        const matchedRole = payload.find((role) => role.fields.UserRoleName === state.authData.user_role);

        if (matchedRole) {
          commit("setCurUserPerms", matchedRole);
          console.log("Role found and committed:", matchedRole);
        } else {
          console.log("No matching role found.");
        }
      } catch (error) {
        console.error("Error fetching user roles:", error);
      }
    },

    // GET USERS ********************************************************************
    async getUsers({ commit, state, dispatch }) {
      console.log(
        "%c GET USERS",
        "font-size: 13px; background-color: purple; color: white; padding: 3px 12px 3px 7px;"
      );

      try {
        const payload = await airtableQuery.getTableAsync("Users", 500, "administrator", "", [
          "Avatar",
          "Email",
          "FavoriteItemIds",
          "FirstName",
          "FullName",
          "LastName",
          "MobilePhone",
          "ShoppingCartItemIds",
          "TransferCartItemIds",
          "username",
        ]);

        const matchedUser = payload.find((user) => user.fields.Email === state.authData.user_email);

        if (matchedUser) {
          commit("setcurUserData", matchedUser);
          commit("setShoppingCartItemIds", matchedUser.fields.ShoppingCartItemIds || []);
          commit("setTransferCartItemIds", matchedUser.fields.TransferCartItemIds || []);
          commit("setUserFavoriteItemIds", matchedUser.fields.FavoriteItemIds || []);
          await dispatch("getUserRelatedData");
          console.log("User found and data committed:", matchedUser);
        } else {
          console.log("No matching user found.");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    },

    async getUserRelatedData({ dispatch }) {
      await dispatch("getShoppingCart");
      await dispatch("getTransferCart");
      await dispatch("getUserFavorites");
      await dispatch("getPreviousRoomScans");
    },

    // GET SHOPPING CART ************************************************************
    async getShoppingCart({ state, commit }) {
      if (state.shoppingCartItemIds.length) {
        const filterByFormulaIdQuery = `OR(${state.shoppingCartItemIds.map((id) => `{Item ID}="${id}"`).join(",")})`;

        try {
          const payload = await airtableQuery.getTableAsync(
            "Art Inventory",
            500,
            "administrator",
            filterByFormulaIdQuery,
            ["TitleClean", "Image", "AskingPrice"]
          );

          commit("setShoppingCart", payload);

          const total = payload.reduce((acc, item) => acc + (item.fields.AskingPrice || 0), 0);
          commit("setShoppingCartTotal", total);

          console.log("Shopping cart data committed:", payload);
        } catch (error) {
          console.error("Error fetching shopping cart:", error);
        }
      } else {
        commit("setShoppingCart", []);
      }
    },

    // GET TRANSFER CART ************************************************************
    async getTransferCart({ state, commit }) {
      if (state.transferCartItemIds.length) {
        const filterByFormulaIdQuery = `OR(${state.transferCartItemIds.map((id) => `{Item ID}="${id}"`).join(",")})`;

        try {
          const payload = await airtableQuery.getTableAsync(
            "Art Inventory",
            500,
            "administrator",
            filterByFormulaIdQuery,
            ["TitleClean", "Image", "AskingPrice"]
          );

          commit("setTransferCart", payload);
          console.log("Transfer cart data committed:", payload);
        } catch (error) {
          console.error("Error fetching transfer cart:", error);
        }
      } else {
        commit("setTransferCart", []);
      }
    },

    // GET USER FAVORITES ***********************************************************
    async getUserFavorites({ state, commit }) {
      if (state.userFavoriteItemIds.length) {
        const filterByFormulaIdQuery = `OR(${state.userFavoriteItemIds.map((id) => `{Item ID}="${id}"`).join(",")})`;

        try {
          const payload = await airtableQuery.getTableAsync(
            "Art Inventory",
            500,
            "administrator",
            filterByFormulaIdQuery,
            ["TitleClean", "Image", "LocationName", "Location", "Item ID"]
          );

          commit("setUserFavorites", payload);
          console.log("User favorites data committed:", payload);
        } catch (error) {
          console.error("Error fetching user favorites:", error);
        }
      } else {
        commit("setUserFavorites", []);
      }
    },

    // GET PREVIOUS ROOM SCANS ******************************************************
    async getPreviousRoomScans({ state, commit }) {
      try {
        const payload = await airtableQuery.getTableAsync("Room Scans", 500, "administrator");
        const curUserRooms = payload.filter((roomScan) => roomScan.fields.UserEmail[0] === state.authData.user_email);
        const previousRoomRecordIds = curUserRooms.map((room) => room.id);

        commit("setPreviousRoomScans", curUserRooms);
        commit("setPreviousScanRecordIds", previousRoomRecordIds);
        console.log("Previous room scans data committed:", curUserRooms);
      } catch (error) {
        console.error("Error fetching previous room scans:", error);
      }
    },

    // POST INIT ********************************************************************
    async getQualities({ commit }) {
      await fetchDataAndCommit(
        { commit },
        {
          tableName: "Qualities",
          commitMutation: "setQualities",
          sortField: "Quality",
          fields: ["Quality", "CollectionCount"],
        }
      );
    },

    async getLocations({ commit }) {
      await fetchDataAndCommit(
        { commit },
        {
          tableName: "Locations",
          commitMutation: "setLocations",
          sortField: "Location",
          fields: ["Location", "CollectionCount", "Attachments", "RecordId"],
        }
      );
    },

    async getSubLocations({ commit }) {
      await fetchDataAndCommit(
        { commit },
        {
          tableName: "SubLocations",
          commitMutation: "setSubLocations",
          sortField: "SubLocationName",
          fields: [
            "Collection",
            "CollectionCount",
            "ParentLocation",
            "ParentLocationId",
            "RecordId",
            "SubLocationName",
            "SubLocationURL",
          ],
        }
      );
    },

    async getCategories({ commit }) {
      await fetchDataAndCommit(
        { commit },
        {
          tableName: "Categories",
          commitMutation: "setCategories",
          sortField: "Category",
          fields: ["Category", "CollectionCount", "Attachments"],
        }
      );
    },

    async getCollections({ commit }) {
      await fetchDataAndCommit(
        { commit },
        {
          tableName: "Collections",
          commitMutation: "setCollections",
          sortField: "CollectionName",
          fields: ["CollectionName", "CollectionCount"],
        }
      );
    },

    async getMerchants({ commit }) {
      await fetchDataAndCommit(
        { commit },
        {
          tableName: "Merchants",
          commitMutation: "setMerchants",
          sortField: "Merchant",
          fields: ["Merchant", "CollectionCount", "Logo"],
        }
      );
    },

    setOffset({ commit }, offset) {
      commit("setOffset", offset);
    },

    updateItems({ state, commit }, payload) {
      if (state.items.length) {
        const updatedArray = state.items.map((item) =>
          item.id === payload.id ? { ...item, fields: { ...item.fields, ...payload.fields } } : item
        );
        commit("setItems", updatedArray);
      }
    },

    deleteItemById({ state, commit }, payload) {
      const updatedArray = state.items.filter((item) => !payload.includes(item.id));
      commit("setItems", updatedArray);
    },

    async getCurrentItemDetailRecord({ state, commit }, recordId) {
      const filterByFormulaIdQuery = `OR({Item ID}="${recordId}", {Old Item ID}="${recordId}")`;
      const role = state.authData.user_role;
      const fields = state.curUserPerms.permittedArtInventoryFields.includes("All")
        ? []
        : state.curUserPerms.permittedArtInventoryFields;

      try {
        const results = await airtableQuery.getTableAsync("Art Inventory", 1, role, filterByFormulaIdQuery, fields);
        commit("setCurItemDetailRecord", results[0]);
        console.log("Current item detail record fetched and committed:", results[0]);
      } catch (error) {
        console.error("Error fetching current item detail record:", error);
      }
    },

    resetFilters({ commit }) {
      commit("clearFilters");
    },
  },
});
