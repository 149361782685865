<template>
  <div>
    <div v-if="showFavorites" class="layout-wrap">
      <masonry-grid :key="`favorites_${randomKey}`" :current-items="curUserFavorites" />
    </div>
    <div v-if="showMessageBox">
      <div class="message-box">
        <h2 class="message-box__title">
          {{ curUserData.fields.FullName }}
        </h2>
        <p class="message-box__text">You have no favorites saved.</p>
      </div>
    </div>
    <div v-if="showSpinner" class="spinner-wrap">
      <Spinner />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import MasonryGrid from "@/components/ui/layouts/CardGrid";
import Spinner from "@/components/ui/Spinner";

export default {
  name: "Favorites",
  components: {
    MasonryGrid,
    Spinner,
  },
  data() {
    return {
      curUserFavorites: [],
      randomKey: 42,
    };
  },
  computed: {
    ...mapState(["userFavorites", "userFavoriteItemIds", "networkBusyStatus", "curUserData"]),
    showSpinner() {
      return this.networkBusyStatus && !this.curUserFavorites.length;
    },
    showMessageBox() {
      return !this.curUserFavorites.length && !this.showSpinner && !this.showFavorites;
    },
    showFavorites() {
      return !this.networkBusyStatus && this.curUserFavorites.length && !this.showSpinner && !this.showMessageBox;
    },
  },
  watch: {
    userFavorites: {
      handler(newVal) {
        if (newVal) {
          this.curUserFavorites = [...newVal];
          this.generateRandomKey();
        }
      },
      deep: true,
    },
    userFavoriteItemIds: {
      handler(newVal) {
        if (newVal) {
          this.currentFavoriteIds = [...newVal];
        }
      },
    },
  },
  async created() {
    if (this.userFavoriteItemIds.length) {
      await this.$store.dispatch("getUserFavorites");
    } else {
      this.$store.commit("setUserFavorites", []);
    }
  },
  methods: {
    generateRandomKey() {
      this.randomKey = Math.floor(Math.random() * 1000000000);
    },
  },
};
</script>
