<template>
  <main id="categoriesPage" aria-label="content">
    <section class="o-section__wrapper">
      <header class="page-header">
        <h2 class="c-section__title">Categories</h2>
      </header>
      <Categories v-if="categories.length" />
      <div v-else class="spinner-wrap">
        <Spinner />
      </div>
    </section>
  </main>
</template>
<script>
import Categories from "@/components/pages/Categories";
import Spinner from "@/components/ui/Spinner";
import { mapState } from "vuex";

export default {
  name: "CategoriesPage",
  components: {
    Categories,
    Spinner,
  },
  computed: mapState(["categories", "networkBusyStatus"]),
  async created() {
    await this.$store.dispatch("getCategories");
  },
};
</script>
