<template>
  <div v-if="currentItems.length" class="layout-wrap">
    <div v-if="showPagination" class="pagination-wrap top">
      <paginate
        :key="`paginateTop_${randomKey}`"
        :current-items="currentItems"
        :initial-page="initialPage"
        :current-page="currentPage"
        @change-page="onChangePage"
        @click.native="generateRandomKey"
      />
    </div>
    <div
      v-if="currentItems.length"
      :key="`masonry_${randomKey}`"
      v-images-loaded:on="getLoadingCallBack()"
      class="grid-layout-wrap"
    >
      <CardGridItem
        v-for="item in pageOfItems"
        :key="item.id"
        :item="item"
        @details-clicked="$emit('prepare-modal', item)"
        @reload-isotope="generateRandomKey"
      />
    </div>
    <div v-if="showPagination" class="pagination-wrap">
      <paginate
        :key="`paginateBottom_${randomKey}`"
        :current-items="currentItems"
        :initial-page="initialPage"
        :current-page="currentPage"
        @change-page="onChangePage"
        @click.native="generateRandomKey"
      />
    </div>
  </div>
</template>
<script>
import CardGridItem from "./CardGridItem";
import imagesLoaded from "vue-images-loaded";
import Paginate from "../Paginate";
import { mapState } from "vuex";

export default {
  name: "MasonryGrid",
  directives: {
    imagesLoaded,
  },
  components: {
    CardGridItem,
    Paginate,
  },
  props: {
    currentItems: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      currentPage: 1,
      pageOfItems: [],
      randomKey: 42,
    };
  },
  computed: {
    ...mapState(["initialPage"]),

    showPagination() {
      return this.currentItems.length > 100;
    },
  },
  mounted() {
    if (!this.showPagination) {
      this.pageOfItems = this.currentItems;
    }
  },
  methods: {
    onChangePage(pageOfItems, currentPage) {
      this.currentPage = currentPage;
      this.pageOfItems = pageOfItems;
    },
    getLoadingCallBack() {
      return {
        progress: (instance, image) => {
          let imageParent = image.img.parentElement.parentElement;
          if (image.isLoaded) {
            imageParent.classList.add("is-loaded");
          } else {
            imageParent.classList.add("is-broken");
          }
        },
      };
    },
    generateRandomKey() {
      this.randomKey = Math.floor(Math.random() * 1000000000);
    },
  },
};
</script>
