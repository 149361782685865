<template>
  <ul v-if="filteredLocations.length" class="c-filter__list">
    <li
      v-for="(location, index) in filteredLocations"
      :key="index"
      :ref="`li_${index}`"
      class="c-filter__list__items"
      :class="{ active: activeItem === index && isOpen }"
    >
      <LocationLi
        :key="`child_${index}`"
        :location="location"
        :ind="index"
        @set-current-location="setCurrentLocation"
        @set-current-sublocation="setCurrentSubLocation"
        @add-active-class="handleActiveClass"
        @toggle-current-sublocs="toggleCurrentSublocs"
      />
    </li>
  </ul>
</template>
<script>
import { mapState } from "vuex";
import LocationLi from "@/components/sidebar/LocationLi";

export default {
  name: "Locations",
  components: {
    LocationLi,
  },
  data() {
    return {
      activeItem: null,
      isOpen: null,
      selectAll: true,
      selectedSubCount: null,
      filteredLocations: [],
    };
  },
  computed: {
    ...mapState(["filters", "locations", "curUserPerms", "sublocations"]),
  },
  async created() {
    await this.$store.dispatch("getLocations");
    await this.$store.dispatch("getSubLocations");
    if (this.curUserPerms.permittedLocations[0] === "All") {
      this.filteredLocations = [...this.locations];
    } else {
      this.locations.forEach((loc) => {
        this.curUserPerms.permittedLocations.forEach((permLoc) => {
          if (loc.fields.Location === permLoc) {
            this.filteredLocations.push(loc);
          }
        });
      });
    }
  },
  methods: {
    handleActiveClass(ind, isOpen) {
      this.activeItem = ind;
      this.isOpen = !isOpen;
    },
    getCurSublocs(curLocId) {
      let currentLocSublocs = [];
      this.sublocations.forEach((subLoc) => {
        let thisId = subLoc && subLoc.fields && subLoc.fields.ParentLocationId && subLoc.fields.ParentLocationId[0];
        if (thisId === curLocId) {
          currentLocSublocs.push(subLoc);
        }
      });
      return currentLocSublocs;
    },
    setCurrentLocation(curLocId) {
      var self = this;
      let subLocs = this.getCurSublocs(curLocId);
      if (this.filters.locations.includes(curLocId)) {
        this.$store.commit("delFilterLocation", curLocId);
      } else {
        this.$store.commit("setFilterLocation", curLocId);
        if (subLocs.length) {
          subLocs.forEach((subLoc) => {
            if (this.filters.sublocations.includes(subLoc.id)) {
              self.$store.commit("delFilterSubLocation", subLoc.id);
            }
          });
          this.selectAll = true;
        }
      }
    },
    setCurrentSubLocation(subLoc, location) {
      if (this.filters) {
        if (this.filters.sublocations.includes(subLoc.id)) {
          this.selectedSubCount -= 1;
          this.$store.commit("delFilterSubLocation", subLoc.id);
        } else {
          this.selectedSubCount += 1;
          this.$store.commit("setFilterSubLocation", subLoc.id);
        }
      }
      if (this.filters.sublocations.length > 0) {
        this.$store.commit("delFilterLocation", location);
      }
    },
    toggleCurrentSublocs(curLocId) {
      var self = this;
      let subLocs = this.getCurSublocs(curLocId);
      if (this.selectAll) {
        if (this.filters.locations.includes(curLocId)) {
          this.$store.commit("delFilterLocation", curLocId);
        }
        subLocs.forEach((subLoc) => {
          self.$store.commit("setFilterSubLocation", subLoc.id);
        });
      } else {
        subLocs.forEach((subLoc) => {
          self.$store.commit("delFilterSubLocation", subLoc.id);
        });
      }
      this.selectAll = !this.selectAll;
    },
  },
};
</script>
