<template>
  <div v-if="isVisible" class="input-wrap">
    <input
      ref="searchInput"
      v-model="currentKeyword"
      class="c-form__input search-input"
      :class="{ show: isVisible }"
      type="text"
      name="search"
      placeholder="Search in artworks..."
      aria-label="Search"
      @keyup="handleKeyup"
      @keyup.enter="submitKeyword"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "SearchForm",
  props: {
    keyword: {
      type: String,
      required: true,
      default: "",
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      currentKeyword: this.keyword,
      storeSubscription: null,
    };
  },
  computed: mapState(["filters"]),
  watch: {
    keyword(newVal) {
      this.currentKeyword = newVal;
    },
  },
  mounted() {
    if (this.isVisible) {
      this.focusInput();
    }
    this.currentKeyword = this.keyword;
    this.storeSubscription = this.$store.subscribe((mutation) => {
      if (["delFilterKeyword", "resetFilters"].includes(mutation.type)) {
        this.currentKeyword = "";
      }
    });
  },
  updated() {
    this.focusInput();
  },
  methods: {
    focusInput() {
      this.$refs.searchInput.focus();
    },
    handleKeyup() {
      this.$store.commit("setFilterKeywordFromNavbar", this.currentKeyword);
    },
    submitKeyword() {
      if (this.currentKeyword === "") {
        return;
      }
      this.$root.$emit("submit-keyword");
    },
  },
};
</script>
