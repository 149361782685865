<template>
  <main id="latestPurchasesPage" aria-label="content">
    <section class="o-section__wrapper">
      <header class="page-header">
        <h2 class="c-section__title">Latest Purchases</h2>
      </header>
      <div v-if="categories.length" class="slick-container">
        <LatestPurchase v-for="(category, index) in categories" :key="`category_${index}`" :category="category" />
      </div>
      <div v-else class="spinner-wrap">
        <Spinner />
      </div>
    </section>
  </main>
</template>
<script>
import { mapState } from "vuex";
import LatestPurchase from "@/components/pages/LatestPurchase";
import Spinner from "@/components/ui/Spinner";

export default {
  name: "LatestPurchases",
  components: {
    LatestPurchase,
    Spinner,
  },
  computed: mapState(["categories"]),
  async created() {
    await this.$store.dispatch("getCategories");
  },
};
</script>
