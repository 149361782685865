<template>
  <main id="locationsPage" aria-label="content">
    <section class="o-section__wrapper">
      <header class="page-header">
        <h2 class="c-section__title">Locations</h2>
      </header>
      <Locations v-if="locations.length" />
      <div v-else class="spinner-wrap">
        <Spinner />
      </div>
    </section>
  </main>
</template>
<script>
import Locations from "@/components/pages/Locations";
import Spinner from "@/components/ui/Spinner";
import { mapState } from "vuex";

export default {
  name: "LocationsPage",
  components: {
    Spinner,
    Locations,
  },
  computed: mapState(["locations", "networkBusyStatus"]),
  async created() {
    await this.$store.dispatch("getLocations");
  },
};
</script>
