<template>
  <button
    :class="{
      'is-active': isInTransferCart,
    }"
    class="c-btn btn-add-cart transfer"
    aria-label="add to transfer cart"
    title="Transfer this item"
    @click.stop.prevent="addToTransferCart"
  >
    <span class="icon">
      <svg
        v-if="!isInTransferCart"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
      >
        <path
          d="M17 14h2v3h3v2h-3v3h-2v-3h-3v-2h3v-3M11.5 1L21 6v2H2V6l9.5-5m4.5 9h3v2.08L18 12c-.7 0-1.37.12-2 .34V10M2 22v-3h10.08c.19 1.14.71 2.17 1.45 3H2m8-12h3v4.68c-.46.69-.78 1.47-.92 2.32H10v-7m-6 0h3v7H4v-7z"
          fill="#626262"
        />
        <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
      </svg>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
      >
        <path
          d="M14.46 15.88l1.42-1.42L18 16.59l2.12-2.13l1.42 1.42L19.41 18l2.13 2.12l-1.42 1.42L18 19.41l-2.12 2.13l-1.42-1.42L16.59 18l-2.13-2.12M11.5 1L21 6v2H2V6l9.5-5m4.5 9h3v2.08L18 12c-.7 0-1.37.12-2 .34V10M2 22v-3h10.08c.19 1.14.71 2.17 1.45 3H2m8-12h3v4.68c-.46.69-.78 1.47-.92 2.32H10v-7m-6 0h3v7H4v-7z"
          fill="#626262"
        />
        <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
      </svg>
    </span>
    <span v-if="!isInTransferCart" class="btn-text">TRANSFER</span>
    <span v-else class="btn-text">CANCEL</span>
  </button>
</template>
<script>
import { mapState } from "vuex";
import airtableQuery from "@/services/airtableQuery.service";
import _ from "lodash";

export default {
  name: "BtnTransferCart",
  props: {
    text: {
      type: String,
      required: false,
      default: "Transfer",
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      curItemId: "",
      curTransferCartItemIds: [],
    };
  },
  watch: {
    transferCartItemIds(newVal) {
      this.curTransferCartItemIds = [...newVal];
    },
  },
  computed: {
    ...mapState(["transferCartItemIds", "curUserData", "transferOrder"]),
    isInTransferCart() {
      return this.curTransferCartItemIds.includes(this.curItemId);
    },
  },
  created() {
    this.curItemId = this.item && this.item.id;
    this.curTransferCartItemIds = _.clone(this.transferCartItemIds);
  },
  methods: {
    addToTransferCart() {
      let newTransferOrder = [];
      if (this.isInTransferCart) {
        this.curTransferCartItemIds = [...this.curTransferCartItemIds].filter((itemId) => itemId !== this.curItemId);
        this.$store.commit("removeTransferCartItemId", this.curItemId);
        this.$store.commit("removeTransferCartItem", this.curItemId);
        newTransferOrder = this.transferOrder.filter((obj) => obj.item.id !== this.curItemId);
      } else {
        this.curTransferCartItemIds.push(this.curItemId);
        this.$store.commit("addTransferCartItemId", this.curItemId);
        this.$store.commit("addTransferCartItem", this.item);
        newTransferOrder = [
          ...new Set([
            ...this.transferOrder,
            {
              item: this.item,
              newLocationId: "",
              newLocationName: "",
              notes: "",
              needBy: "",
            },
          ]),
        ];
      }
      this.$store.commit("setTransferOrder", newTransferOrder);
      airtableQuery.updateRecordsAsync("Users", [
        {
          id: this.curUserData.id,
          fields: { TransferCart: this.curTransferCartItemIds },
        },
      ]);
    },
  },
};
</script>
