<template>
  <div class="spinner">
    <i></i>
    <i></i>
    <i></i>
    <i></i>
    <i></i>
    <i></i>
    <i></i>
  </div>
</template>
<script>
export default {
  name: "Spinner",
};
</script>
