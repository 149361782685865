<template>
  <div class="add-item">
    <main id="addItemPage" aria-label="content">
      <div v-if="networkBusyStatus" class="spinner-wrap">
        <Spinner />
      </div>
      <section v-if="!networkBusyStatus" class="content-block">
        <h2 class="c-section__title">Add Item</h2>
        <iframe class="airtable-embed" src="https://airtable.com/embed/shrTTE0Wn62C4ToSu?backgroundColor=white" />
      </section>
    </main>
  </div>
</template>
<script>
import Spinner from "@/components/ui/Spinner";
import { mapState } from "vuex";

export default {
  name: "ListMerchants",
  components: {
    Spinner,
  },
  computed: mapState(["networkBusyStatus"]),
};
</script>
