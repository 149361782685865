<template>
  <div class="slick-slider-wrap">
    <header class="slider-header">
      <h3 class="c-section__title">{{ collection.fields.CollectionName }}</h3>
      <a class="btn-white" @click="handleSeeAll(collection.fields.CollectionName)">See All</a>
    </header>
    <div v-if="!collectionItems.length || isLoading" class="progress-wrap">
      <Progress fill-color="#000000" />
    </div>
    <VueSlickCarousel v-if="collectionItems.length" v-bind="imageOptions">
      <div v-for="item in collectionItems" :id="item.id" :key="item.id">
        <SlickItem :key="item.id" :item="item" />
      </div>
      <template slot="prevArrow">
        <button type="button" class="agile__nav-button agile__nav-button--prev">
          <svg width="11" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 1L1 10L10 19" stroke="#1A120F"></path>
          </svg>
        </button>
      </template>
      <template slot="nextArrow">
        <button type="button" class="agile__nav-button agile__nav-button--next">
          <svg width="11" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L10 10L1 19" stroke="#1A120F"></path>
          </svg>
        </button>
      </template>
    </VueSlickCarousel>
  </div>
</template>
<script>
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import Progress from "@/components/ui/Progress";
import SlickItem from "./SlickItem";
import VueSlickCarousel from "vue-slick-carousel";
import airtableQuery from "@/services/airtableQuery.service";
import router from "@/router";
import { agileOptions2 } from "@/constants";
import { mapState } from "vuex";
import _ from "lodash";
export default {
  name: "Collection",
  components: {
    Progress,
    SlickItem,
    VueSlickCarousel,
  },
  props: {
    collection: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      collectionItems: [],
      isLoading: "",
      imageOptions: agileOptions2,
      fields: [],
    };
  },
  computed: {
    ...mapState(["authData", "curUserPerms"]),
  },
  async created() {
    if (this.curUserPerms.permittedArtInventoryFields.includes("All")) {
      this.fields = [];
    } else {
      this.fields = _.clone(this.curUserPerms.permittedArtInventoryFields);
    }
    this.isLoading = true;
    await airtableQuery
      .getTableAsync(
        "Art Inventory",
        100,
        this.authData.user_role,
        `FIND("${this.collection.fields.CollectionName}", {Collections}) > 0`,
        this.fields
      )
      .then((artistItems) => {
        this.isLoading = false;
        artistItems.forEach((record) => {
          this.collectionItems.push(record);
        });
      });
  },
  updated() {},
  methods: {
    handleSeeAll(collection) {
      this.$store.commit("setFilterCollectionFromMenu", collection);
      this.$store.commit("setTotalResults", 0);
      router.push({
        path: "/art-inventory",
        query: { reload: true },
      });
    },
  },
};
</script>
