<template>
  <div v-if="filteredLocations.length" class="grid-wrap">
    <div v-for="(location, index) in filteredLocations" :key="index" class="grid-item">
      <div class="image" :style="getStyles(location)">
        <span class="overlay" @click.stop="setCurrentLocation(location)">
          <span class="text" v-html="handleName(location.fields.Location)"> </span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import router from "../../router";
import { mapState } from "vuex";

export default {
  name: "Locations",
  data() {
    return {
      filteredLocations: [],
    };
  },
  computed: {
    ...mapState(["locations", "curUserPerms"]),
  },
  created() {
    if (this.curUserPerms.permittedLocations[0] === "All") {
      this.filteredLocations = [...this.locations];
    } else {
      this.locations.forEach((cat) => {
        this.curUserPerms.permittedLocations.forEach((permCat) => {
          if (cat.fields.Location === permCat) {
            this.filteredLocations.push(cat);
          }
        });
      });
    }
  },
  methods: {
    setCurrentLocation(location) {
      router.push({
        path: `/location`,
        query: { name: `${location.fields.Location}`, id: location.id },
      });
    },
    handleName(name) {
      if (name.includes(":")) {
        return name.replace(":", ":<br />");
      } else if (name.includes("?")) {
        return "";
      } else {
        return name;
      }
    },
    getStyles(location) {
      if (location.fields.Attachments) {
        return `background: url(${location.fields.Attachments[0].thumbnails.large.url})`;
      } else {
        return "background: linear-gradient(0deg, rgb(87, 61, 42), rgb(15, 48, 86));";
      }
    },
  },
};
</script>
