<template>
  <div v-if="currentItems.length" class="layout-wrap">
    <div class="save-pdf-wrap">
      <button class="c-btn btn-open-save-modal" @click="openPdfModal">Save PDF</button>
    </div>
    <save-pdf-modal
      v-if="isShowingSavePdfModal"
      :key="randomKey"
      :pdf-items="currentItems"
      @close-save-pdf-modal="handleCloseModal"
    />
    <div v-if="showPagination" class="pagination-wrap top">
      <paginate
        :key="`paginateTop_${randomKey}`"
        :current-items="currentItems"
        :initial-page="initialPage"
        :current-page="currentPage"
        @change-page="onChangePage"
        @click.native="generateRandomKey"
      />
    </div>
    <div
      v-if="currentItems.length"
      :key="`masonry_${randomKey}`"
      v-images-loaded:on="getLoadingCallBack()"
      class="o-list o-list__wrap o-list__triple c-works c-works--list list-layout"
    >
      <card-list-item
        v-for="(item, index) in pageOfItems"
        :key="index"
        :item="item"
        @details-clicked="$emit('prepare-modal', item)"
        @reload-isotope="generateRandomKey"
      />
    </div>
    <div v-if="showPagination" class="pagination-wrap">
      <paginate
        :key="`paginateBottom_${randomKey}`"
        :current-items="currentItems"
        :initial-page="initialPage"
        :current-page="currentPage"
        @change-page="onChangePage"
        @click.native="generateRandomKey"
      />
    </div>
  </div>
</template>
<script>
import CardListItem from "./CardListItem";
import SavePdfModal from "../SavePdfModal";
import imagesLoaded from "vue-images-loaded";
import Paginate from "../Paginate";
import { mapState } from "vuex";
import dom from "../../../services/dom.service";

export default {
  name: "CardList",
  directives: {
    imagesLoaded,
  },
  components: {
    CardListItem,
    Paginate,
    SavePdfModal,
  },
  props: {
    currentItems: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      currentPage: 1,
      pageOfItems: [],
      randomKey: 42,
      isShowingSavePdfModal: false,
      main: document.querySelector("main"),
    };
  },
  computed: {
    ...mapState(["initialPage"]),
    showPagination() {
      return this.currentItems.length > 100;
    },
  },
  mounted() {
    if (!this.showPagination) {
      this.pageOfItems = this.currentItems;
    }
  },
  methods: {
    handleCloseModal() {
      this.isShowingSavePdfModal = false;
      dom.removeHtmlClass("is-fixed");
      this.main.classList.remove("sidebar-hidden");
    },
    openPdfModal() {
      this.main.classList.add("sidebar-hidden");
      dom.addHtmlClass("is-fixed");
      this.isShowingSavePdfModal = true;
    },
    onChangePage(pageOfItems, currentPage) {
      this.currentPage = currentPage;
      this.pageOfItems = pageOfItems;
    },
    getLoadingCallBack() {
      return {
        progress: (instance, image) => {
          let imageParent = image.img.parentElement.parentElement;
          if (image.isLoaded) {
            imageParent.classList.add("is-loaded");
          } else {
            imageParent.classList.add("is-broken");
          }
        },
      };
    },
    generateRandomKey() {
      this.randomKey = Math.floor(Math.random() * 1000000000);
    },
  },
};
</script>
