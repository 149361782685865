import axios from "axios";
import environment from "@/environments/environment";
import store from "../store";
import router from "../router";

const instance = axios.create({
  baseURL: environment.AIR_TABLE_BASE_URL,
  headers: {
    Authorization: "Bearer " + environment.API_KEY,
  },
});

let count = 0;
instance.interceptors.request.use(
  (config) => {
    count += 1;
    store.commit("setNetworkBusyStatus", true);
    return config;
  },
  (error) => {
    count += 1;
    store.commit("setNetworkBusyStatus", true);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    count -= 1;
    count ? store.commit("setNetworkBusyStatus", true) : store.commit("setNetworkBusyStatus", false);
    return response;
  },
  (error) => {
    count -= 1;
    count ? store.commit("setNetworkBusyStatus", true) : store.commit("setNetworkBusyStatus", false);
    if ([401, 403].includes(error.response.status)) {
      store.commit("delUserData");
      router.push("/login");
    }
    return Promise.reject(error);
  }
);

export default {
  get(path) {
    return instance.get(instance.defaults.baseURL + path);
  },
  post(path, params) {
    return instance.post(instance.defaults.baseURL + path, params);
  },
  put(path, params) {
    return instance.put(instance.defaults.baseURL + path, params);
  },
  patch(path, params) {
    return instance.patch(instance.defaults.baseURL + path, params);
  },
  delete(path, params) {
    return instance.delete(instance.defaults.baseURL + path, params);
  },
};
