<template>
  <main id="favoritesPage" aria-label="content">
    <section class="o-section__wrapper">
      <header class="page-header">
        <h2 class="c-section__title">Compare</h2>
        <a v-if="compareList.length" class="btn-link" @click.stop.prevent="handleClearCompareList">
          Clear compare list
        </a>
      </header>
      <div v-if="compareList.length" class="compare-list">
        <compare-item v-for="item in compareList" :key="item.id" :item="item" />
      </div>
      <div v-else>
        <div class="message-box message-box--cart">
          <h2 class="message-box__title">Your compare list is empty</h2>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import CompareItem from "@/components/pages/CompareItem.vue";
import { mapState } from "vuex";

export default {
  name: "ComparePage",
  components: {
    CompareItem,
  },
  computed: {
    ...mapState(["compareList", "curUserPerms"]),
  },
  created() {},
  methods: {
    handleClearCompareList() {
      this.$store.commit("setCompareList", []);
    },
  },
};
</script>
