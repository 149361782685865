<template>
  <div v-click-outside="handleClickOutside" class="locations-dropdown">
    <div
      ref="locationSelect"
      :class="{ 'is-open': isDropdownOpen, disabled: dropdownDisabled }"
      class="c-dropdown"
      @click="isDropdownOpen = !isDropdownOpen"
    >
      <div class="c-dropdown__title js-dropdown-title">
        <input v-model="selectedLocation" class="selected-option" />
      </div>
      <ul :id="item.id" class="c-dropdown__list">
        <li
          v-for="(location, dropItemIndex) in filteredLocations"
          :key="dropItemIndex"
          class="c-dropdown__list__items"
          @click="handleChange(location)"
        >
          <a class="c-link c-link--dropdown" :aria-label="location.fields.Location">
            {{ location.fields.Location }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import vClickOutside from "v-click-outside";
export default {
  name: "DropdownLocations",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: false,
      default: () => null,
    },
    dropdownDisabled: {
      type: Boolean,
      required: true,
      default: () => false,
    },
  },
  data() {
    return {
      isDropdownOpen: false,
      selectedLocation: "",
      filteredLocations: [],
    };
  },
  watch: {
    transferOrder: {
      handler(newVal) {
        if (newVal) {
          this.handleSetSelectedLocation();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(["locations", "curUserPerms", "transferOrder"]),
  },

  async created() {
    if (this.transferOrder.length) {
      this.handleSetSelectedLocation();
    }
    await this.$store.dispatch("getLocations");
    if (this.curUserPerms.permittedLocations[0] === "All") {
      this.filteredLocations = [...this.locations];
    } else {
      this.locations.forEach((loc) => {
        this.curUserPerms.permittedLocations.forEach((permLoc) => {
          if (loc.fields.Location === permLoc) {
            this.filteredLocations.push(loc);
          }
        });
      });
    }
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    handleSetSelectedLocation() {
      this.transferOrder.forEach((obj) => {
        if (obj.item.id === this.item.id) {
          if (obj.newLocationName === "") {
            this.selectedLocation = "Select destination";
          } else {
            this.selectedLocation = obj.newLocationName;
          }
        }
      });
    },
    handleClickOutside() {
      this.isDropdownOpen = false;
    },
    handleChange(location) {
      let newTransferOrder = this.transferOrder;
      newTransferOrder.forEach((obj, idx) => {
        if (obj.item.id === this.item.id) {
          newTransferOrder[idx] = {
            ...newTransferOrder[idx],
            newLocationId: location.id,
            newLocationName: location.fields.Location,
          };
        }
      });
      this.$store.commit("setTransferOrder", newTransferOrder);
    },
    handleKeyDown(event) {
      if (event.keyCode === 27) {
        this.isDropdownOpen = false;
      }
    },
  },
};
</script>
