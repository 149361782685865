<template>
  <div class="cart-item">
    <a :href="`/item/${item.id}`" class="item-link" target="_blank">
      <div
        v-if="item.fields.Image"
        :style="{
          backgroundImage: `url(${getImageUrl(item)})`,
        }"
        class="cart-thumb"
      />
      <div class="details">
        <h3 class="title">
          <truncate
            :length="70"
            :text="item.fields.TitleClean | titlecase | sanitize"
            action-class="is-hidden"
            clamp="See More"
            class=""
            less="See Less"
            type="html"
          />
        </h3>
        <div v-if="item.fields['Current Location']" class="current-location">
          <i class="material-icons material-icons-outlined"> room </i>
          <span>Current Location:</span>
          <span>{{ item.fields["Current Location"] || "" | titlecase }}</span>
        </div>
      </div>
    </a>
    <!-- locations dropdown -->
    <dropdown-locations
      :key="item.id"
      :item="item"
      :index="index"
      :dropdown-disabled="dropdownDisabled"
      @click.native="handleNativeClick(item)"
      @set-active-label="handleSetActiveLabel"
    />
    <!-- delete-->
    <div class="delete-item">
      <a aria-label="Delete" class="c-link c-link--bin" @click="$emit('on-remove-item', item)">
        <svg fill="none" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 7H5v6h2V7zM11 7H9v6h2V7z" fill="#B0AEA9" />
          <path
            d="M12 1c0-.6-.4-1-1-1H5c-.6 0-1 .4-1 1v2H0v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V5h1V3h-4V1zM6 2h4v1H6V2zm7 3v9H3V5h10z"
            fill="#B0AEA9"
          />
        </svg>
      </a>
    </div>
  </div>
</template>
<script>
import DropdownLocations from "@/components/ui/DropdownLocations";
import Truncate from "vue-truncate-collapsed";
import { mapState } from "vuex";

export default {
  name: "CartTransferListItem",
  components: {
    DropdownLocations,
    Truncate,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: false,
      default: () => null,
    },
    dropdownDisabled: {
      type: Boolean,
      required: true,
      default: () => false,
    },
  },
  data() {
    return {
      activeLabel: "Select destination",
    };
  },
  computed: {
    ...mapState(["transferCart", "transferCartItemIds", "transferOrder", "networkBusyStatus", "curUserData"]),
  },

  methods: {
    handleSetActiveLabel(locationName) {
      this.activeLabel = locationName;
    },
    getImageUrl(item) {
      let imageUrl;
      if (typeof item.fields.Image === "object") {
        imageUrl = item.fields.Image[item.fields.Image.length - 1].thumbnails.large.url;
      } else if (typeof item.fields.Image === "string") {
        imageUrl = item.fields.Image.thumbnails.large.url;
      }
      return imageUrl;
    },
    handleNativeClick(item) {
      let dropdown = document.getElementById(item.id);
      let windowHeight = window.outerHeight;
      let dropPos = event.clientY;
      if (dropPos > windowHeight / 2) {
        dropdown.classList.add("dropup");
      } else {
        dropdown.classList.remove("dropup");
      }
    },
  },
};
</script>
