<template>
  <header ref="navbar" v-click-outside="handleSearchOutsideClick" class="topbar">
    <a class="nav-link logo" aria-label="Logo" @click="handleClickLogo">
      <Logo />
    </a>
    <ul ref="navbarUl" class="navbar" :class="{ 'is-open': isActive }">
      <!-- add 'is-open-nav' on nav click -->
      <li class="nav-item art-inventory">
        <router-link class="nav-link" aria-label="Home" to="/art-inventory" @click.native="handleNativeClick">
          Art Inventory
        </router-link>
      </li>
      <li v-if="showPage(['Locations', 'All'])" class="nav-item locations">
        <router-link class="nav-link" aria-label="Home" to="/locations" @click.native="handleNativeClick">
          Locations
        </router-link>
      </li>
      <li v-if="showPage(['Categories', 'All'])" class="nav-item categories">
        <router-link class="nav-link" aria-label="Categories" to="/categories" @click.native="handleNativeClick">
          Categories
        </router-link>
      </li>
      <li v-if="showPage(['Merchants', 'All'])" ref="merchantsLink" class="nav-item merchants">
        <router-link class="nav-link" aria-label="Home" to="/merchants" @click.native="handleNativeClick">
          Merchants
        </router-link>
      </li>
      <li v-if="showPage(['Latest Purchases', 'All'])" class="nav-item latest">
        <router-link class="nav-link" aria-label="Home" to="/latest-purchases" @click.native="handleNativeClick">
          Latest Purchases
        </router-link>
      </li>
      <li v-if="showPage(['Collections', 'All'])" class="nav-item collections">
        <router-link class="nav-link" aria-label="Home" to="/collections" @click.native="handleNativeClick">
          Collections
        </router-link>
      </li>
      <!--      SEARCH-->
      <li class="nav-item search">
        <SearchForm v-if="isInputVisible" ref="searchCompNav" :keyword="filters.keyword" :is-visible="isInputVisible" />
        <div v-if="isMagVisible" ref="iconMag" class="md-icon icon-magnify" @click="isInputVisible = !isInputVisible">
          <Magnify />
        </div>
        <div
          v-if="isArrowVisible"
          ref="iconArrowRight"
          class="md-icon icon-arrow-right"
          :class="{ disabled: isEmpty }"
          @click="submitFilters"
        >
          <ArrowRight />
        </div>
      </li>
      <!--      COMPARE -->
      <li v-if="curUserPerms.canTransfer" id="compare" ref="compare" class="nav-item compare" title="Compare items">
        <router-link class="nav-link" aria-label="Compare" to="/compare" @click.native="handleNativeClick">
          <Compare :size="30" />
        </router-link>
      </li>
      <!--    SHOPPING CART-->
      <li v-if="curUserPerms.canBuy" id="navCart" ref="navCart" class="nav-item cart shopping">
        <router-link class="nav-link" aria-label="My Cart" to="/shopping-cart" @click.native="handleNativeClick">
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 32">
            <path
              fill="#ECDFC5"
              d="M9.65,26.68a2.67,2.67,0,1,0,0,5.32,2.67,2.67,0,1,0,0-5.32Zm19.35,0a2.67,2.67,0,1,0,2.89,2.66A2.78,2.78,0,0,0,29,26.68Zm-17.48-6H26.9a3.4,3.4,0,0,0,2.84-1.37L36,2.58,33.18,1.31,26.9,18.05H12.21L5.3,0H0V2.66H3.24L9.08,18.84,4.25,26H32.5V23.37H9.73Z"
            />
          </svg>
          <span class="cart-count">{{ shoppingCartItemIdsCount }}</span>
        </router-link>
      </li>
      <!--     TRANSFER CART-->
      <li
        v-if="curUserPerms.canTransfer"
        id="navshoppingCartItemIds"
        ref="navshoppingCartItemIds"
        class="nav-item cart transfer"
      >
        <router-link class="nav-link" aria-label="My Cart" to="/transfer-cart" @click.native="handleNativeClick">
          <svg
            id="fa7149c9-9ad7-4147-9cc1-b1fde149558e"
            data-name="mdi-bank-transfer"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 19.2"
          >
            <path
              d="M15.6,14.4V10.8h3.6V8.4L24,12.6l-4.8,4.2V14.4H15.6M14.4,6.84V8.4H0V6.84L7.2,2.4l7.2,4.44M6,9.6H8.4v6H6v-6m-4.8,0H3.6v6H1.2v-6m12,0v3l-2.4,2.16V9.6h2.4M8.52,16.8l-.72.6,2,1.8H0V16.8H8.52M18,15.6v3.6H14.4v2.4L9.6,17.4l4.8-4.2v2.4Z"
              transform="translate(0 -2.4)"
            />
          </svg>
          <span class="cart-count">{{ transferCartItemIdsCount }}</span>
        </router-link>
      </li>
      <!--     FAVORITES -->
      <li
        v-if="showPage(['My Favorites', 'All'])"
        id="navUserFavoriteItemIds"
        ref="navUserFavoriteItemIds"
        class="nav-item cart favorites"
      >
        <router-link class="nav-link" aria-label="My Cart" to="/favorites" @click.native="handleNativeClick">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            width="1em"
            height="1em"
            style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg)"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M20.205 4.791a5.938 5.938 0 0 0-4.209-1.754A5.906 5.906 0 0 0 12 4.595a5.904 5.904 0 0 0-3.996-1.558a5.942 5.942 0 0 0-4.213 1.758c-2.353 2.363-2.352 6.059.002 8.412L12 21.414l8.207-8.207c2.354-2.353 2.355-6.049-.002-8.416z"
            />
            <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
          </svg>
          <span class="cart-count">{{ userFavoriteItemIdsCount }}</span>
        </router-link>
      </li>
      <!--      USER-->
      <li class="nav-item user">
        <a ref="userDropdownTrigger" class="nav-link" aria-label="Profile" @click="handleDropdown">
          <Account />
          <span class="user-name">
            {{ authData.user_display_name }}
          </span>
          <ChevronDown />
        </a>
        <ul v-if="isUserDropdownOpen" v-click-outside="handleUserDropdownOutside" class="dropdown-menu">
          <li v-if="showPage(['My Requests', 'All'])" class="nav-item">
            <router-link
              class="nav-link"
              aria-label="My Requests"
              to="/transfer-cart"
              @click.native="handleNativeClick"
            >
              My Requests
            </router-link>
          </li>
          <li v-if="showPage(['Room Scan', 'All'])" class="nav-item">
            <router-link class="nav-link" aria-label="Room Scan" to="/room-scan" @click.native="handleNativeClick">
              Room Scan
            </router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" aria-label="Profile" @click.stop.prevent="logout"> Logout </a>
          </li>
        </ul>
      </li>
    </ul>
    <div class="burger-wrap">
      <button
        class="hamburger hamburger--3dy"
        :class="{ 'is-active': isActive }"
        type="button"
        @click="handleHamburger"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner" />
        </span>
      </button>
    </div>
  </header>
</template>
<script>
import { mapState } from "vuex";
import SearchForm from "./SearchForm";
import { mapActions } from "vuex";
import router from "../../router";
import Logo from "./Logo";
import Account from "vue-material-design-icons/Account";
import ChevronDown from "vue-material-design-icons/ChevronDown";
import ArrowRight from "vue-material-design-icons/ArrowRight";
import Compare from "vue-material-design-icons/Compare";
import Magnify from "vue-material-design-icons/Magnify";
import vClickOutside from "v-click-outside";

export default {
  name: "Navbar",
  components: {
    Account,
    ArrowRight,
    ChevronDown,
    Compare,
    Logo,
    Magnify,
    SearchForm,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      isActive: false,
      isArrowVisible: false,
      isInputVisible: false,
      isMagVisible: true,
      isUserDropdownOpen: false,
      shoppingCartItemIdsCount: 0,
      transferCartItemIdsCount: 0,
      userFavoriteItemIdsCount: 0,
    };
  },
  computed: {
    ...mapState([
      "authData",
      "categories",
      "collections",
      "curUserPerms",
      "filters",
      "isNoInventoryQuery",
      "locations",
      "merchants",
      "networkBusyStatus",
      "shoppingCartItemIds",
      "transferCartItemIds",
      "userFavoriteItemIds",
    ]),
    isEmpty() {
      return this.filters.keyword === "";
    },
  },
  watch: {
    permittedArtInventoryFields: {
      handler(newVal) {
        if (newVal) {
          this.permittedArtInventoryFields = [...newVal];
        }
      },
    },
    userFavoriteItemIds: {
      handler(newVal) {
        if (newVal) {
          this.userFavoriteItemIdsCount = [...newVal].length;
        }
      },
    },
    shoppingCartItemIds: {
      handler(newVal) {
        if (newVal) {
          this.shoppingCartItemIdsCount = newVal.length;
        }
      },
    },
    transferCartItemIds: {
      handler(newVal) {
        if (newVal) {
          this.transferCartItemIdsCount = newVal.length;
        }
      },
    },
    networkBusyStatus: {
      handler(newVal) {
        if (newVal === false) {
          this.isUserDropdownOpen = false;
        }
      },
      deep: true,
    },
  },
  async created() {
    this.$store.dispatch("getUserFavorites");
    this.shoppingCartItemIdsCount = this.shoppingCartItemIds.length;
    this.transferCartItemIdsCount = this.transferCartItemIds.length;
    this.userFavoriteItemIdsCount = this.userFavoriteItemIds.length;
    console.log(`%c NAVBAR`, "font-size: 13px; background-color:  #2B3D2D; color: white; padding: 3px 9px 3px 4px;");
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("resize", this.onResize);
    this.isUserDropdownOpen = false;
  },
  updated() {
    if (this.isInputVisible) {
      this.isMagVisible = false;
      this.isArrowVisible = true;
    } else {
      this.isMagVisible = true;
      this.isArrowVisible = false;
    }
  },
  destroyed() {
    document.removeEventListener("keydown", this.handleKeyDown);
  },

  methods: {
    showPage(arr) {
      return arr.some((v) => this.curUserPerms.permittedPages.includes(v));
    },
    handleSearchOutsideClick() {
      this.isInputVisible = false;
    },
    handleUserDropdownOutside() {
      this.isUserDropdownOpen = false;
    },
    onResize() {
      this.isActive = false;
      this.isInputVisible = false;
    },
    handleDropdown() {
      this.isUserDropdownOpen = !this.isUserDropdownOpen;
    },
    handleKeyDown(event) {
      if (event.keyCode === 27) {
        this.isInputVisible = false;
        this.isMagVisible = true;
        this.isArrowVisible = false;
      }
    },
    handleHamburger() {
      this.isActive = !this.isActive;
      this.$parent["$parent"].$emit("menu-toggle", this.isActive);
    },
    handleNativeClick() {
      this.isActive = !this.isActive;
      window.scrollTo(0, 0);
    },
    handleClickLogo() {
      if (this.$route.name !== "ArtInventoryPage") {
        router.push({
          path: "/art-inventory",
          query: { reload: false, reset: true },
        });
      }
    },
    submitFilters() {
      this.isActive = false;
      this.isInputVisible = false;
      this.isMagVisible = true;
      this.isArrowVisible = false;
      this.$root.$emit("submit-keyword");
    },
    ...mapActions(["logout"]),
  },
};
</script>
