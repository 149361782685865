<template>
  <div :class="barClass" class="item-detail-row">
    <!--      location link-->
    <a v-if="locationName" class="c-works__location" @load="log(item)" @click.stop="setCurrentLocation(locationName)">
      <MapMarker />
      <span>{{ locationName | titlecase }}</span>
      <span v-if="isList && subLocationName" class="ml-2"> ({{ subLocationName | titlecase }}) </span>
    </a>
    <div v-if="inventoryNumber" class="item-inventory-number">
      <ViewListOutline />
      <span>#{{ inventoryNumber }}</span>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ViewListOutline from "vue-material-design-icons/ViewListOutline";
import MapMarker from "vue-material-design-icons/MapMarker";
export default {
  name: "MetaBar",
  components: {
    MapMarker,
    ViewListOutline,
  },
  props: {
    barClass: {
      type: String,
      required: false,
      default: "",
    },
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      locationName: null,
      inventoryNumber: null,
      locationId: null,
      subLocationName: null,
      subLocationRecordId: null,
    };
  },
  computed: {
    ...mapState(["authData", "userRoles", "curUserPerms"]),
    isList() {
      return this.barClass === "list";
    },
  },
  created() {
    this.locationName = this.item.fields.LocationName ? this.item.fields.LocationName : null;
    this.inventoryNumber = this.item.fields["Inventory No."] ? this.item.fields["Inventory No."] : null;
    this.locationId = this.item.fields.LocationRecordId ? this.item.fields.LocationRecordId[0] : null;
    this.subLocationName = this.item.fields.SubLocationName ? this.item.fields.SubLocationName : null;
    this.subLocationRecordId = this.item.fields.SubLocationRecordId ? this.item.fields.SubLocationRecordId[0] : null;
  },
  methods: {
    setCurrentLocation() {
      this.$store.commit("setFilterLocationFromMenu", this.locationId);
      this.$store.commit("setTotalResults", 0);
      this.$store.commit("setInitialPage", 1);
      this.$root.$emit("submit-filters");
    },
  },
};
</script>
