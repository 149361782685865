<template>
  <div v-if="images" class="slider">
    <a
      v-for="(item, index) in images"
      :key="index"
      :href="getFull(item)"
      class="slide"
      :style="{ backgroundImage: getThumb(item) }"
      :data-fancybox="locationId"
    />
  </div>
</template>
<script>
export default {
  name: "ImageSlider",
  props: {
    images: {
      type: Array,
      required: true,
    },
    locationId: {
      type: String,
      required: true,
      default: "",
    },
  },
  methods: {
    getThumb(item) {
      if (item && item.thumbnails.large) {
        return `url(${item.thumbnails.large.url}`;
      }
    },
    getFull(item) {
      if (item && item.thumbnails.full) {
        return item.thumbnails.full.url;
      } else {
        return item.thumbnails.large.url;
      }
    },
  },
};
</script>
