<template>
  <main id="locationPage" aria-label="content">
    <div v-if="showPage && !networkBusyStatus" class="fullpage-wrap">
      <full-page ref="fullpage" :options="fullPageOptions">
        <!--      INTRO-->
        <!--      INTRO-->
        <!--      INTRO-->
        <section id="introSection" class="section" data-anchor="intro">
          <div v-if="currentLocation.fields.Location" class="content">
            <header class="intro-header">
              <div class="intro-title">
                <div class="cycad">
                  <span class="slide-up">CYCAD MGMT</span>
                </div>
                <div class="location">
                  <span class="slide-down">{{ currentLocation.fields.Location }}</span>
                </div>
              </div>
            </header>
            <div :style="heroStyle" class="hero fade-in"></div>
            <footer>
              <div class="buttons">
                <span class="c-btn c-btn--primary" @click.stop="handleViewInventory(currentLocation.fields.Location)">
                  inventory
                </span>
                <a
                  v-if="currentLocation.fields.TourLink"
                  :href="currentLocation.fields.TourLink"
                  class="c-btn c-btn--primary"
                  target="_blank"
                >
                  virtual tour
                </a>
              </div>
              <div class="back">
                <router-link aria-label="Location" class="btn-back" to="/locations" @click.native="scrollToTop">
                  &#8592; Back to locations
                </router-link>
              </div>
            </footer>
          </div>
          <div v-else class="spinner-wrap">
            <Spinner />
          </div>
        </section>
        <!--      DETAILS-->
        <!--      DETAILS-->
        <!--      DETAILS-->
        <section v-if="!hideDetails" id="detailsSection" class="section" data-anchor="details">
          <div class="content">
            <header>
              <div class="location-name">
                {{ currentLocation.fields.Location }}
              </div>
            </header>
            <div class="container">
              <div v-if="showField(['DetailImages', 'All']) && DetailImages.length" class="detail-images">
                <div v-for="(item, index) in DetailImages" :key="index" :style="getImage(item)" class="image" />
              </div>
              <div v-if="showField(['PropertyOverviewBodyText', 'All'])" class="description">
                <h4 class="royal-text text-center">About this property</h4>
                <p v-if="PropertyOverviewBodyText">
                  {{ currentLocation.fields.PropertyOverviewBodyText }}
                </p>
                <div v-else class="no-data">
                  <p>No details are available for this property at this time.</p>
                </div>
              </div>
              <div class="details-wrap">
                <div v-if="showField(['BedroomCount', 'All']) && currentLocation.fields.BedroomCount" class="detail">
                  <div class="inner">
                    <span class="value">{{ currentLocation.fields.BedroomCount }}</span>
                    <label>Bedrooms</label>
                  </div>
                </div>
                <div v-if="showField(['BathroomCount', 'All']) && currentLocation.fields.BathroomCount" class="detail">
                  <div class="inner">
                    <span class="value">{{ currentLocation.fields.BathroomCount }}</span>
                    <label>Bath</label>
                  </div>
                </div>
                <div v-if="showField(['ParkingSpaces', 'All']) && currentLocation.fields.ParkingSpaces" class="detail">
                  <div class="inner">
                    <span class="value">{{ currentLocation.fields.ParkingSpaces }}</span>
                    <label>Parking Spaces</label>
                  </div>
                </div>
                <div v-if="showField(['LotSize', 'All']) && currentLocation.fields.LotSize" class="detail">
                  <div class="inner">
                    <span class="value">{{ currentLocation.fields.LotSize }}</span>
                    <label>Lot Size (acres)</label>
                  </div>
                </div>
                <div v-if="showField(['PetsAllowed', 'All']) && currentLocation.fields.PetsAllowed" class="detail">
                  <div class="inner">
                    <span class="value">{{ currentLocation.fields.PetsAllowed }}</span>
                    <label>Pets Allowed</label>
                  </div>
                </div>
                <div v-if="showField(['Stories', 'All']) && currentLocation.fields.Stories" class="detail">
                  <div class="inner">
                    <span class="value">{{ currentLocation.fields.Stories }}</span>
                    <label>Stories</label>
                  </div>
                </div>
                <div v-if="showField(['SquareFootage', 'All']) && currentLocation.fields.SquareFootage" class="detail">
                  <div class="inner">
                    <span class="value">{{ currentLocation.fields.SquareFootage | commas }}</span>
                    <label>Square Footage</label>
                  </div>
                </div>
                <div
                  v-if="showField(['OriginalListPrice', 'All']) && currentLocation.fields.OriginalListPrice"
                  class="detail"
                >
                  <div class="inner">
                    <span class="value">
                      ${{ kFormatter(currentLocation.fields.OriginalListPrice) | commas }}<span class="small">/mo</span>
                    </span>
                    <label>Original Price</label>
                  </div>
                </div>
              </div>
              <div v-if="showField(['OriginalListPrice', 'All']) && DetailImages.length" class="detail-images">
                <div v-for="(item, index) in DetailImages" :key="index" :style="getImage(item)" class="image" />
              </div>
            </div>
          </div>
        </section>
        <!--      GALLERY -->
        <!--      GALLERY -->
        <!--      GALLERY -->
        <section id="gallerySection" class="section" data-anchor="gallery">
          <div class="content">
            <header>
              <div class="location-name">
                {{ currentLocation.fields.Location }}
              </div>
              <nav class="gallery-nav">
                <ul>
                  <li
                    v-for="(item, index) in galleries"
                    :id="`galleryMenu_${index}`"
                    :key="`galleryMenu_${index}`"
                    class="gallery-nav-item"
                    @click="handleGalleryMenuClick(item, index)"
                  >
                    {{ item.nav }}
                  </li>
                </ul>
              </nav>
            </header>
            <!--          SLIDER-->
            <SliderControls v-if="galleryImages" @move-slide="handleMoveSlide" />
            <div v-if="galleryImages && galleryImages.length" class="gallery">
              <ImageSlider :images="galleryImages" :location-id="currentLocation.id" />
            </div>
            <div v-else class="no-data">
              <p>No images yet.</p>
            </div>
          </div>
        </section>
        <!--      CONTACT-->
        <!--      CONTACT-->
        <!--      CONTACT-->
        <section id="contactSection" class="section" data-anchor="contact">
          <div class="content">
            <header>
              <div class="location-name">
                {{ currentLocation.fields.Location }}
              </div>
            </header>
            <div class="container">
              <div v-if="HouseManagerName" class="detail">
                <h4 class="royal-text">Contact</h4>
                <span>{{ currentLocation.fields.HouseManagerName }}</span>
                <br />
                <span>{{ currentLocation.fields.HouseManagerPhone }}</span>
              </div>
              <div v-else class="no-data">
                <p>No contact information yet.</p>
              </div>
            </div>
          </div>
        </section>
      </full-page>
      <ul class="page-nav">
        <li class="active" data-menuanchor="intro">
          <a href="#intro"><span class="text">INTRO</span><span class="line"></span></a>
        </li>
        <li v-if="!hideDetails" data-menuanchor="details">
          <a href="#details"><span class="text">DETAILS</span><span class="line"></span></a>
        </li>
        <li data-menuanchor="gallery">
          <a href="#gallery"><span class="text">GALLERY</span><span class="line"></span></a>
        </li>
        <li data-menuanchor="contact">
          <a href="#contact"><span class="text">CONTACT</span><span class="line"></span></a>
        </li>
      </ul>
      <a class="btn-page-scroll" @click="$refs.fullpage.api.moveSectionDown()">
        <img alt="Scroll Down" src="/assets/images/btn_scroll_down.svg" />
      </a>
    </div>
    <div v-else class="spinner-wrap">
      <Spinner />
    </div>
  </main>
</template>
<script>
import ImageSlider from "../ui/ImageSlider";
import SliderControls from "../ui/SliderControls";
import Spinner from "@/components/ui/Spinner";
import router from "../../router";
import { mapState } from "vuex";
import airtableQuery from "@/services/airtableQuery.service";

export default {
  name: "Location",
  components: {
    ImageSlider,
    SliderControls,
    Spinner,
  },
  data() {
    return {
      Bedroom1: [],
      Bedroom2: [],
      Bedroom3: [],
      Bedroom4: [],
      Bedroom5: [],
      Bedroom6: [],
      Bedroom7: [],
      DetailImages: [],
      HeroImage: "",
      HouseManagerName: "",
      activeMenuIndex: 0,
      showPage: false,
      fullPageOptions: {
        licenseKey: "0724124E-60C14749-B9E515C2-5D939BC3",
        normalScrollElements: "",
        responsiveWidth: 740,
        responsiveHeight: 900,
        menu: ".page-nav",
        afterLoad: this.afterLoad,
        afterSlideLoad: this.afterSlideLoad,
        onLeave: this.onLeave,
        onSlideLeave: this.onSlideLeave,
        afterResize: this.afterResize,
        controlArrows: false,
        fitToSection: false,
        dragAndMove: true,
        anchors: ["intro", "details", "gallery", "contact"],
        navigation: false,
      },
      galleries: [],
      galleryBedrooms: [],
      galleryCinema: [],
      galleryDen: [],
      galleryDiningRoom: [],
      galleryEntry: [],
      galleryExterior: [],
      galleryGym: [],
      galleryImages: [],
      galleryInfo: [],
      galleryKitchen: [],
      galleryLaundryRoom: [],
      galleryLivingRoom: [],
      galleryPool: [],
      galleryStudy: [],
      isFirst: true,
      isLast: false,
      currentLocation: {},
      lastGalleryIndex: undefined,
      startingIndex: 0,
      PropertyOverviewBodyText: undefined,
      hideDetails: false,
    };
  },
  computed: {
    ...mapState(["networkBusyStatus", "fetchingData", "curUserPerms"]),
    heroStyle() {
      return {
        background: `url(${this.HeroImage})`,
      };
    },
  },
  watch: {
    activeMenuIndex: {
      handler(newVal) {
        $(".gallery-nav-item").removeClass("active");
        $(`#galleryMenu_${newVal}`).addClass("active");
      },
    },
  },
  async created() {
    this.currentLocationId = this.$route.query.id;
    this.fetchData();
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("click", this.handleClick);
  },
  mounted() {
    $("#galleryMenu_0").addClass("active");
    const body = document.getElementById("app");
    body.classList.add("location-page");
  },
  destroyed() {
    const body = document.getElementById("app");
    body.classList.remove("location-page");
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("click", this.handleClick);
  },
  methods: {
    showField(arr) {
      return arr.some((v) => this.curUserPerms.permittedLocationFields.includes(v));
    },
    async fetchData() {
      this.currentLocation = await airtableQuery.getRecordAsync("Locations", this.currentLocationId);
      this.showPage = true;
      if (this.currentLocation.fields.Bedroom1) {
        this.galleryBedrooms.push(this.Bedroom1);
      }
      if (this.currentLocation.fields.Bedroom2) {
        this.galleryBedrooms.push(this.Bedroom2);
      }
      if (this.currentLocation.fields.Bedroom3) {
        this.galleryBedrooms.push(this.Bedroom3);
      }
      if (this.currentLocation.fields.Bedroom4) {
        this.galleryBedrooms.push(this.Bedroom4);
      }
      if (this.currentLocation.fields.Bedroom5) {
        this.galleryBedrooms.push(this.Bedroom5);
      }
      if (this.currentLocation.fields.Bedroom6) {
        this.galleryBedrooms.push(this.Bedroom6);
      }
      if (this.currentLocation.fields.Bedroom7) {
        this.galleryBedrooms.push(this.Bedroom7);
      }

      this.galleryBedrooms = this.galleryBedrooms.flat() || null;
      if (this.galleryBedrooms) {
        this.galleries.push({
          gallery: this.galleryBedrooms,
          nav: "Bedrooms",
          count: this.galleryBedrooms.length,
        });
      }

      this.galleryCinema = this.currentLocation.fields.galleryCinema || null;

      if (this.galleryCinema) {
        this.galleries.push({
          gallery: this.galleryCinema,
          nav: "Cinema",
          count: this.galleryCinema.length,
        });
      }
      this.galleryDen = this.currentLocation.fields.galleryDen || null;
      if (this.galleryDen) {
        this.galleries.push({
          gallery: this.galleryDen,
          nav: "Den",
          count: this.galleryDen.length,
        });
      }
      this.galleryDiningRoom = this.currentLocation.fields.galleryDiningRoom || null;
      if (this.galleryDiningRoom) {
        this.galleries.push({
          gallery: this.galleryDiningRoom,
          nav: "Dining Room",
          count: this.galleryDiningRoom.length,
        });
      }
      this.galleryEntry = this.currentLocation.fields.galleryEntry || null;
      if (this.galleryEntry) {
        this.galleries.push({
          gallery: this.galleryEntry,
          nav: "Entry",
          count: this.galleryEntry.length,
        });
      }
      this.galleryExterior = this.currentLocation.fields.galleryExterior || null;
      if (this.galleryExterior) {
        this.galleries.push({
          gallery: this.galleryExterior,
          nav: "Exterior",
          count: this.galleryExterior.length,
        });
      }
      this.galleryGym = this.currentLocation.fields.galleryGym || null;
      if (this.galleryGym) {
        this.galleries.push({
          gallery: this.galleryGym,
          nav: "Gym",
          count: this.galleryGym.length,
        });
      }
      this.galleryKitchen = this.currentLocation.fields.galleryKitchen || null;
      if (this.galleryKitchen) {
        this.galleries.push({
          gallery: this.galleryKitchen,
          nav: "Kitchen",
          count: this.galleryKitchen.length,
        });
      }
      this.galleryLaundryRoom = this.currentLocation.fields.galleryLaundryRoom || null;
      if (this.galleryLaundryRoom) {
        this.galleries.push({
          gallery: this.galleryLaundryRoom,
          nav: "Laundry Room",
          count: this.galleryLaundryRoom.length,
        });
      }
      this.galleryLivingRoom = this.currentLocation.fields.galleryLivingRoom || null;
      if (this.galleryLivingRoom) {
        this.galleries.push({
          gallery: this.galleryLivingRoom,
          nav: "Living Room",
          count: this.galleryLivingRoom.length,
        });
      }
      this.galleryPool = this.currentLocation.fields.galleryPool || null;
      if (this.galleryPool) {
        this.galleries.push({
          gallery: this.galleryPool,
          nav: "Pool",
          count: this.galleryPool.length,
        });
      }
      this.galleryStudy = this.currentLocation.fields.galleryStudy || null;
      if (this.galleryStudy) {
        this.galleries.push({
          gallery: this.galleryStudy,
          nav: "Study",
          count: this.galleryStudy.length,
        });
      }
      this.galleries.forEach((gallery) => {
        this.galleryImages.push(gallery.gallery);
        this.galleryInfo.push({
          imageCount: gallery.count,
          startIndex: this.startingIndex,
        });
        this.startingIndex += gallery.count;
      });

      this.HeroImage = this.currentLocation.fields.HeroImage
        ? this.currentLocation.fields.HeroImage[0].thumbnails.full.url
        : this.currentLocation.fields.Attachments[0].thumbnails.full.url;
      this.galleryImages = this.galleryImages.flat();
      this.lastGalleryIndex = this.galleries.length - 1;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    afterResize(width, height) {
      if (width < 740 || height < 900) {
        this.$refs.fullpage.api.setAutoScrolling(false);
      } else {
        this.$refs.fullpage.api.setAutoScrolling(true);
      }
    },
    onSlideLeave(section, origin, destination) {
      this.galleryInfo.forEach((gallery, index) => {
        if (index === 0 && destination.index <= gallery.startIndex) {
          this.activeMenuIndex = 0;
        } else if (destination.index >= gallery.startIndex) {
          this.activeMenuIndex = index;
        }
      });
      this.isLast = destination.isLast;
      this.isFirst = destination.isFirst;
      if (this.isFirst) {
        $(".slide-arrow").removeClass("disabled");
        $("#arrowLeft").addClass("disabled");
      } else if (this.isLast) {
        $(".slide-arrow").removeClass("disabled");
        $("#arrowRight").addClass("disabled");
      } else {
        $(".slide-arrow").removeClass("disabled");
      }
    },
    onLeave(origin, destination) {
      let gallery = $(".gallery");
      if (destination.index === 3) {
        $(".btn-page-scroll").fadeOut();
      } else if (origin.index === 3) {
        $(".btn-page-scroll").show();
      }
      if (destination.index === 1) {
        $(".details-wrap, .description").fadeTo(1500, 1, ["swing"]);
        $(".detail-images").fadeTo(2000, 1, ["swing"]);
      }
      if (destination.index === 2) {
        gallery.fadeTo(1200, 1, ["swing"]);
      }
    },
    handleMoveSlide(dir) {
      if (dir === "left") {
        this.$refs.fullpage.api.moveSlideLeft();
      }
      if (dir === "right") {
        this.$refs.fullpage.api.moveSlideRight();
      }
    },
    handleGalleryMenuClick(item, index) {
      $(".gallery-nav-item").removeClass("active");
      $(`#galleryMenu_${index}`).addClass("active");
      this.$refs.fullpage.api.silentMoveTo(3, this.galleryInfo[index].startIndex);
    },
    handleKeyDown(event) {
      if (event.keyCode === 27) {
        this.isOpen = false;
      }
    },
    handleClick(event) {
      if (this.isOpen === true) {
        const isInside = this.$refs.qualitySelect.contains(event.target);
        if (!isInside) {
          if (this.isOpen) {
            this.isOpen = false;
          }
        }
      }
    },
    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },
    getImage(item) {
      if (item.thumbnails.full) {
        return `background: url(${item.thumbnails.full.url}`;
      } else {
        return `background: url(${item.thumbnails.large.url}`;
      }
    },
    handleViewInventory() {
      this.$store.commit("setFilterLocationFromMenu", this.currentLocation.id);
      this.$store.commit("setTotalResults", 0);
      this.$store.commit("setInitialPage", 1);
      router.push({
        path: "/art-inventory",
        query: { reload: true },
      });
    },
  },
};
</script>
