<template>
  <ul class="c-filter__list">
    <li
      v-for="(merchant, index) in filteredMerchants"
      :key="index"
      class="c-filter__list__items"
      @click.stop="setCurrentMerchant(merchant.fields.Merchant)"
    >
      <div class="li-inner">
        <a
          :class="{
            'is-selected': filters.merchants.includes(merchant.fields.Merchant),
          }"
          class="c-link c-link--filter"
        >
          <span class="label">{{ merchant.fields.Merchant }}</span>
          <span v-if="curUserPerms.canViewCollectionCount">({{ merchant.fields.CollectionCount }})</span>
        </a>
      </div>
    </li>
  </ul>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "Merchants",
  data() {
    return {
      filteredMerchants: [],
    };
  },
  computed: mapState(["filters", "merchants", "curUserPerms"]),
  async created() {
    await this.$store.dispatch("getMerchants");
    if (this.curUserPerms.permittedMerchants[0] === "All") {
      this.filteredMerchants = [...this.merchants];
    } else {
      this.merchants.forEach((merch) => {
        this.curUserPerms.permittedMerchants.forEach((permMerch) => {
          if (merch.fields.Merchant === permMerch) {
            this.filteredMerchants.push(merch);
          }
        });
      });
    }
  },
  methods: {
    setCurrentMerchant(merchant) {
      if (this.filters) {
        this.filters.merchants.includes(merchant)
          ? this.$store.commit("delFilterMerchant", merchant)
          : this.$store.commit("setFilterMerchant", merchant);
      }
    },
  },
};
</script>
