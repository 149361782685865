import airtableClient from "../services/airtableClient.service";
import environment from "@/environments/environment";
import Airtable from "airtable";
import store from "../store/index";
import { allFields } from "@/constants";

const base = new Airtable({ apiKey: environment.API_KEY }).base(environment.AIR_TABLE_BASE_ID);

export default {
  createQuery(filters, offset, authData, sortByArr, permittedArtInventoryFields) {
    let userEmail = authData.user_email;
    let userRole = authData.user_role;
    let permFieldsString = "";
    if (permittedArtInventoryFields.includes("All")) {
      permFieldsString = "";
    } else {
      permittedArtInventoryFields.forEach((field) => {
        if (!allFields.includes(field)) {
          console.log(`The field "${field}" does not exist in the Art Inventory table`);
        } else {
          let encodedField = field.replace(/\s/g, "+");
          permFieldsString += `&fields%5B%5D=${encodedField}`;
        }
      });
    }

    let query = [];
    let encodedURI = "";
    let offsetURI = "";
    let sortURI = "";

    if (filters.keyword) {
      let keyword = filters.keyword.replace(/(^"|"$)/g, "");
      query.push(
        encodeURIComponent(
          `OR(
          SEARCH("${keyword.toLowerCase().trim()}", LOWER({TitleClean})),
          SEARCH("${keyword.toLowerCase().trim()}", LOWER({DescriptionClean})),
          SEARCH("${keyword.toLowerCase().trim()}", LOWER({Sale})),
          SEARCH("${keyword.toLowerCase().trim()}", LOWER({QualityName})),
          SEARCH("${keyword.toLowerCase().trim()}", LOWER({Invoice No.})),
          SEARCH("${keyword.toLowerCase().trim()}", LOWER({Lot No.})),
          SEARCH("${keyword.toLowerCase().trim()}", LOWER({Inventory No.})),
          SEARCH("${keyword.toLowerCase().trim()}", LOWER({Notes})),
          SEARCH("${keyword.toLowerCase().trim()}", LOWER({LocationName}))
          )`
        )
      );
    }
    if (filters.locations) {
      this.pushTheFilters(filters, "locations", "LocationRecordId", query);
    }
    if (filters.sublocations) {
      this.pushTheFilters(filters, "sublocations", "SublocationRecordId", query);
    }

    if (filters.collections) {
      this.pushTheFilters(filters, "collections", "Collections", query);
    }
    if (filters.categories) {
      this.pushTheFilters(filters, "categories", "Category", query);
    }
    if (filters.merchants) {
      this.pushTheFilters(filters, "merchants", "Merchant", query);
    }
    if (filters.quality) {
      this.pushTheFilters(filters, "quality", "Quality", query);
    }
    if (filters.favorites === "true") {
      query.push(encodeURIComponent(`FIND("${userEmail}", {Favorites}) > 0`));
    }
    if (filters.forSale === true) {
      query.push(encodeURIComponent(`AND({ForSale} != '')`));
    }
    if (filters.collateralized === true && filters.freeAsset === true) {
      query.push(
        encodeURIComponent(`OR({GRAPH - COLLATERAL / FREE} = 'COLLATERAL', {GRAPH - COLLATERAL / FREE} = 'FREE ASSET')`)
      );
    } else if (filters.collateralized === true) {
      query.push(encodeURIComponent(`{GRAPH - COLLATERAL / FREE} = 'COLLATERAL'`));
    } else if (filters.freeAsset === true) {
      query.push(encodeURIComponent(`{GRAPH - COLLATERAL / FREE} = 'FREE ASSET'`));
    }

    let minP = parseInt(filters.minPrice);
    let maxP = parseInt(filters.maxPrice);
    let minL = parseInt(filters.minLength);
    let maxL = parseInt(filters.maxLength);
    let minW = parseInt(filters.minWidth);
    let maxW = parseInt(filters.maxWidth);
    let hasPriceRange = minP !== 0 || maxP > 0;
    let hasLengthRange = minL !== 0 || maxL > 0;
    let hasWidthRange = minW !== 0 || maxW > 0;

    if (hasPriceRange) {
      if (minP > 0 && maxP !== 5000000) {
        query.push(encodeURIComponent(`AND({Value} >= "${minP}", {Value} <= "${maxP}")`));
      } else if (minP > 0 && maxP === 5000000) {
        query.push(encodeURIComponent(`AND({Value} >= "${minP}", {Value} <= 5000000)`));
      } else if (minP === 0 && maxP !== 5000000) {
        query.push(encodeURIComponent(`AND({Value} >= 1, {Value} <= "${maxP}")`));
      }
    } else if (filters.hasNoValue === true) {
      query.push(encodeURIComponent(`AND({Value} = '')`));
    } else if (filters.excludeItemsWithNoValue === true) {
      query.push(encodeURIComponent(`NOT({Value} = '')`));
    }
    if (hasLengthRange) {
      if (minL > 0 && maxL !== 500) {
        query.push(
          encodeURIComponent(`AND({Length ${filters.units}} >= ${minL}, {Length ${filters.units}} <= ${maxL})`)
        );
      } else if (minL > 0 && maxL === 500) {
        query.push(
          encodeURIComponent(`AND({Length ${filters.units}} >= ${minL}, {Length ${filters.units}} <= ${maxL})`)
        );
      } else if (minL === 0 && maxL !== 500) {
        query.push(encodeURIComponent(`AND({Length ${filters.units}} >= 1, {Length ${filters.units}} <= ${maxL})`));
      }
    }
    if (hasWidthRange) {
      if (minW > 0 && maxW !== 500) {
        query.push(encodeURIComponent(`AND({Width ${filters.units}} >= ${minW}, {Width ${filters.units}} <= ${maxW})`));
      } else if (minW > 0 && maxW === 500) {
        query.push(encodeURIComponent(`AND({Width ${filters.units}} >= ${minW}, {Width ${filters.units}} <= ${maxW})`));
      } else if (minW === 0 && maxW !== 500) {
        query.push(encodeURIComponent(`AND({Width ${filters.units}} >= 1, {Width ${filters.units}} <= ${maxW})`));
      }
    }
    if (filters.dateStart || filters.dateEnd) {
      if (filters.dateStart && filters.dateEnd) {
        query.push(
          encodeURIComponent(
            `AND(IS_AFTER({Sale Date}, DATETIME_PARSE('${filters.dateStart}')), IS_BEFORE({Sale Date}, DATETIME_PARSE('${filters.dateEnd}')))`
          )
        );
      } else if (filters.dateStart) {
        query.push(encodeURIComponent(`AND(IS_AFTER({Sale Date}, DATETIME_PARSE('${filters.dateStart}')))`));
      } else if (filters.dateEnd) {
        query.push(encodeURIComponent(`AND(IS_BEFORE({Sale Date}, DATETIME_PARSE('${filters.dateEnd}')))`));
      }
    }
    query.forEach((value, index) => {
      if (typeof value === "string") {
        index ? (encodedURI += `${encodeURIComponent(",")}${value}`) : (encodedURI += `${value}`);
      } else if (typeof value === "object") {
        let temp = "";
        value.forEach((e, i) => {
          !i ? (temp = `${e}`) : (temp += `${encodeURIComponent(",")}${e}`);
        });
        index
          ? (encodedURI += temp ? `${encodeURIComponent(",")}OR(${temp})` : "")
          : (encodedURI += temp ? `OR(${temp})` : "");
      }
    });
    if (encodedURI.length) {
      encodedURI = `&filterByFormula=AND(${encodedURI})`;
    }
    if (filters.itemIds !== "") {
      encodedURI = filters.itemIds;
    }
    if (offset.length > 0) {
      offsetURI = `&offset=${offset}`;
    }
    if (sortByArr) {
      sortByArr.forEach((sort, i) => {
        sortURI += `&sort%5B${i}%5D%5Bfield%5D=${encodeURIComponent(
          sort.field
        )}&sort%5B${i}%5D%5Bdirection%5D=${sort.direction}`;
      });
    }
    let finalEncodedURI = `Art%20Inventory?view=${userRole}${encodedURI}${sortURI}${offsetURI}${permFieldsString}`;
    return this.getTheData(finalEncodedURI);
  },
  pushTheFilters(filters, filterName, field, query) {
    if (filters[filterName].length === 1) {
      if (filterName === "collections") {
        query.push(encodeURIComponent(`FIND(", ${filters[filterName][0]},",CollectionsFormula)`));
      } else if (filterName === "sublocations" || filterName === "locations") {
        query.push(encodeURIComponent(`SEARCH("${filters[filterName]}", ARRAYJOIN(${field})) > 0`));
      } else {
        query.push(encodeURIComponent(`FIND("${filters[filterName][0]}", {${field}}) > 0`));
      }
    } else if (filters[filterName].length > 1) {
      if (filterName === "collections") {
        query.push([...filters[filterName].map((e) => encodeURIComponent(`FIND(", ${e},",CollectionsFormula)`))]);
      } else if (filterName === "sublocations" || filterName === "locations") {
        query.push([...filters[filterName].map((e) => encodeURIComponent(`SEARCH("${e}", ARRAYJOIN(${field})) > 0`))]);
      } else {
        query.push([...filters[filterName].map((e) => encodeURIComponent(`FIND("${e}", {${field}}) > 0`))]);
      }
    }
  },
  async getTheData(finalEncodedURI) {
    return await airtableClient.get(finalEncodedURI);
  },
  getRecordAsync(table, recordId) {
    return new Promise((resolve, reject) => {
      base(table).find(recordId, (err, record) => {
        if (err) {
          console.log(`%c ERROR: ${err}`, "font-size: 13px; background-color: red; color: white; padding: 3px 5px;");
          reject(err);
        } else {
          resolve(record);
        }
      });
    });
  },
  createRecordAsync(table, payload) {
    return new Promise((resolve, reject) => {
      base(`${table}`).create(payload, (err, success) => {
        if (err) {
          console.log(err);
          reject(err);
        } else {
          resolve(success);
        }
      });
    });
  },
  updateRecordsAsync(table, payload) {
    return new Promise((resolve, reject) => {
      base(`${table}`).update(payload, (err, success) => {
        if (err) {
          console.log(err);
          reject(err);
        } else {
          resolve(success);
        }
      });
    });
  },
  getTableAsync(table, maxRecords, view, filterByFormula = "", permittedFields = []) {
    store.commit("setNetworkBusyStatus", true);
    let maxRec = parseInt(maxRecords, 10);
    return new Promise((resolve, reject) => {
      let recordsArr = [];
      base(`${table}`)
        .select({
          maxRecords: maxRec,
          view: view,
          filterByFormula: filterByFormula,
          fields: permittedFields,
        })
        .eachPage(
          function page(records, fetchNextPage) {
            records.forEach((record) => {
              recordsArr.push(record);
            });
            fetchNextPage();
          },
          function done(err) {
            store.commit("setNetworkBusyStatus", false);
            if (err) {
              console.log("error", err);
              reject(err);
            } else {
              resolve(recordsArr);
            }
          }
        );
    });
  },
  likeQuery(records, favorites) {
    return airtableClient.patch(`Art%20Inventory/${records.id}/`, {
      fields: {
        Favorites: favorites.toString(),
      },
    });
  },
};
