<template>
  <div class="li-inner">
    <a
      :class="{
        'is-selected': filters.locations.includes(location.id),
      }"
      class="c-link c-link--filter"
      @click.stop="$emit('set-current-location', location.id)"
    >
      <span class="label">
        {{ location.fields.Location }}
      </span>
      <span v-if="curUserPerms.canViewCollectionCount" class="collection-count"
        >({{ location.fields.CollectionCount }})</span
      >
    </a>
    <div
      v-if="curSublocations && curSublocations.length"
      class="expand"
      :class="{
        'is-closed': isSubLocationsClosed,
      }"
      @click="setActive"
    />
    <ul v-if="curSublocations.length && !isSubLocationsClosed" class="c-filter__list sub-list">
      <li class="c-filter__list__items">
        <button
          v-if="curSublocations.length > 1"
          class="btn btn-toggle"
          @click="$emit('toggle-current-sublocs', location.id)"
        >
          Select / Deselect All
        </button>
      </li>
      <li
        v-for="(subLoc, subIndex) in curSublocations"
        :key="subIndex"
        class="c-filter__list__items"
        @click.stop="$emit('set-current-sublocation', subLoc, location.id)"
      >
        <div class="li-inner">
          <span class="line"></span>
          <a
            :class="{
              'is-selected': filters.sublocations.includes(subLoc.id),
            }"
            class="c-link c-link--filter"
          >
            <span class="label">
              {{ subLoc.fields.SubLocationName }}
            </span>
            <span class="collection-count">({{ subLoc.fields.CollectionCount }})</span>
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "LocationsLi",
  props: {
    location: {
      type: Object,
      required: true,
      default: () => {},
    },
    ind: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      isSubLocationsClosed: true,
      curSublocations: [],
    };
  },
  computed: {
    ...mapState(["filters", "locations", "sublocations", "curUserPerms"]),
  },
  created() {
    this.curSublocations = this.getSubLocations(this.location.fields.Location);
  },
  methods: {
    setActive() {
      this.isSubLocationsClosed = !this.isSubLocationsClosed;
      this.$emit("add-active-class", this.ind, this.isSubLocationsClosed);
    },
    getSubLocations(curLoc) {
      return this.sublocations.filter((sub) => sub.fields.ParentLocation === curLoc);
    },
  },
};
</script>
