<template>
  <div>
    <div class="c-works__image" @click="navigateToDetails">
      <div v-if="item.fields.Image">
        <span v-if="typeof item.fields.Image === 'object'">
          <img
            v-if="item.fields.Image[item.fields.Image.length - 1].thumbnails"
            :src="item.fields.Image[item.fields.Image.length - 1].thumbnails.large.url"
            alt="Thumnail Image"
          />
        </span>
        <span v-if="typeof item.fields.Image === 'string'">
          <img
            v-if="item.fields.Image.thumbnails"
            :src="item.fields.Image.thumbnails.large.url"
            alt="Thumnail Image"
            class="c-works__photo"
          />
        </span>
      </div>
      <div v-else class="progress-wrap">
        <Progress fill-color="#000000" />
      </div>
    </div>
    <div class="layout-item-details">
      <div class="c-works__details__wrap">
        <div v-if="item.fields.TitleClean" class="c-works__detail__info">
          <div class="c-link" @click="navigateToDetails">
            <h3 class="c-works__title">
              {{ item.fields.TitleClean | sanitize }}
            </h3>
          </div>
        </div>
        <meta-bar :item="item" />
      </div>
    </div>
  </div>
</template>
<script>
import Progress from "@/components/ui/Progress";
import router from "@/router";
import MetaBar from "@/components/ui/layouts/MetaBar";

export default {
  name: "SlickItem",
  components: {
    MetaBar,
    Progress,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    navigateToDetails() {
      this.$store.commit("setCurrentRecordId", this.item.id);
      this.$store.commit("setFilteredItemIds", []);
      router.push({
        path: `/item/${this.item.id}`,
        query: { hasNav: false },
      });
    },
  },
};
</script>
