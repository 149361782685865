var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"li-inner"},[_c('a',{staticClass:"c-link c-link--filter",class:{
      'is-selected': _vm.filters.locations.includes(_vm.location.id),
    },on:{"click":function($event){$event.stopPropagation();return _vm.$emit('set-current-location', _vm.location.id)}}},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.location.fields.Location)+" ")]),(_vm.curUserPerms.canViewCollectionCount)?_c('span',{staticClass:"collection-count"},[_vm._v("("+_vm._s(_vm.location.fields.CollectionCount)+")")]):_vm._e()]),(_vm.curSublocations && _vm.curSublocations.length)?_c('div',{staticClass:"expand",class:{
      'is-closed': _vm.isSubLocationsClosed,
    },on:{"click":_vm.setActive}}):_vm._e(),(_vm.curSublocations.length && !_vm.isSubLocationsClosed)?_c('ul',{staticClass:"c-filter__list sub-list"},[_c('li',{staticClass:"c-filter__list__items"},[(_vm.curSublocations.length > 1)?_c('button',{staticClass:"btn btn-toggle",on:{"click":function($event){return _vm.$emit('toggle-current-sublocs', _vm.location.id)}}},[_vm._v(" Select / Deselect All ")]):_vm._e()]),_vm._l((_vm.curSublocations),function(subLoc,subIndex){return _c('li',{key:subIndex,staticClass:"c-filter__list__items",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('set-current-sublocation', subLoc, _vm.location.id)}}},[_c('div',{staticClass:"li-inner"},[_c('span',{staticClass:"line"}),_c('a',{staticClass:"c-link c-link--filter",class:{
            'is-selected': _vm.filters.sublocations.includes(subLoc.id),
          }},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(subLoc.fields.SubLocationName)+" ")]),_c('span',{staticClass:"collection-count"},[_vm._v("("+_vm._s(subLoc.fields.CollectionCount)+")")])])])])})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }