<template>
  <main id="merchantsPage" aria-label="content">
    <div class="o-section__wrapper">
      <header class="page-header">
        <h2 class="c-section__title">Merchants</h2>
      </header>
      <merchants v-if="merchants.length" />
      <div v-else class="spinner-wrap">
        <Spinner />
      </div>
    </div>
  </main>
</template>
<script>
import Merchants from "@/components/pages/Merchants";

import Spinner from "@/components/ui/Spinner";
import { mapState } from "vuex";

export default {
  name: "ListMerchants",
  components: {
    Spinner,
    Merchants,
  },
  computed: mapState(["networkBusyStatus", "merchants"]),
  async created() {
    await this.$store.dispatch("getMerchants");
  },
};
</script>
