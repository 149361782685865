<template>
  <div class="unit-radios">
    <div class="unit-wrap">
      <input
        :id="`in_${dim}`"
        v-model="filters.units"
        class="cycad-radio"
        type="radio"
        :name="`units_${dim}`"
        :value="'IN'"
        :checked="filters.units === 'IN'"
        @click="handleChange('IN')"
      />
      <label :for="`in_${dim}`">IN</label>
    </div>
    <div class="unit-wrap">
      <input
        :id="`cm_${dim}`"
        v-model="filters.units"
        class="cycad-radio"
        type="radio"
        :name="`units_${dim}`"
        :value="'CM'"
        :checked="filters.units === 'CM'"
        @click="handleChange('CM')"
      />
      <label :for="`cm_${dim}`">CM</label>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "RadioUnits",
  props: {
    dim: {
      type: String,
      require: true,
      default: "",
    },
  },
  computed: mapState(["filters"]),
  methods: {
    handleChange(u) {
      let max;
      this.$store.commit("setFilterUnits", u);
      max = u === "IN" ? 500 : 1250;
      this.$store.commit("setFilterLengthRange", [0, max]);
      this.$store.commit("setFilterWidthRange", [0, max]);
    },
  },
};
</script>
