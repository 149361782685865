<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        ref="page"
        class="c-modal save-pdf__modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <div class="save-pdf__page">
          <header ref="header" id="modalTitle" class="savd-pdf__header">
            <div class="row">
              <div class="header-fields">
                <h6>Heading Display</h6>
                <div class="checkbox-wrap">
                  <div class="form-group">
                    <input id="showCollateralized" v-model="showCollateralized" class="squarecheck" type="checkbox" />
                    <label for="showCollateralized">Collateralized</label>
                  </div>
                </div>
                <div class="checkbox-wrap">
                  <div class="form-group">
                    <input
                      id="showUncollateralized"
                      v-model="showUncollateralized"
                      class="squarecheck"
                      type="checkbox"
                    />
                    <label for="showUncollateralized">Uncollateralized</label>
                  </div>
                </div>
                <div class="checkbox-wrap">
                  <div class="form-group">
                    <input id="showTotal" v-model="showTotal" class="squarecheck" type="checkbox" />
                    <label for="showTotal">Total</label>
                  </div>
                </div>
              </div>
              <div class="item-fields">
                <h6>Item Fields</h6>
                <div class="checkbox-wrap">
                  <div class="form-group">
                    <input id="showInventory" v-model="isInventory" class="squarecheck" type="checkbox" />
                    <label for="showInventory">Inventory</label>
                  </div>
                </div>
                <div class="checkbox-wrap">
                  <div class="form-group">
                    <input id="showValue" v-model="isValue" class="squarecheck" type="checkbox" />
                    <label for="showValue">Value</label>
                  </div>
                </div>
                <div class="checkbox-wrap">
                  <div class="form-group">
                    <input id="showLocation" v-model="isLocation" class="squarecheck" type="checkbox" />
                    <label for="showLocation">Location</label>
                  </div>
                </div>
                <div class="checkbox-wrap">
                  <div class="form-group">
                    <input id="showDimensions" v-model="isDimensions" class="squarecheck" type="checkbox" />
                    <label for="showDimensions">Dimensions</label>
                  </div>
                </div>
                <div class="checkbox-wrap">
                  <div class="form-group">
                    <input id="showQRCode" v-model="isQRCode" class="squarecheck" type="checkbox" />
                    <label for="showQRCode">QR Code</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="btn-row">
              <div class="form-group title">
                <h6>PDF Title:</h6>
                <input
                  id="pdfTitleInput"
                  v-model="pdfTitle"
                  class="c-form__input ml-3"
                  type="text"
                  placeholder="Enter PDF title"
                />
              </div>
              <button class="c-btn btn-save-pdf" @click="createPdf">
                Save
                <Progress v-if="isBuildingPdf" fill-color="#FFFFFF" width="20" class-name="ml-2" />
              </button>
              <button
                type="button"
                class="c-btn c-btn--close btn-back"
                aria-label="Close modal"
                @click="$emit('close-save-pdf-modal')"
              >
                <svg width="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17 1L1 17M1 1l16 16"
                    stroke="#73706E"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </header>
          <Spinner v-if="isLoading" />
          <table v-if="pdfItems.length && !isLoading" id="pdfTable" class="save-pdf__table">
            <thead>
              <tr>
                <th colspan="3" class="header-th">
                  <table class="pdf-header">
                    <tr class="pdf-title">
                      <td colspan="3">{{ pdfTitle }}</td>
                    </tr>
                    <tr>
                      <td class="collateralization">
                        <table>
                          <tr>
                            <td>
                              <tr v-if="showCollateralized" class="collateralized">
                                <td>Collateralized:</td>
                                <td>{{ collateralized.length }} {{ itemWord(collateralized.length) }} valued at:</td>
                                <td>{{ collateralizedValue }}</td>
                              </tr>
                              <tr v-if="showUncollateralized" class="uncollateralized">
                                <td>Uncollateralized:</td>
                                <td>
                                  {{ uncollateralized.length }} {{ itemWord(uncollateralized.length) }} valued at:
                                </td>
                                <td>{{ uncollateralizedValue }}</td>
                              </tr>
                              <tr v-if="showTotal" class="total">
                                <td>Total:</td>
                                <td>{{ pdfItems.length }} {{ itemWord(pdfItems.length) }} valued at:</td>
                                <td>{{ totalValue }}</td>
                              </tr>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </th>
              </tr>
            </thead>

            <tbody>
              <save-pdf-item
                v-for="(item, index) in pdfItems"
                :key="index"
                :item="item"
                :is-inventory="isInventory"
                :is-value="isValue"
                :is-location="isLocation"
                :is-dimensions="isDimensions"
                :is-q-r-code="isQRCode"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import SavePdfItem from "./SavePdfItem";
import Progress from "@/components/ui/Progress";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Spinner from "@/components/ui/Spinner";
import { debounce } from "lodash";
import dom from "../../services/dom.service";
let path = require("path");

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: "SavePdfModal",
  components: {
    SavePdfItem,
    Spinner,
    Progress,
  },
  props: {
    pdfItems: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      isBuildingPdf: false,
      isDimensions: true,
      isInventory: true,
      isLoading: false,
      isLocation: true,
      isQRCode: true,
      isValue: true,
      pdfTitle: "Enter PDF Title",
      showCollateralized: true,
      showTotal: true,
      showUncollateralized: true,
      sortedItems: [],
    };
  },
  computed: {
    ...mapState(["networkBusyStatus", "curUserData"]),
    totalValue() {
      return this.formatNumber(
        this.pdfItems.reduce((total, item) => {
          const value = item.fields.Value;
          return value > 0 ? total + value : total;
        }, 0)
      );
    },
    collateralized() {
      return this.pdfItems.filter((item) => item.fields["GRAPH - COLLATERAL / FREE"] === "COLLATERAL");
    },
    uncollateralized() {
      return this.pdfItems.filter(
        (item) =>
          item.fields["GRAPH - COLLATERAL / FREE"] === "FREE ASSET" ||
          item.fields["GRAPH - COLLATERAL / FREE"] === undefined
      );
    },
    collateralizedValue() {
      return this.formatNumber(
        this.collateralized.reduce((total, item) => {
          const value = item.fields.Value;
          return value > 0 ? total + value : total;
        }, 0)
      );
    },
    uncollateralizedValue() {
      return this.formatNumber(
        this.uncollateralized.reduce((total, item) => {
          const value = item.fields.Value;
          return value > 0 ? total + value : total;
        }, 0)
      );
    },
  },
  created() {
    this.sortedItems = [...this.pdfItems];
  },
  mounted() {
    console.log(this.curUserData);
    dom.addHtmlClass("save-pdf");
    window.addEventListener("resize", this.debouncedUpdateHeaderHeight);
    this.$nextTick(() => {
      this.updateHeaderHeight();
    });
  },
  beforeDestroy() {
    dom.removeHtmlClass("save-pdf");
    window.removeEventListener("resize", this.debouncedUpdateHeaderHeight);
  },
  methods: {
    updateHeaderHeight() {
      const header = this.$refs.header;
      const page = this.$refs.page;
      if (header && page) {
        const headerHeight = header.offsetHeight + 70;
        page.style.top = `${headerHeight}px`;
      }
    },
    debouncedUpdateHeaderHeight: debounce(function () {
      this.updateHeaderHeight();
    }, 20),
    formatNumber(value) {
      let fixedDecimal = value.toFixed(2);
      return `$${Number(fixedDecimal).toLocaleString("en-US", { minimumFractionDigits: 2 })}`;
    },
    itemWord(itemCount) {
      return itemCount === 1 ? "item" : "items";
    },
    getImageUrl(item) {
      let imageUrl;
      if (typeof item.fields.Image === "object") {
        imageUrl = item.fields.Image[item.fields.Image.length - 1].thumbnails.large.url;
      } else if (typeof item.fields.Image === "string") {
        imageUrl = item.fields.Image.thumbnails.large.url;
      }
      return imageUrl;
    },
    async getBase64FromUrl(url) {
      const data = await fetch(url);
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
    },
    createHeaderRows() {
      let headers = [
        [{ text: this.pdfTitle, style: "tableTitle", colSpan: 3, alignment: "center", margin: [0, 5, 0, 5] }, {}, {}],
        [{ text: "", colSpan: 3, style: "headerPadding", border: [true, false, true, false] }, {}, {}],
      ];
      if (this.showCollateralized) {
        headers.push([
          {
            text: "Collateralized:",
            style: "tableHeader",
            colSpan: 1,
            alignment: "right",
            border: [true, false, false, false],
            margin: [0, 0, 0, 1],
          },
          {
            text: `${this.collateralized.length} ${this.itemWord(this.collateralized.length)} valued at:`,
            style: "tableHeader",
            colSpan: 1,
            alignment: "left",
            border: [false, false, false, false],
            margin: [0, 0, 0, 1],
          },
          {
            text: this.collateralizedValue,
            style: "tableHeader",
            colSpan: 1,
            alignment: "left",
            border: [false, false, true, false],
            margin: [0, 0, 0, 1],
          },
        ]);
      }

      if (this.showUncollateralized) {
        headers.push([
          {
            text: "Uncollateralized:",
            style: "tableHeader",
            colSpan: 1,
            alignment: "right",
            border: [true, false, false, false],
            margin: [0, 0, 0, 1],
          },
          {
            text: `${this.uncollateralized.length} ${this.itemWord(this.uncollateralized.length)} valued at:`,
            style: "tableHeader",
            colSpan: 1,
            alignment: "left",
            border: [false, false, false, false],
            margin: [0, 0, 0, 1],
          },
          {
            text: this.uncollateralizedValue,
            style: "tableHeader",
            colSpan: 1,
            alignment: "left",
            border: [false, false, true, false],
            margin: [0, 0, 0, 1],
          },
        ]);
      }
      if (this.showTotal) {
        headers.push([
          {
            text: "Total:",
            style: "tableHeader",
            colSpan: 1,
            alignment: "right",
            border: [true, false, false, false],
            margin: [0, 0, 0, 1],
          },
          {
            text: `${this.pdfItems.length} ${this.itemWord(this.pdfItems.length)} valued at:`,
            style: "tableHeader",
            colSpan: 1,
            alignment: "left",
            border: [false, false, false, false],
            margin: [0, 0, 0, 1],
          },
          {
            text: this.totalValue,
            style: "tableHeader",
            colSpan: 1,
            alignment: "left",
            border: [false, false, true, false],
            margin: [0, 0, 0, 1],
          },
        ]);
      }
      headers.push([{ text: "", colSpan: 3, style: "headerPadding", border: [true, false, true, false] }, {}, {}]);
      return headers;
    },

    async createPdf() {
      this.isLoading = true;
      let tableBody = this.createHeaderRows.call(this);
      for (const item of this.pdfItems) {
        let metaTd = [];
        let descriptionTd = [];
        let imageTd = {};
        let base64Item = null;
        let base64ItemQR = null;
        let imageUrl = this.getImageUrl(item);
        let qrCodeUrl =
          item.fields &&
          item.fields["QR Code"] &&
          item.fields["QR Code"][0] &&
          item.fields["QR Code"][0].thumbnails &&
          item.fields["QR Code"][0].thumbnails.large &&
          item.fields["QR Code"][0].thumbnails.large.url;

        if (imageUrl === undefined) {
          imageUrl = path.resolve(__dirname, "assets/images", "no-image.jpg");
        }
        if (qrCodeUrl === undefined) {
          qrCodeUrl = path.resolve(__dirname, "assets/images", "no-qr.jpg");
        }

        base64Item = await this.getBase64FromUrl(imageUrl);
        base64ItemQR = await this.getBase64FromUrl(qrCodeUrl);
        let artistName = item.fields.Artist || null;
        let inventoryNo = item.fields["Inventory No."] ? item.fields["Inventory No."] : "";
        let currentValue = Vue.filter("currency")(item.fields.Value);
        let location = item.fields["Current Location"] ? item.fields["Current Location"] : "";
        let subLocation = item.fields.SubLocation ? `(${item.fields.SubLocation})` : "";
        let dimensions = item.fields.Dimensions ? item.fields.Dimensions : "";
        let rawTitle = Vue.filter("sanitize")(item.fields.TitleClean);
        let title = Vue.filter("titlecase")(rawTitle);
        let titlePreview = title.substring(0, 200) + "...";
        let description = Vue.filter("sanitize")(item.fields.DescriptionClean);
        let descriptionPreview = description.substring(0, 500) + "...";

        if (this.isInventory && inventoryNo) {
          metaTd.push({
            table: {
              widths: [72, "auto"],
              body: [
                [
                  [
                    {
                      text: "InventorNo:",
                      bold: true,
                      margin: [0, 0, 0, 1],
                    },
                    {
                      text: `${inventoryNo}`,
                      bold: false,
                      margin: [0, 0, 0, 3],
                    },
                  ],
                  {
                    image: base64ItemQR,
                    fit: [20, 20],
                    opacity: 1.0,
                    margin: [0, 0, 0, 3],
                  },
                ],
              ],
            },
            layout: "noBorders",
          });
        }

        if (this.isValue && currentValue) {
          metaTd.push([
            {
              text: "Value:",
              bold: true,
              margin: [0, 0, 0, 1],
            },
            {
              text: `${currentValue}`,
              bold: false,
              margin: [0, 0, 0, 3],
            },
          ]);
        }

        if (this.isLocation && location) {
          metaTd.push([
            {
              text: "Location:",
              bold: true,
              margin: [0, 0, 0, 1],
            },
            {
              text: `${location}${subLocation}`,
              bold: false,
              margin: [0, 0, 0, 3],
            },
          ]);
        }

        if (this.isDimensions && dimensions) {
          metaTd.push([
            {
              text: "Dimensions:",
              bold: true,
              margin: [0, 0, 0, 1],
            },
            {
              text: `${dimensions}`,
              bold: false,
              margin: [0, 0, 0, 3],
            },
          ]);
        }

        if (artistName) {
          descriptionTd.push({
            text: `${artistName}`,
            bold: false,
            fontSize: 7,
            margin: [0, 0, 0, 3],
          });
        }
        if (titlePreview) {
          descriptionTd.push({
            text: `${titlePreview}`,
            bold: true,
            fontSize: 8,
            margin: [0, 0, 0, 3],
          });
        }
        if (descriptionPreview) {
          descriptionTd.push({
            text: `${descriptionPreview}`,
            bold: false,
            color: "black",
            margin: [0, 0, 0, 3],
          });
        }

        if (base64Item) {
          imageTd = {
            image: base64Item,
            fit: [100, 100],
            opacity: 1.0,
            margin: [0, 2, 0, 0],
          };
        }

        let dataRow = [metaTd, descriptionTd, imageTd];
        tableBody.push(dataRow);
      }
      let docDefinition = {
        content: [
          {
            style: "cycadItems",
            table: {
              dontBreakRows: true,
              widths: [100, "*", 100],
              body: tableBody,
            },
            layout: {
              hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 0.1 : 0.1;
              },
              vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 0.1 : 0.1;
              },
              hLineColor: function (i, node) {
                return i === 0 || i === node.table.body.length ? "#BBBBBB" : "#BBBBBB";
              },
              vLineColor: function (i, node) {
                return i === 0 || i === node.table.widths.length ? "#BBBBBB" : "#BBBBBB";
              },
            },
          },
        ],
        styles: {
          cycadItems: {
            fontSize: 6,
          },
          tableTitle: {
            bold: true,
            border: [false, false, false, false],
            color: "black",
            fontSize: 12,
          },
          tableHeader: {
            bold: false,
            border: [false, false, false, false],
            color: "black",
            fontSize: 9,
          },
          headerPadding: {
            border: [false, false, false, false],
            fillColor: "white",
            margin: [0, 1, 0, 1],
          },
          noBorders: {
            border: [false, false, false, false],
          },
        },

        defaultStyle: {
          // alignment: 'justify'
        },
      };
      setTimeout(() => {
        try {
          pdfMake.createPdf(docDefinition).open();
        } catch (err) {
          alert(err);
        }
        this.isLoading = false;
      }, 300);
    },
  },
};
</script>
