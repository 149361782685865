<template>
  <div class="add-item">
    <main id="addItemPage" aria-label="content">
      <section class="content-block">
        <h2 class="c-section__title">Airtable Utilities</h2>
        <button type="button" class="c-btn util" @click="getTable('Merchants')">Convert Image to Base 64</button>
      </section>
      <div style="word-break: break-all">{{ dataURL }}</div>
    </main>
  </div>
</template>
<script>
import { mapState } from "vuex";
import airtableQuery from "../services/airtableQuery.service";

export default {
  name: "AirtableUtilities",
  data() {
    return {
      recordsArray: [],
      table: "Merchants",
      dataURL: "",
    };
  },
  computed: mapState(["networkBusyStatus"]),
  created() {},
  mounted() {},
  updated() {},
  methods: {
    async getBase64FromUrl(url) {
      const data = await fetch(url);
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
    },
    async getTable(table) {
      await airtableQuery.getTableAsync(table, 1000, "Grid view").then((records) => {
        const forLoop = async () => {
          for (let index = 0; index < records.length; index += 1) {
            let url =
              records[index].fields &&
              records[index].fields.Logo &&
              records[index].fields.Logo[0] &&
              records[index].fields.Logo[0].thumbnails &&
              records[index].fields.Logo[0].thumbnails.large &&
              records[index].fields.Logo[0].thumbnails.large.url;
            if (url) {
              let base64Image = await this.getBase64FromUrl(url);
              this.recordsArray.push({
                id: records[index].id,
                fields: { LogoBase64: `${base64Image}` },
              });
            } else {
              continue;
            }
            if (index === records.length - 1) {
              this.updateRecords();
            }
          }
        };
        forLoop();
      });
    },
    async updateRecords() {
      let records = JSON.parse(JSON.stringify(this.recordsArray));
      let i = 0;
      while (i < records.length) {
        await airtableQuery.updateRecordsAsync(this.table, records.slice(0, 10));
        records = records.slice(10);
      }
    },
  },
};
</script>
