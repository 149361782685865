<template>
  <div class="compare-item">
    <div class="compare-item-image">
      <div v-if="item.fields.Image">
        <span v-if="typeof item.fields.Image === 'object'">
          <img
            v-if="item.fields.Image[item.fields.Image.length - 1].thumbnails"
            :src="item.fields.Image[item.fields.Image.length - 1].thumbnails.large.url"
            alt="Thumnail Image"
          />
        </span>
        <span v-if="typeof item.fields.Image === 'string'">
          <img
            v-if="item.fields.Image.thumbnails"
            :src="item.fields.Image.thumbnails.large.url"
            alt="Thumnail Image"
            class="c-works__photo"
          />
        </span>
      </div>
      <div v-else class="compare-item-image">
        <img alt="No Image" src="/assets/images/no-image.jpg" />
      </div>
    </div>
    <div class="compare-item-details">
      <div v-for="(value, key, index) in detailFields" :key="index" class="detail-item">
        <div v-if="key === 'Send This To'">
          <h6>{{ key }}</h6>
          <ul class="sublist">
            <li v-for="(transfer, idx) in curPrevTransfers" :key="idx">
              <div>TransferId: {{ transfer.fields.TransferId }}</div>
              <div>Location: {{ transfer.fields.LocationName }}</div>
            </li>
          </ul>
        </div>
        <div v-else-if="key === 'QR Code'" class="qr-code">
          <h6>{{ key }}</h6>
          <img :src="value[0].url" alt="QR Code" class="mt-1" />
        </div>
        <div v-else-if="key === 'Invoices'" class="qr-code">
          <h6>{{ key }}</h6>
          <div v-for="(pdf, idx) in value" :key="idx">
            <a class="c-btn" :href="pdf.url" target="_blank">
              <span>PDF</span>
            </a>
          </div>
        </div>
        <div v-else-if="key === 'SubLocations'">
          <h6>{{ key }}</h6>
          <ul class="sublist">
            <li v-for="(sub, idx) in curSubLocations" :key="idx">
              {{ sub.fields.SubLocationName }}
            </li>
          </ul>
        </div>
        <div v-else>
          <h6>{{ key }}</h6>
          <p>{{ value }}</p>
        </div>
      </div>
    </div>
    <footer>
      <button class="c-btn remove" @click="handleRemoveFromCompareList">REMOVE</button>
    </footer>
  </div>
</template>
<script>
import { mapState } from "vuex";
import airtableQuery from "@/services/airtableQuery.service";

export default {
  name: "CompareItem",
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      detailFields: {},
      compareFields: [
        "Artist",
        "AskingPrice",
        "Category",
        "Certificate",
        "Current Location",
        "Delivery Location",
        "DescriptionClean",
        "Dimensions",
        "ForSale",
        "Image",
        "Inventory No.",
        "Invoices",
        "Item ID",
        "LocationName",
        "Lot No.",
        "Media",
        "QR Code",
        "QualityName",
        "Sale Date",
        "Sale",
        "Send This To",
        "SubLocationName",
        "SubLocations",
        "TitleClean",
        "Value",
      ],
      previousTransfers: [],
      curPrevTransferIds: [],
      curPrevTransfers: [],
      curSubLocationIds: [],
      curSubLocations: [],
      sublocations: [],
    };
  },
  watch: {
    compareList: {
      handler(newVal) {
        if (newVal) {
          this.handleCheckIfCompare();
        }
      },
      deep: true,
    },
    previousTransfers: {
      handler(newVal) {
        if (newVal) {
          newVal.forEach((obj) => {
            if (this.curPrevTransferIds.includes(obj.id)) {
              this.curPrevTransfers.push(obj);
            }
          });
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(["authData", "userRoles", "curUserPerms", "compareList", "curUserData"]),
  },
  created() {
    this.detailFields = { ...this.item.fields };
    Object.keys(this.detailFields).forEach((key) => {
      if (!this.compareFields.includes(key)) {
        delete this.detailFields[key];
      }
      if (key === "Send This To") {
        this.curPrevTransferIds = [...this.detailFields[key]];
      }
      if (key === "SubLocations") {
        this.curSubLocationIds = [...this.detailFields[key]];
      }
    });
    this.getPreviousTransfers();
    this.getCurSublocations();
    delete this.detailFields["Image"];
    delete this.detailFields["Category"];
    delete this.detailFields["ImagesOptimized"];
    delete this.detailFields["Related Documents LU"];
    this.detailFields = Object.keys(this.detailFields)
      .sort()
      .reduce((obj, key) => {
        obj[key] = this.detailFields[key];
        return obj;
      }, {});
  },

  methods: {
    async getPreviousTransfers() {
      console.log("getting prev");
      let filterByForumla = `AND({User}="${this.curUserData.fields.FullName}")`;
      await airtableQuery.getTableAsync("Transfers", 5000, "administrator", filterByForumla, []).then((data) => {
        this.previousTransfers = data.sort((a, b) => {
          return a.fields.Created > b.fields.Created ? -1 : 1;
        });
      });
      this.previousTransfers.forEach((obj) => {
        if (this.curPrevTransferIds.includes(obj.id)) {
          console.log();
          this.curPrevTransfers.push(obj);
        }
      });
    },
    async getCurSublocations() {
      console.log("getting subs");
      await airtableQuery.getTableAsync("SubLocations", 5000, "administrator", "", []).then((data) => {
        this.sublocations = data.sort((a, b) => {
          return a.fields.Created > b.fields.Created ? -1 : 1;
        });
        console.log();
      });
      this.sublocations.forEach((obj) => {
        if (this.curSubLocationIds.includes(obj.id)) {
          this.curSubLocations.push(obj);
        }
      });
    },
    handleRemoveFromCompareList() {
      let newCompareList = this.compareList.filter((obj) => obj.id !== this.item.id);
      this.$store.commit("setCompareList", newCompareList);
    },
  },
};
</script>
