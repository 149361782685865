import Airtable from "airtable";
import environment from "@/environments/environment";

export const baseFilters = {
  categories: [],
  collections: [],
  collateralized: false,
  dateEnd: "",
  dateStart: "",
  excludeItemsWithNoValue: false,
  favorites: "",
  forSale: false,
  freeAsset: false,
  hasNoValue: false,
  hasPriceRange: false,
  itemIds: "",
  keyword: "",
  locations: [],
  maxLength: 500,
  maxPrice: 5000000,
  maxWidth: 500,
  merchants: [],
  minLength: 0,
  minPrice: 0,
  minWidth: 0,
  quality: [],
  sublocations: [],
  units: "IN",
};

export const baseFiltersCM = {
  categories: [],
  collateralized: false,
  collections: [],
  dateEnd: "",
  dateStart: "",
  excludeItemsWithNoValue: false,
  favorites: "",
  forSale: false,
  freeAsset: false,
  hasNoValue: false,
  hasPriceRange: false,
  itemIds: "",
  keyword: "",
  locations: [],
  maxLength: 1250,
  maxPrice: 5000000,
  maxWidth: 1250,
  merchants: [],
  minLength: 0,
  minPrice: 0,
  minWidth: 0,
  quality: [],
  sublocations: [],
  units: "CM",
};

export const emptyUser = {
  token: "",
  user_display_name: "",
  user_email: "",
  user_nicename: "",
  user_role: "",
};

export const baseRouteQuery = `{"categories":"","collections":"","dateEnd":"","dateStart":"","favorites":"","itemIds":"","keyword":"","layout":"masonry-small","locations":"","lengthMin":"0","lengthMax":"500","priceMax":"5000000","merchants":"","priceMin":"0","quality":"","sublocations":""}`;

export const baseQueryUrl =
  "http://localhost:8080/art-inventory?categories=&collections=&dateEnd=&dateStart=&excludeItemsWithNoValue=false&favorites=&forSale=false&hasNoValue=false&hasPriceRange=false&itemIds=&keyword=&locations=&maxLength=500&maxPrice=5000000&maxWidth=500&merchants=&minLength=0&minPrice=0&minWidth=0&quality=&sublocations=&units=IN&reload=true";

export const base = new Airtable({ apiKey: environment.API_KEY }).base(environment.AIR_TABLE_BASE_ID);

export const agileOptions = {
  imageOptions: {
    dots: false,
    fade: false,
    navButtons: true,
    infinite: true,
  },
  thumbOptions: {
    autoplay: false,
    dots: false,
    navButtons: true,
    slidesToShow: 4,
    infinite: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 5,
          navButtons: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          navButtons: true,
          slidesToShow: 7,
        },
      },
    ],
  },
};

export const agileOptions2 = {
  dots: false,
  infinite: true,
  speed: 200,
  slidesToShow: 6,
  lazyLoad: "ondemand",
  slidesToScroll: 6,
  progressive: "progressive",
  responsive: [
    {
      breakpoint: 1999,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
      },
    },
    {
      breakpoint: 1799,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 1599,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 1399,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 599,
      settings: {
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
export const baseUserPerms = {
  canBuy: false,
  canSetAskingPrice: false,
  canSetQuality: false,
  canTransfer: false,
  canViewCollectionCount: false,
  permittedCategories: [],
  permittedCollections: [],
  permittedFilters: [],
  permittedLocationFields: [],
  permittedLocations: [],
  permittedMerchants: [],
  permittedPages: [],
  permittedSorting: [],
};

export const layouts = ["masonry-small", "masonry-large", "list", "grid"];

export const initialState = {
  airTableResTime: "",
  authData: Object.assign(emptyUser),
  categories: [],
  collections: [],
  compareList: [],
  curItemDetailRecord: {},
  curRoomName: "",
  curScanRoom: {},
  curScanRoomItems: [],
  curScanRoomScannedItemIds: [],
  curScanRoomUnscannedItemIds: [],
  curUserData: {},
  curUserPerms: Object.assign(baseUserPerms),
  currentLayout: "",
  currentQueryURL: "",
  currentRecordId: "",
  currentRecordIndex: "",
  fetchingData: false,
  filterByFormula: "",
  filterByFormulaIdQuery: "",
  filteredItemIds: [],
  filters: Object.assign(baseFilters),
  hasAskingPrice: false,
  initialPage: 1,
  items: [],
  layouts: layouts,
  loadingFavorites: "",
  locations: [],
  loggingIn: false,
  loginError: null,
  merchants: [],
  networkBusyStatus: false,
  offset: null,
  permittedArtInventoryFields: [],
  previousRoomRecordIds: [],
  previousRoomScans: [],
  qualities: [],
  scrollOffset: 0,
  selectedLocs: [],
  setPreviousScanRecordIds: [],
  shoppingCart: [],
  shoppingCartItemIds: [],
  shoppingCartTotal: 0,
  showItemNav: false,
  sortByArr: [],
  sortDirection: "desc",
  sublocations: [],
  totalResults: 0,
  transferCart: [],
  transferCartItemIds: [],
  transferOrder: [],
  userFavoriteItemIds: [],
  userFavorites: [],
  userRoles: [],
};

export const allFields = [
  "(Associated Sale Transaction No.)",
  "* PENDING DELETION TRANSACTION NO.",
  "#",
  "% of Value",
  "10/24/23 SCHEDULE",
  "10/30/23 SCH I UPDATE",
  "100522",
  "2024.01.08 TSNY TO KUWAIT",
  "2024.01.22 VALERIE LIST",
  "4TH ADVANCE",
  "6/11/23 CONSIGNMENT NO.",
  "6/11/23 GERMAN",
  "6/11/23 LOCATION",
  "6/11/23 ORDER",
  "Additional Location Comments",
  "Additional Pricing Info",
  "Admin Tag",
  "AEL COLLATERAL 2023.09.07 ORDER",
  "AEL COLLATERAL 2023.09.07",
  "AEL REVAL 2023.12.19 CHANGE",
  "AEL REVAL 2023.12.19 ORDER",
  "AEL REVAL 2023.12.19",
  "ALI LIST ORDER",
  "Art Intel Fee Calculation",
  "ART SOLD FROM TEST",
  "ARTISAN B / BYA",
  "Artist &amp; Title",
  "Artist",
  "Artists 2",
  "Artists",
  "AskingPrice",
  "AskingPriceSetByUser",
  "Autonumber",
  "AVAILABILITY WIP FROM SUBLOCATIONS",
  "CA - SELL KEEP",
  "CA",
  "Calculation",
  "Carpet Tag No.",
  "CATEGORIZATION",
  "Category Symbol",
  "CATEGORY: CONSOLIDATED",
  "Category",
  "Certificate",
  "CERTIFICATES / RESTORATIONS",
  "CHART - CATEGORY",
  "CHART - COLLATERAL",
  "CHART - NUMBER OF ITEMS BY LOCATION",
  "CHART: ITEM VALUE",
  "CHART: SALE PRICE (USD)",
  "CHART: VALUE TIER",
  "CHECKBOX",
  "Christies Diff H+10%",
  "Christies Diff vs. Low Est.",
  "Christies Hammer + 10%",
  "Christies Low Est.",
  "Collateral",
  "CollectionLOCATIONVALUE (from Location)",
  "CollectionNames",
  "CollectionNameTemp",
  "Collections copy",
  "Collections",
  "CollectionsFormula",
  "Commission",
  "CONSIGNMENT # LU copy",
  "CONSIGNMENT # LU",
  "CONT 2018-12 - DUPLICATE",
  "CONT 2018-12",
  "CONT 2021-06",
  "COST",
  "Crate",
  "Created by",
  "CreatedDate",
  "Current Location",
  "Current TransferId",
  "Date Added",
  "Date Modified",
  "dc dec numbers file",
  "DC For Sale AH",
  "dc jun numbers",
  "dc mar number file",
  "dc sept numbers file",
  "DELETION DATE MONTH",
  "DELETION DATE YRMN",
  "DELETION/RETURN DATE",
  "Delivery Location",
  "Description",
  "DescriptionClean",
  "DescriptionRaw",
  "difference",
  "Dimensions CM",
  "Dimensions",
  "Document Item Number (from CA)",
  "Done",
  "Field 300",
  "FINE ART COMMISSION",
  "ForSale",
  "ForSaleSetByUser",
  "GAIN / LOSS",
  "GALLERY - COLLATERAL / FREE",
  "GIVE KEEP",
  "GRAPH - COLLATERAL / FREE",
  "GRAPH - COLLATERAL LOCATIONS",
  "GRAPH - FREE ASSET LOCATIONS",
  "hammer",
  "Image Size",
  "Image",
  "ImagesOptimized",
  "Insurance Assumption",
  "Insurance Locations Plain",
  "Insurance Locations",
  "Insurance Policy",
  "Internal (Full) Description",
  "Internal Images",
  "Internal Title",
  "Internal Value",
  "Inventory No.",
  "InventoryApp",
  "Invoice + Lot",
  "Invoice No.",
  "Invoice Title",
  "Invoices",
  "Italy",
  "Item ID",
  "Item URL New",
  "Item URL Old",
  "ITEM VALUE",
  "ITEM",
  "ItemQ",
  "Kuwait Tag",
  "Label 2",
  "Label",
  "Latest Purchases",
  "Lebanon Tag",
  "Length CM",
  "Length FT IN",
  "Length IN",
  "Limited Description",
  "LOAN COL",
  "Loan",
  "Location (Destination)(from Locations Table)",
  "Location Different Format",
  "Location",
  "LocationName",
  "LocationRecordId",
  "LOG FROM 2022-03-04",
  "LOG TO 2022-03-23",
  "LOGX",
  "Lot No.",
  "Low Estimate / Value (from CA)",
  "Media",
  "Merchant Image",
  "Merchant",
  "MerchantName",
  "Merchants",
  "MERGE REF NO.",
  "ModelGLB",
  "new",
  "Non-English Info",
  "Not at Peori",
  "Note that comes from transfers",
  "Notes 2",
  "Notes",
  "Obtained",
  "Old Item ID",
  "Old Location",
  "OLD URL",
  "On Lloyds",
  "On Sale",
  "ONGOING AND SCHEDULED",
  "ORIGINAL CURRENCY",
  "ORIGINAL SALE CURRENCY",
  "ORIGINAL SALE PRICE",
  "ORIGINAL TEXT",
  "Owner OLD",
  "Owner",
  "ParentLocation",
  "Past Inventory No.",
  "PAST NOTES",
  "PAYMENT DATE",
  "Pending Transfer",
  "PEORI: APRIL 2021 LIST",
  "PEORI: IN/OUT",
  "PRESALE LOCATION",
  "Print Checkbox",
  "Print Value",
  "PROP SCH",
  "PROVENANCE DATE",
  "PROVENANCE",
  "PURCHASE DATE YRMN",
  "Purchase Location (TEMPORARY)",
  "PurchaseID",
  "Purchases",
  "QR Code Current",
  "QR Code",
  "Quality Line",
  "Quality",
  "QualityName",
  "Quantity (Qty)",
  "Questions",
  "Related Documents LU",
  "RELEVANT ART FROM TEST",
  "Remove",
  "REPLACEMENT VALUE",
  "Request for Invoice",
  "RETURN DATE",
  "RETURN ENTITY",
  "RETURN GAIN LOSS",
  "RETURN TRANSACTION",
  "RETURN TYPE TRANSACTION",
  "RETURNED ITEMS SALE PRICE (USD)",
  "Room Scans",
  "Rooms 2",
  "Rooms 3",
  "Rooms 4 copy",
  "Rooms 4",
  "Rooms",
  "SALE / AUCTION DATE",
  "SALE / AUCTION NAME",
  "Sale Date",
  "SALE DESCRIPTION",
  "Sale Documents",
  "SALE ENTITY LOCATION (from Sale Transactions)",
  "SALE ENTITY Rollup (from RELEVANT ART FROM TEST)",
  "SALE ENTITY",
  "Sale No.",
  "SALE PRICE (USD)",
  "Sale Transaction (to Art Inventory)",
  "SALE TYPE TRANSACTION",
  "SALE YEAR LOOKUP",
  "SALE YEAR",
  "Sale",
  "SALE/RETURN TYPE TRANSACTION",
  "SaleDateMonth",
  "SaleDateYear",
  "SaleStatus (OLD- REPUPOSE?)",
  "SALESTATUS ON 20220206",
  "Sanjay No.",
  "Sayin Carpet Purchase",
  "Schedule A - Art Contribution",
  "Schedule A Duplicate",
  "Schedule A Note",
  "Schedule I Location",
  "Seller",
  "Selling J",
  "Selling J2",
  "SellingWith",
  "Send This To",
  "Sent to Val",
  "SetForSaleDate",
  "SetPriceTime",
  "SFS Minimum Agreed Insurance Value",
  "Shipping Documents",
  "Shipping Reference (Tracking Details, Box Numbers, etc)",
  "Shipping Status",
  "SHORT TITLE",
  "SINCE TFG",
  "Sold On Different Format",
  "Sold",
  "SoldDate",
  "SOTHEBY'S APR 2014 DISCREPANCIES",
  "Sotheby's Loan",
  "Sotheby's Part A Advance",
  "SOTHEBY'S PROPERTY SCH",
  "SPEDITION ??",
  "SPEDITION NAME",
  "SPEDITION ORDER",
  "SPEDITION UNPACKED/CRATE",
  "Status",
  "STHB Reference No",
  "SubLocation Plain",
  "SubLocation",
  "SubLocationID",
  "SubLocationName",
  "SublocationRecordId",
  "SubLocations",
  "SubLocURL",
  "TASK: 2021",
  "TASK: 2022",
  "TASK: 2023",
  "TASK: SALE STATUS",
  "TBD REMOVE",
  "Temp - Packed",
  "Temp Art Schedule 09",
  "temp differ",
  "Temp For Sale",
  "Temp Invoices",
  "TEMP LOC PLAIN",
  "TEMP LOC",
  "Temp Selection",
  "TEMP",
  "TEMP1 copy copy",
  "TEMP1 copy",
  "TEMP1",
  "temphanu",
  "Tennis house request",
  "TFG Loan 9",
  "Tier Art Intel",
  "Tier",
  "Title (Automation)",
  "TITLE &amp; INV# copy",
  "Title",
  "TitleClean",
  "Today's Date",
  "Top 200 for Selling",
  "track",
  "Transaction Type OLD",
  "Transaction Type",
  "TransferId (from Locations)",
  "TRAVEL DOCS",
  "UPON SALE",
  "User (from Locations Table)",
  "Users 2",
  "Users 3",
  "Users",
  "Value Tier",
  "Value",
  "Weight",
  "Width CM",
  "Width FT IN",
  "Width IN",
  "wip",
  "WIPPP",
  "With Hampel",
  "working",
  "workingg",
];
