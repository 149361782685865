<template>
  <div id="cart" class="o-section__wrapper shopping">
    <main id="cartPage" aria-label="content">
      <header class="section-header">Shopping Cart</header>
      <section class="cart-wrap">
        <header v-if="shoppingCart.length" class="cart-header">
          <span class="item">Item</span>
          <span class="asking">Asking Price</span>
          <span class="delete">
            <svg fill="none" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 7H5v6h2V7zM11 7H9v6h2V7z" fill="#B0AEA9" />
              <path
                d="M12 1c0-.6-.4-1-1-1H5c-.6 0-1 .4-1 1v2H0v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V5h1V3h-4V1zM6 2h4v1H6V2zm7 3v9H3V5h10z"
                fill="#B0AEA9"
              />
            </svg>
          </span>
        </header>
        <!--        item list-->
        <div v-if="shoppingCart.length" class="cart-list shopping">
          <div v-for="(item, index) in shoppingCart" :key="index" class="cart-item">
            <div
              v-if="getImageUrl(item)"
              :style="{
                backgroundImage: `url(${getImageUrl(item)})`,
              }"
              class="cart-thumb"
            ></div>
            <div class="details">
              <h3 class="title">
                <truncate
                  :length="70"
                  :text="item && item.fields && item.fields.TitleClean | titlecase | sanitize"
                  action-class="is-hidden"
                  clamp="See More"
                  class=""
                  less="See Less"
                  type="html"
                />
              </h3>
            </div>
            <div v-if="item && item.fields && item.fields.AskingPrice" class="asking-price">
              {{ item.fields.AskingPrice | currency }}
            </div>
            <!-- delete-->
            <div class="delete-item">
              <a aria-label="Delete" class="c-link c-link--bin" @click="removeShoppingCartItem(item)">
                <svg fill="none" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 7H5v6h2V7zM11 7H9v6h2V7z" fill="#B0AEA9" />
                  <path
                    d="M12 1c0-.6-.4-1-1-1H5c-.6 0-1 .4-1 1v2H0v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V5h1V3h-4V1zM6 2h4v1H6V2zm7 3v9H3V5h10z"
                    fill="#B0AEA9"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <!-- send button-->
        <footer v-if="shoppingCart.length" class="cart-footer">
          <div class="cart-footer-row total">
            <label class="total">Total:</label>
            <span v-if="!networkBusyStatus" class="cart-total">
              {{ curShoppingCartTotal | currency }}
            </span>
            <span v-else class="cart-total">
              <Progress fill-color="#000000" width="20" />
            </span>
          </div>
          <div class="cart-footer-row submit">
            <button class="c-btn success" @click="submitPurchaseOrder">Submit Order</button>
          </div>
        </footer>
        <div v-if="!shoppingCart.length && showThankYou" class="cart-thank-you">
          <div class="message-box">
            <h2 class="message-box__title">THANK YOU FOR YOUR PURCHASE</h2>
            <p class="message-box__text">Someone will contact you regarding this order.</p>
            <p class="message-box__text">Reference #: {{ orderId }}</p>
            <router-link aria-label="Categories" class="btn-link mt-4" to="/categories" @click.native="scrollToTop">
              Continue shopping
            </router-link>
          </div>
        </div>
        <div v-if="!shoppingCart.length && !showThankYou" class="message-box message-box--cart">
          <h2 class="message-box__title">Your shopping cart is empty</h2>
        </div>
      </section>
      <!-- previous purchases previous purchases previous purchases -->
      <!-- previous purchases previous purchases previous purchases -->
      <!-- previous purchases previous purchases previous purchases -->
      <section v-if="previousPurchases && previousPurchases.length" class="previous-list">
        <header class="section-header previous">Previous purchases for {{ curUserData.fields.FirstName }}</header>
        <div
          v-for="(purchase, index) in previousPurchases"
          :key="index"
          :class="getStatus(purchase)"
          class="cart-item-wrap"
        >
          <div v-if="getStatus(purchase)" class="previous-details">
            <div class="detail">
              <label>Reference ID#:</label>
              {{ purchase.id }}
            </div>
            <div class="detail">
              <label>Date:</label>
              <span>{{ purchase.fields.Created | date("date") }}</span>
            </div>
            <div class="detail status">
              <label>Status:</label>
              <span :class="getStatus(purchase)">{{ purchase.fields.Status || "n/a" }}</span>
            </div>
            <div class="detail">
              <label>Items:</label>
              <ul v-if="purchase.fields.Collection">
                <li v-for="(n, itemIndex) in purchase.fields.Collection.length" :key="itemIndex">
                  <router-link
                    :to="`/item/${purchase.fields.Collection[n - 1]}`"
                    @click.native="setCurrentRecordId(purchase.fields.Collection[n - 1])"
                  >
                    <truncate
                      :length="70"
                      :text="purchase.fields.CollectionTitle[n - 1] | sanitize | titlecase"
                      action-class="is-hidden"
                      clamp="See More"
                      class=""
                      less="See Less"
                      type="html"
                    />
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div v-if="networkBusyStatus && !shoppingCart.length" class="pt-5 mt-5">
        <Spinner />
      </div>
    </main>
  </div>
</template>
<script>
import Progress from "../ui/Progress";
import Spinner from "../ui/Spinner";
import Truncate from "vue-truncate-collapsed";
import Vue from "vue";
import _ from "lodash";
import airtableQuery from "@/services/airtableQuery.service";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "CartShopping",
  components: {
    Progress,
    Spinner,
    Truncate,
  },
  data() {
    return {
      curShoppingCart: [],
      curShoppingCartItemIds: [],
      curShoppingCartTotal: 0,
      message: "",
      orderId: "",
      orderPayload: [],
      previousPurchases: [],
      removeItem: false,
      showThankYou: false,
      successMessage: "",
      updateInventoryPayload: [],
    };
  },
  computed: {
    ...mapState([
      "curUserData",
      "locations",
      "networkBusyStatus",
      "shoppingCart",
      "shoppingCartItemIds",
      "shoppingCartTotal",
    ]),
    currentDate() {
      let purchaseDate = new Date().toLocaleDateString();
      let purchaseTime = new Date().toLocaleTimeString();
      return `${purchaseDate} at ${purchaseTime}`;
    },
  },
  watch: {
    shoppingCart: {
      handler(newVal) {
        this.curShoppingCart = newVal;
      },
      deep: true,
    },
    shoppingCartTotal: {
      handler(newVal) {
        this.curShoppingCartTotal = newVal;
      },
    },
  },
  created() {
    this.curShoppingCartTotal = this.shoppingCartTotal;
    if (this.shoppingCartItemIds.length) {
      this.$store.dispatch("getShoppingCart");
    } else {
      this.$store.commit("setShoppingCart", []);
    }
    this.curShoppingCart = _.clone(this.shoppingCart);
    this.curShoppingCartItemIds = _.clone(this.shoppingCartItemIds);
    this.getPreviousPurchases();
  },

  methods: {
    setCurrentRecordId(id) {
      this.$store.commit("setCurrentRecordId", id);
    },
    getStatus(purchase) {
      return purchase.fields.Status.replace(" ", "-");
    },
    async getPreviousPurchases() {
      await airtableQuery.getTableAsync("Purchases", 100, "administrator", "", []).then((data) => {
        let fitleredPurchases = data.filter(
          (purchase) =>
            purchase.fields.BuyerName === this.curUserData.fields.FullName ||
            purchase.fields.BuyerName === this.curUserData.fields.username
        );
        this.previousPurchases = fitleredPurchases.sort((a, b) => {
          return a.fields.Created > b.fields.Created ? -1 : 1;
        });
      });
    },
    /**

     SUBMIT PURCHASE ORDER

     */
    async submitPurchaseOrder() {
      this.orderPayload = [
        {
          fields: {
            BuyerName: this.curUserData.fields.FullName,
            BuyerEmail: this.curUserData.fields.Email,
            Collection: this.curShoppingCartItemIds,
            Status: "Needs Review",
            OrderTotal: this.curShoppingCartTotal,
          },
        },
      ];

      this.showThankYou = true;
      this.curShoppingCartItemIds.forEach((itemId) => {
        this.updateInventoryPayload.push({
          id: itemId,
          fields: {
            Sold: true,
            ForSale: false,
            AskingPrice: null,
          },
        });
      });
      await airtableQuery.createRecordAsync("Purchases", this.orderPayload).then((data) => {
        this.orderId = data[0].id;
        this.sendPurchaseConfirmationEmail();
      });
    },
    /**

     SEND CONFIMATION EMAIL

     */
    sendPurchaseConfirmationEmail() {
      this.message += "opendiv";
      this.message += `h3open Purchase order from: ${this.curUserData.fields.FullName}: h3close`;
      this.message += `Submitted on: ${this.currentDate}^^`;
      this.message += `Reference ID: ${this.orderId}^^`;
      this.message += `__________________________________________^^`;
      this.curShoppingCart.forEach((item, i) => {
        this.curShoppingCartTotal += parseInt(item.fields.AskingPrice, 10);
        let shortName = `${item.fields.TitleClean.substring(0, 42)}\u2026`;
        let askingPrice = Vue.filter("currency")(item.fields.AskingPrice);
        this.message += `${i + 1}) `;
        this.message += `<a href="https://cycad.art/item/${item.id}">${shortName}</a> ^^`;
        this.message += `Item ID: ${item.id} ^^`;
        this.message += `Asking price: ${askingPrice} ^^^^`;
      });
      let cartTotal = Vue.filter("currency")(this.curShoppingCartTotal);
      this.message += `__________________________________________^^`;
      this.message += `Total: ${cartTotal}`;
      this.message += "closediv";
      let post = {
        message: this.message,
        ccEmail: this.curUserData.fields.Email,
      };
      if (post) {
        axios
          .post("/purchase_confirmation.php", post)
          .then(() => {
            this.isSending = false;
            this.successMessage = "Order submitted to Cycad Management.";
            window.scrollTo(0, 0);
            this.$toasted.success(this.successMessage, {
              duration: 6000,
            });
          })
          .catch((error) => {
            this.isSending = false;
            window.scrollTo(0, 0);
            this.$toasted.error(`There was a problem sending confirmation email. (${error})`, {
              duration: 6000,
            });
            console.error(error);
          });
      }
      this.$store.commit("setShoppingCartItemIds", []);
      this.$store.commit("setShoppingCart", []);
      this.$store.commit("setShoppingCartTotal", 0);
      airtableQuery.updateRecordsAsync("Users", [{ id: this.curUserData.id, fields: { ShoppingCart: [] } }]);
      if (this.removeItem) {
        airtableQuery.updateRecordsAsync("Art Inventory", this.updateInventoryPayload);
      }
    },
    // utilities------------------------------------
    getImageUrl(item) {
      let imageUrl;
      if (item && item.fields && item.fields.Image) {
        if (typeof item.fields.Image === "object") {
          imageUrl = item.fields.Image[item.fields.Image.length - 1].thumbnails.large.url;
        } else if (typeof item.fields.Image === "string") {
          imageUrl = item.fields.Image.thumbnails.large.url;
        }
      }
      return imageUrl;
    },
    // remove item
    removeShoppingCartItem(item) {
      this.curShoppingCartItemIds = this.curShoppingCartItemIds.filter((itemId) => itemId !== item.id);
      this.$store.commit("removeShoppingCartItemId", item.id);
      this.$store.commit("removeShoppingCartItem", item.id);
      this.curShoppingCartTotal -= item.fields.AskingPrice;
      this.$store.commit("setShoppingCartTotal", this.curShoppingCartTotal);
      airtableQuery.updateRecordsAsync("Users", [
        {
          id: this.curUserData.id,
          fields: { ShoppingCart: this.curShoppingCartItemIds },
        },
      ]);
    },
  },
};
</script>
