<template>
  <div v-if="filteredCategories.length" class="grid-wrap">
    <div
      v-for="(category, index) in filteredCategories"
      :key="index"
      class="grid-item"
      @click.stop="setCurrentCategory(category)"
    >
      <div class="image" :style="getStyles(category)">
        <span class="overlay">
          <span class="text">{{ category.fields.Category }}</span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import router from "../../router";
import { mapState } from "vuex";

export default {
  name: "Categories",
  data() {
    return {
      filteredCategories: [],
    };
  },
  computed: {
    ...mapState(["categories", "curUserPerms"]),
  },
  created() {
    if (this.curUserPerms.permittedCategories[0] === "All") {
      this.filteredCategories = [...this.categories];
    } else {
      this.categories.forEach((cat) => {
        this.curUserPerms.permittedCategories.forEach((permCat) => {
          if (cat.fields.Category === permCat) {
            this.filteredCategories.push(cat);
          }
        });
      });
    }
  },
  methods: {
    setCurrentCategory(category) {
      this.$store.commit("setFilterCategoryFromMenu", category.fields.Category);
      this.$store.commit("setTotalResults", 0);
      router.push({
        path: "/art-inventory",
        query: { reload: true },
      });
    },
    getStyles(category) {
      if (category.fields.Attachments && category.fields.Attachments[0]) {
        return `background: url(${category.fields.Attachments[0].thumbnails.large.url})`;
      } else {
        return "background: linear-gradient(0deg, rgb(87, 61, 42), rgb(15, 48, 86));";
      }
    },
  },
};
</script>
