<template>
  <div class="cleave">
    <div class="checkbox-wrap">
      <input
        :id="`${item.id}_compare`"
        v-model="isCompare"
        type="checkbox"
        class="squarecheck success"
        @change="handleToggleIsCompare"
      />
      <label :for="`${item.id}_compare`">Compare</label>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "Compare",
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      isCompare: false,
    };
  },
  watch: {
    compareList: {
      handler(newVal) {
        if (newVal) {
          this.handleCheckIfCompare();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(["authData", "compareList"]),
  },
  created() {
    this.handleCheckIfCompare();
  },
  methods: {
    handleToggleIsCompare() {
      let newCompareList = [];
      if (!this.isCompare) {
        newCompareList = this.compareList.filter((obj) => obj.id !== this.item.id);
      } else {
        newCompareList = [...new Set([...this.compareList, this.item])];
      }
      this.$store.commit("setCompareList", newCompareList);
    },
    handleCheckIfCompare() {
      this.compareList.length &&
        this.compareList.forEach((obj) => {
          if (obj.id === this.item.id) {
            this.isCompare = true;
          }
        });
    },
  },
};
</script>
