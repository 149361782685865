<template>
  <ul class="c-filter__list">
    <li class="c-filter__list__items" @click.stop="setFilterCollateralized(filters.collateralized)">
      <div class="li-inner">
        <a :class="{ 'is-selected': filters.collateralized }" class="c-link c-link--filter">
          <span class="label">Collateralized</span>
        </a>
      </div>
    </li>
  </ul>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "Collateralized",
  computed: mapState(["filters"]),
  data() {
    return {
      isCollateralized: false,
    };
  },
  created() {
    this.isCollateralized = this.filters.collateralized;
  },
  methods: {
    setFilterCollateralized() {
      this.isCollateralized = !this.isCollateralized;
      if (this.filters) {
        this.$store.commit("setFilterCollateralized", this.isCollateralized);
      }
    },
  },
};
</script>
