<template>
  <div v-if="currentItems.length" class="layout-wrap">
    <div v-if="showPagination" class="pagination-wrap top">
      <paginate
        :key="`paginateTop_${randomKey}`"
        :current-items="currentItems"
        :initial-page="initialPage"
        :current-page="currentPage"
        @change-page="onChangePage"
        @click.native="generateRandomKey"
      />
    </div>
    <isotope
      v-if="Object.keys(currentItems).length"
      id="masonry"
      :key="`iso_${randomKey}`"
      ref="isotope"
      v-images-loaded:on="getLoadingCallBack()"
      :options="options"
      :list="currentItems"
      class="c-layout c-layout--masonry js-masonry"
    >
      <card-masonry-item
        v-for="(item, index) in pageOfItems"
        :key="index"
        :item="item"
        class="masonry-wrap js-masonry-item"
        @reload-isotope="generateRandomKey"
      />
    </isotope>
    <div v-if="showPagination" class="pagination-wrap">
      <paginate
        :key="`paginateBottom_${randomKey}`"
        :current-items="currentItems"
        :initial-page="initialPage"
        :current-page="currentPage"
        @change-page="onChangePage"
        @click.native="generateRandomKey"
      />
    </div>
  </div>
</template>
<script>
import CardMasonryItem from "./CardMasonryItem";
import imagesLoaded from "vue-images-loaded";
import isotope from "vueisotope";
import Paginate from "../Paginate";
import { mapState } from "vuex";

export default {
  name: "CardMasonry",
  directives: {
    imagesLoaded,
  },
  components: {
    isotope,
    CardMasonryItem,
    Paginate,
  },
  props: {
    currentItems: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      currentPage: 1,
      options: {
        layoutMode: "masonry",
        masonry: {
          horizontalOrder: true,
          columnWidth: ".js-masonry-item",
        },
        getSortData: {
          name: "name",
        },
      },
      pageOfItems: [],
      randomKey: 42,
    };
  },
  computed: {
    ...mapState(["initialPage"]),
    showPagination() {
      return this.currentItems.length > 100;
    },
  },
  mounted() {
    if (!this.showPagination) {
      this.pageOfItems = this.currentItems;
    }
  },
  methods: {
    onChangePage(pageOfItems, currentPage) {
      this.currentPage = currentPage;
      this.pageOfItems = pageOfItems;
    },
    getLoadingCallBack() {
      return {
        progress: (instance, image) => {
          let imageParent = image.img.parentElement.parentElement;
          if (image.isLoaded) {
            imageParent.classList.add("is-loaded");
          } else {
            imageParent.classList.add("is-broken");
          }
          if (this.$refs.isotope) {
            this.$refs.isotope.layout("masonry");
          }
        },
      };
    },
    generateRandomKey() {
      this.randomKey = Math.floor(Math.random() * 1000000000);
    },
  },
};
</script>
