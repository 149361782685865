<template>
  <div v-if="item" class="c-room__list__items">
    <div class="c-room__image">
      <div v-if="item.fields.Image">
        <span v-if="typeof item.fields.Image === 'object'">
          <img
            v-if="item.fields.Image[item.fields.Image.length - 1].thumbnails"
            :src="item.fields.Image[item.fields.Image.length - 1].thumbnails.large.url"
            class="c-works__photo"
            alt="Thumnail Image"
          />
        </span>
        <span v-if="typeof item.fields.Image === 'string'">
          <img
            v-if="item.fields.Image.thumbnails"
            :src="item.fields.Image.thumbnails.large.url"
            class="c-works__photo"
            alt="Thumnail Image"
          />
        </span>
      </div>
      <div v-else>
        <img src="/assets/images/no-image.jpg" alt="No Image" />
      </div>
    </div>
    <div class="c-room__details">
      <Truncate
        v-if="item.fields.TitleClean"
        action-class="c-btn c-btn--readmore"
        class="modal-title"
        clamp="See More"
        :length="80"
        less="See Less"
        type="html"
        :text="item.fields.TitleClean | sanitize"
      />
      <div v-if="item.fields['Current Location']" class="c-room__location">
        <i class="material-icons material-icons-outlined"> room </i>
        Current Location:
        {{ item.fields["Current Location"] || "" | titlecase }}
      </div>
    </div>
    <div class="scan-status" :class="{ scanned: isScanned }">
      {{ statusText }}
    </div>
  </div>
</template>
<script>
import Truncate from "vue-truncate-collapsed";
export default {
  name: "RoomItem",
  components: {
    Truncate,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    scannedIds: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    isScanned() {
      return this.scannedIds.includes(this.item.id);
    },
    statusText() {
      return this.scannedIds.includes(this.item.id) ? "Scanned" : "Not Scanned";
    },
  },
};
</script>
