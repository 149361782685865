<template>
  <div v-if="qualityOptions && qualityOptions.length">
    <ul v-if="hasCheckbox" class="c-filter__list">
      <li
        v-for="(option, index) in qualityOptions"
        :key="`quality_${index}`"
        class="c-filter__list__items"
        @click="$emit('set-quality', option)"
      >
        <div class="li-inner">
          <a :class="{ 'is-selected': filters.quality.includes(option) }" class="c-link c-link--filter">
            <span class="label">{{ option }}</span>
          </a>
        </div>
      </li>
    </ul>
    <ul v-else class="c-dropdown__list">
      <li
        v-for="(option, index) in qualityOptions"
        :key="index"
        class="c-dropdown__list__items"
        @click="$emit('set-quality', option)"
      >
        <a class="c-link c-link--dropdown" :aria-label="option">{{ option }}</a>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "DropdownQuality",
  props: {
    hasCheckbox: {
      type: Boolean,
      required: true,
      default: true,
    },
    qualityOptions: {
      type: Array,
      required: true,
      default: () => {},
    },
  },
  computed: mapState(["filters"]),
};
</script>
