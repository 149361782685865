<template>
  <div id="app">
    <Navbar v-if="isNavbarVisible" class="js-sticky-nav" />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>
<script>
import Navbar from "./components/ui/Navbar";

export default {
  name: "App",
  components: {
    Navbar,
  },
  computed: {
    isNavbarVisible() {
      return this.$route.name !== "AppLogin";
    },
  },
};
</script>
