<template>
  <div class="datepicker-wrap">
    <div class="pickers">
      <div class="start">
        <label>Start Date:</label>
        <Datepicker
          ref="dateStart"
          :key="`dateStart_${randomKey}`"
          name="dateDate"
          wrapper-class=""
          calendar-class="calendar-wrap start"
          placeholder="Choose"
          input-class="c-dropdown__title js-dropdown-title"
          format="yyyy-MM-dd"
          :value="initialValue"
          @selected="setStartDate($event)"
        />
      </div>
      <div class="dash">&mdash;</div>
      <div class="end">
        <label>End Date:</label>
        <Datepicker
          ref="dateEnd"
          :key="`dateEnd_${randomKey}`"
          name="dateEnd"
          wrapper-class=""
          calendar-class="calendar-wrap end"
          placeholder="Choose"
          input-class="c-dropdown__title js-dropdown-title"
          format="yyyy-MM-dd"
          :value="initialValue"
          @selected="setEndDate($event)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Datepicker from "vuejs-datepicker";

export default {
  name: "DateRange",
  components: {
    Datepicker,
  },
  data() {
    return {
      initialValue: "",
      randomKey: 42,
    };
  },
  computed: {
    ...mapState(["filters"]),
  },
  created() {
    this.$root.$on("resetFilters", this.resetDates);
    this.$root.$on("resetDates", this.resetDates);
  },
  updated() {},
  methods: {
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }

      return [year, month, day].join("-");
    },
    resetDates() {
      if (this.$refs.dateStart) {
        this.$refs.dateStart.value = this.initialValue;
      }
      if (this.$refs.dateEnd) {
        this.$refs.dateEnd.value = this.initialValue;
      }
      this.generateRandomKey();
    },
    setStartDate(date) {
      let formattedDate = this.formatDate(date);
      this.$store.commit("setFilterDateStart", formattedDate);
    },
    setEndDate(date) {
      let formattedDate = this.formatDate(date);
      this.$store.commit("setFilterDateEnd", formattedDate);
    },
    generateRandomKey() {
      this.randomKey = Math.floor(Math.random() * 10000000) + 1;
    },
  },
};
</script>
