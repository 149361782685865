import "./scss/index.scss";
import "material-icons/iconfont/material-icons.scss";
import App from "./App";
import Nl2br from "vue-nl2br";
import Toasted from "vue-toasted";
import ToggleButton from "vue-js-toggle-button";
import Vue from "vue";
import VueFullPage from "vue-fullpage.js";
import router from "./router";
import store from "./store";
import toTitleCase from "to-title-case";
import vSelect from "vue-select";
import { VueMasonryPlugin } from "vue-masonry";
import VueTextareaAutosize from "vue-textarea-autosize";
import _ from "lodash";

Object.defineProperty(Vue.prototype, "$_", { value: _ });
Vue.config.productionTip = false;

Vue.use(VueTextareaAutosize);
Vue.use(VueFullPage);
Vue.use(VueMasonryPlugin);
Vue.use(ToggleButton);
Vue.use(Toasted, {
  position: "top-right",
  keepOnHover: true,
  iconPack: "material",
});

// COMPONENTS ================================
Vue.component("VSelect", vSelect);
Vue.component("Nl2br", Nl2br);
// FILTERS ===================================
Vue.filter("titlecase", (str) => {
  if (!str) {
    return "";
  }
  return str && str.length ? toTitleCase(str) : str;
});

Vue.filter("sanitize", (value) => {
  if (!value) {
    return "";
  }
  value = String(value);
  value = value.replace(/[\u2028]/g, " \u25AA ");
  value = value.replace(/\r\n|\r|\n\n/g, " \u25AA ");
  value = value.replace(/\r\n|\r|\n/g, " \u25AA ");
  return value;
});

Vue.filter("sanitizeTitle", (value) => {
  if (!value) {
    return "";
  }
  value = value.trim();
  value = String(value);
  value = value.replace(/[\u2028]/g, "");
  value = value.replace(/(?:\r\n|\r|\n\n)/g, "");
  value = toTitleCase(value);
  return value;
});

Vue.filter("sanitizeDescription", (value) => {
  if (!value) {
    return "";
  }
  value = String(value);
  value = value.replace(/[\u2028]/g, "<br />");
  value = value.replace(/\n/g, "<br />");
  return value;
});

Vue.filter("date", function (value, format = "full") {
  if (!value) {
    return "";
  }
  let newDate = new Date(value);
  let displayDate;
  if (format === "full") {
    displayDate = `${
      newDate.getMonth() + 1
    }/${newDate.getDate()}/${newDate.getFullYear()} - ${newDate.getUTCHours()}:${newDate.getUTCMinutes()}`;
  } else if (format === "time") {
    displayDate = `${newDate.getUTCHours()}:${newDate.getUTCMinutes()}`;
  } else if (format === "date") {
    displayDate = `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getUTCFullYear()}`;
  }
  return displayDate;
});

Vue.filter("currency", (value) => {
  if (!value) {
    return "";
  }
  return value.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
});

Vue.filter("commas", function numberWithCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

Vue.directive("scroll", {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
